import React, { useState, useEffect } from "react";
import { Link, useMatch, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createProfile, getCurrentProfile } from "../../actions/profile";
import { setAlert } from "../../actions/alert";
import { SportList } from "./SportList";
import { allCountries } from "country-region-data";

// import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Icon from "../../img/icon.png";
import {
  DATA_PLAYER,
  DATA_COACH,
  DATA_MANAGER,
  DATA_PRESS,
  DATA_FAN,
  DATA_ROLES,
  listFields,
  roleRequiredFields,
  countryFieldsArray,
  cityFieldsArray,
} from "./RoleData";
import BasicDatePicker from "./BasicDatePicker";

//MUI
import {
  Box,
  TextField,
  Typography,
  Button,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  FormControl,
  Autocomplete,
  Divider,
} from "@mui/material";
import SearchSelect from "./SearchSelect";
import { profileDataInitialState } from "../../utils/profileData";
const countriesArray = allCountries.map((name) => name[0]);
const ProfileForm = ({
  profile: { profile, loading },
  createProfile,
  getCurrentProfile,
  setAlert,
}) => {
  const [formData, setFormData] = useState(profileDataInitialState);
  const [roleDataSorted, setRoleDataSorted] = useState([]);
  const [cityArray, setCityArray] = useState([""]);
  const [uploadError, setUploadError] = useState(null);
  const creatingProfile = useMatch("/create-profile");
  const [isUser, setIsUser] = useState(false);
  const [objectURL, setObjectURL] = useState(false);
  const navigate = useNavigate();
  const fileInput = React.createRef();

  const { firstname, lastname, nickname, role, email, sport, city, country } =
    formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDateChange = (type, date) => {
    setFormData((prevData) => ({
      ...prevData,
      [type]: date,
    }));
  };

  const onChangeSport = (value) => {
    setFormData({ ...formData, sport: value });
  };

  const onChangeCountry = (value, type) => {
    setFormData({ ...formData, country: value, [type]: value });
  };

  const onChangeCity = (value, type) => {
    setFormData({ ...formData, [type]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const currentRole = formData?.role;
    const requiredFields = roleRequiredFields[currentRole];
    if (!Array.isArray(requiredFields)) return;

    const missingFields = Array.isArray(requiredFields)
      ? requiredFields.filter((field) => !formData[field])
      : [];

    if (!formData.dateOfBirth) missingFields.push("dateOfBirth");

    if (missingFields.length > 0) {
      const validationErrors = {
        missingFields,
        statusMessage: `Validation Error: Some required fields for ${currentRole} role are missing.`,
      };
      console.error(validationErrors.statusMessage);
      setAlert(
        validationErrors?.statusMessage,
        "danger",
        validationErrors?.missingFields
      );
    } else {
      createProfile(
        formData,
        navigate("/dashboard"),
        profile ? true : false,
        profile
      );
    }
  };

  const handlePhoto = (e) => {
    const image = e.target.files[0];
    if (Image && image?.size > 1024 * 1024 * 1) {
      setUploadError("File size exceeds 1MB");
      return;
    }
    setUploadError(null);
    setFormData({ ...formData, avatar: fileInput.current.files[0] });
    setObjectURL(URL.createObjectURL(image));
  };

  const generateArray = (start, end, reverse = false) => {
    const range = Array.from(
      { length: end - start + 1 },
      (_, index) => start + index
    );
    return reverse ? range.reverse() : range;
  };

  useEffect(() => {
    // if there is no profile, attempt to fetch one
    if (!profile) getCurrentProfile();

    // if we finished loading and we do have a profile
    // then build our profileData
    if (!loading && profile) {
      const profileData = { ...profileDataInitialState };

      // Loop through each field in profileData
      for (const key in profileData) {
        if (key === "experience" || key === "education") {
          // If the field is an array (e.g., experience, education)
          // use the profile value if available, or the default array
          profileData[key] = profile[key] || profileDataInitialState[key];
        } else if (key === "email") {
          profileData[key] =
            profile?.user?.email || profileDataInitialState[key];
        } else if (key in profile) {
          // If the field is present in the profile, use its value
          profileData[key] = profile[key];
        }
      }

      // set local state with the profileData
      setFormData(profileData);
      setIsUser(true);
    }
  }, [loading, getCurrentProfile, profile]);

  useEffect(() => {
    const newCityArray =
      countriesArray
        .map((element, index) =>
          element === formData.country
            ? allCountries[index][2].map((name) => name[0])
            : []
        )
        .find((array) => array.length > 0) || [];
    setCityArray(newCityArray);
  }, [formData.country]);
  console.log(cityArray, "cityArray");
  useEffect(() => {
    const newCopyItems = [];

    switch (formData.role) {
      case "player":
        DATA_PLAYER.forEach((key) => {
          console.log(`${key} : ${DATA_ROLES[key]}`);
          newCopyItems.push([key, DATA_ROLES[key]]);
        });
        break;
      case "coach":
        DATA_COACH.forEach((key) => {
          console.log(`${key} : ${DATA_ROLES[key]}`);
          newCopyItems.push([key, DATA_ROLES[key]]);
        });
        break;
      case "manager":
        DATA_MANAGER.forEach((key) => {
          console.log(`${key} : ${DATA_ROLES[key]}`);
          newCopyItems.push([key, DATA_ROLES[key]]);
        });
        break;
      case "press":
        DATA_PRESS.forEach((key) => {
          console.log(`${key} : ${DATA_ROLES[key]}`);
          newCopyItems.push([key, DATA_ROLES[key]]);
        });
        break;
      case "fans":
        DATA_FAN.forEach((key) => {
          console.log(`${key} : ${DATA_ROLES[key]}`);
          newCopyItems.push([key, DATA_ROLES[key]]);
        });
        break;
      default:
        DATA_PLAYER.forEach((key) => {
          console.log(`${key} : ${DATA_ROLES[key]}`);
          newCopyItems.push([key, DATA_ROLES[key]]);
        });
        break;
    }

    setRoleDataSorted(newCopyItems);
  }, [formData.role]);

  return (
    <Box
      component="form"
      sx={{
        mx: "auto",
        mt: 5,
        p: 3,
        maxWidth: 800,
        borderRadius: "8px",
        "& .MuiTextField-root": { mb: 2 },
        "& .MuiTypography-root": { mb: 1, mt: 1 },
        "& .MuiButton-root": { mb: 1, mt: 1, mr: 1 },
        background: "#FFFFFF",
        boxShadow: "0px 0px 0px 2px rgb(0 0 0 / 10%)",
      }}
      noValidate
      onSubmit={onSubmit}
    >
      <Typography variant="h4" align="center">
        {creatingProfile ? "Create Your Profile" : "Profile Setup"}
      </Typography>
      <Typography variant="h6" align="center">
        * Indicates required
      </Typography>

      {/* player type */}
      <Grid item xs={12} mt={2} mb={2}>
        <FormControl fullWidth>
          <InputLabel id="fieldOfActivityLabel">Field of Activity</InputLabel>
          <Select
            labelId="fieldOfActivityLabel"
            id="fieldOfActivity"
            value={role}
            name="role"
            label="Field of Activity"
            onChange={onChange}
          >
            <MenuItem value={"player"}>Player</MenuItem>
            <MenuItem value={"coach"}>Coach</MenuItem>
            <MenuItem value={"manager"}>Manager</MenuItem>
            <MenuItem value={"press"}>Press</MenuItem>
            <MenuItem value={"fans"}>Fans</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {/* sport information */}
      {isUser && (
        <SearchSelect
          onChange={onChangeSport}
          defaultValueProp={sport}
          value={sport}
          list={SportList}
          name={"Sport"}
          placeholder={"Select or type sport"}
          fullWidth
        />
      )}
      {/* Basic Information */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={formData.role === "player" ? 6 : 6}>
          <TextField
            id="firstname"
            label="First Name"
            variant="outlined"
            name="firstname"
            value={firstname}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={formData.role === "player" ? 6 : 6}>
          <TextField
            id="lastname"
            label="Last Name"
            variant="outlined"
            name="lastname"
            value={lastname}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        {/* <Grid item xs={12} sm={4}>
          {formData.role === "player" && (
            <TextField
              id="nickname"
              label="Nick Name"
              variant="outlined"
              name="nickname"
              value={nickname}
              onChange={onChange}
              fullWidth
            />
          )}
        </Grid> */}
      </Grid>

      <TextField
        id="email"
        label="Email Address"
        variant="outlined"
        name="email"
        value={email}
        InputProps={{
          readOnly: true,
        }}
        fullWidth
      />

      {/* {formData.role === "press" && (
        <BasicDatePicker
          type={"pressConceptionDate"}
          label="Press Conception Date (*required)"
          onDateChange={handleDateChange}
          initialDate={formData?.pressConceptionDate}
        />
      )} */}

      {roleDataSorted.map((value) => {
        // Check if the field is in the listFields array
        if (listFields.includes(value[0])) {
          return (
            <Autocomplete
              key={value[0]}
              multiple
              id={value[0]}
              options={[]} // Add options here if you want to suggest predefined values
              freeSolo
              value={formData[value[0]] || []}
              onChange={(event, newValue) =>
                onChange({ target: { name: value[0], value: newValue } })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={value[1]}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          );
        } else if (countryFieldsArray.includes(value[0])) {
          // Render SearchSelect for country field
          return (
            <Grid key={value[0]} container spacing={2}>
              <Grid item xs={12}>
                <SearchSelect
                  onChange={(country) => onChangeCountry(country, value[0])}
                  list={countriesArray}
                  name={value[1]}
                  placeholder={value[1]}
                  defaultValueProp={formData[value[0]]}
                  fullWidth
                  value={formData[value[0]]}
                />
              </Grid>
            </Grid>
          );
        } else if (cityFieldsArray.includes(value[0])) {
          // Render SearchSelect for city field
          return (
            <Grid key={value[0]} container spacing={2}>
              <Grid item xs={12}>
                <SearchSelect
                  onChange={(city) => onChangeCity(city, value[0])}
                  list={cityArray}
                  name={value[1]}
                  placeholder={value[1]}
                  defaultValueProp={formData[value[0]]}
                  fullWidth
                  value={formData[value[0]]}
                />
              </Grid>
            </Grid>
          );
        } else {
          // Render a regular TextField for other fields
          return (
            <TextField
              key={value[0]}
              id={value[0]}
              label={value[1]}
              variant="outlined"
              name={value[0]}
              value={formData[value[0]] || ""}
              onChange={onChange}
              fullWidth
            />
          );
        }
      })}

      <Divider sx={{ m2: 2, mb: 2 }} />
      {/* <GridTemplateColumns /> */}
      {/* <Typography variant="body1">Region</Typography> */}
      {isUser && (
        <Grid container spacing={2}>
          {/* <Grid item xs={12} sm={6}>
            <SearchSelect
              onChange={onChangeCountry}
              list={countriesArray}
              name={"Country"}
              placeholder={"Select or type country"}
              defaultValueProp={country}
              value={country}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SearchSelect
              onChange={onChangeCity}
              list={cityArray}
              name={"City"}
              placeholder={"Select or type city"}
              defaultValueProp={city}
              fullWidth
            />
          </Grid> */}
        </Grid>
      )}
      <Divider sx={{ m2: 2, mb: 2 }} />
      {/* Profile Picture */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 3,
        }}
      >
        <Typography variant="body1">Profile Picture</Typography>
        {(objectURL || formData.avatar) && (
          <img
            src={
              objectURL
                ? objectURL
                : formData.avatar !== "empty"
                ? `https://${
                    process.env.NODE_ENV === "development"
                      ? "localhost:8080"
                      : "picme.click:8080"
                  }/api/profile/photo/${profile?.avatar}`
                : Icon
            }
            alt="Profile"
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
              marginBottom: "8px",
            }}
          />
        )}

        <Button variant="contained" component="label">
          Upload File
          <input
            type="file"
            accept=".png, .jpg, .jpeg"
            name="avatar"
            onChange={handlePhoto}
            hidden
            ref={fileInput}
          />
        </Button>
        {uploadError && (
          <Typography variant="body2" color="error" mt={1}>
            {uploadError}
          </Typography>
        )}
      </Box>

      {/* Submit and Go Back Buttons */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 3,
        }}
      >
        <Button type="submit" variant="contained">
          Submit
        </Button>

        <Button variant="outlined" component={Link} to="/dashboard">
          Go Back
        </Button>
      </Box>
    </Box>
  );
};

ProfileForm.propTypes = {
  createProfile: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, {
  createProfile,
  getCurrentProfile,
  setAlert,
})(ProfileForm);
