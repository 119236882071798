import React, { useState } from "react";
import store from "../../store";
import { showAgreement } from "../../actions/agreements";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/auth";

//MUI
import { Box, TextField, Typography, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
//import Input from "@mui/material/Input";
//import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
//import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

//import LoginIcon from "@mui/icons-material/Login";

const Login = ({ login, isAuthenticated, openModal }) => {
  const loginPage = "login";
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [values, setValues] = useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });
  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    debugger;
    e.preventDefault();
    login(email, password);
  };

  if (isAuthenticated) {
    return <Navigate to="/home" />;
  }
  /*
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
*/
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box
      component="form"
      sx={{
        maxWidth: 500,
        "& .MuiFormControl-root": { mb: 2 },
        "& .MuiTypography-root": { mb: 1 },
      }}
      noValidate
      autoComplete="off"
      onSubmit={onSubmit}
    >
      <TextField
        id="email"
        label="Email Address"
        variant="outlined"
        name="email"
        value={email}
        onChange={onChange}
        fullWidth
      />
      {/*
      <TextField
        id="password2"
        label="Password (8+ characters)"
        variant="outlined"
        name="password"
        value={password}
        onChange={onChange}
        inputProps={{ minLength: 8 }}
        fullWidth
      /> */}
      <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor="outlined-adornment-password">
          Password (8+ characters)
        </InputLabel>
        <OutlinedInput
          id="password"
          type={values.showPassword ? "text" : "password"}
          value={password}
          onChange={onChange}
          name="password"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Password (8+ characters)"
          autoComplete="off"
        />
      </FormControl>

      <Typography variant="body1" align="left">
        By clicking Agree & Join, you agree to the Pic-Me{" "}
        <Link onClick={() => store.dispatch(showAgreement("UserAgreement"))}>
          User Agreement
        </Link>
        ,{" "}
        <Link onClick={() => store.dispatch(showAgreement("PrivacyPolicy"))}>
          Privacy Policy
        </Link>
        , and{" "}
        <Link onClick={() => store.dispatch(showAgreement("CookiePolicy"))}>
          Cookie Policy
        </Link>
        .
      </Typography>
      <Typography align="center">
        <Button
          type="submit"
          variant="contained"
          align="center"
          style={{ justifyContent: "center", borderRadius: "30px" }}
          fullWidth
          size="large"
        >
          Login
        </Button>
      </Typography>

      <Typography variant="body1" align="center" sx={{ mt: 2 }}>
        Don't have an account? <Link to="/register"> Sign Up </Link>
      </Typography>
    </Box>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  openModal: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Login);
