import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import { register } from "../../actions/auth";
import PropTypes from "prop-types";

import {
  Box,
  TextField,
  Typography,
  Button,
  Select,
  Container,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Grid,
  Alert,
  Snackbar,
} from "@mui/material";

import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";

import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Register = ({
  setAlert,
  register,
  auth: { userDetails },
  isAuthenticated,
  closeModal,
}) => {
  const { cookies } = useSelector((state) => state);

  const [formData, setFormData] = useState({
    firstname: "",
    email: "",
    password: "",
    password2: "",
    lastname: "",
    role: "",
    dateOfBirth: { day: "", month: "", year: "" },
    gender: "",
  });
  const {
    firstname,
    email,
    password,
    password2,
    lastname,
    role,
    dateOfBirth,
    gender,
  } = formData;

  const onSubmit = async (e) => {
    e.preventDefault();
    // Validation checks
    if (firstname === "") {
      setAlert("First name is required", "danger");
    }
    if (lastname === "") {
      setAlert("Last name is required", "danger");
    }
    if (email === "") {
      setAlert("Email is required", "danger");
    }
    if (!isValidEmail(email)) {
      setAlert("Please enter a valid email address", "danger");
    }
    if (password === "") {
      setAlert("Password is required", "danger");
    }
    if (password.length < 8) {
      setAlert("Password must be at least 8 characters long", "danger");
    }
    if (password !== password2) setAlert("Passwords do not match", "danger");

    if (role === "") {
      setAlert("Role is required", "danger");
    }
    if (gender === "") {
      setAlert("Gender is required", "danger");
    }

    if (
      dateOfBirth.day === "" ||
      dateOfBirth.month === "" ||
      dateOfBirth.year === ""
    ) {
      setAlert("Please select your date of birth", "danger");
    }

    if (
      firstname !== "" &&
      lastname !== "" &&
      email !== "" &&
      isValidEmail(email) &&
      password !== "" &&
      role !== "" &&
      password.length >= 8 &&
      dateOfBirth.day !== "" &&
      dateOfBirth.month !== "" &&
      dateOfBirth.year !== "" &&
      password === password2
    ) {
      register({
        firstname,
        lastname,
        email,
        password,
        dateOfBirth,
        gender,
        role,
        cookies,
      });
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "day" || name === "month" || name === "year") {
      setFormData({
        ...formData,
        dateOfBirth: {
          ...formData.dateOfBirth,
          [name]: value,
        },
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const generateArray = (start, end, reverse = false) => {
    const range = Array.from(
      { length: end - start + 1 },
      (_, index) => start + index
    );
    return reverse ? range.reverse() : range;
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  const daysArray = generateArray(1, 31);
  const monthsArray = generateArray(1, 12);
  const yearsArray = generateArray(1900, new Date().getFullYear(), true);
  const [alertBox, setAlertBox] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = alertBox;
  const handleClose = () => {
    setAlertBox({ ...alertBox, open: false });
  };
  useEffect(() => {
    if (userDetails && userDetails.status === 200) {
      setAlertBox({ vertical: "top", horizontal: "right", open: true });
      setFormData({
        firstname: "",
        email: "",
        password: "",
        password2: "",
        lastname: "",
        role: "",
        dateOfBirth: { day: "", month: "", year: "" },
        gender: "",
      });
    } else {
      setAlertBox({ ...alertBox, open: false });
    }
  }, [userDetails]);
  // if (isAuthenticated) {
  //   // return <Navigate to="/dashboard" />;
  //   return <Navigate to="/login" />;
  // }

  return (
    <Container maxWidth="sm">
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={userDetails?.message}
        key={vertical + horizontal}
        sx={{
          "& .MuiSnackbarContent-message": { background: "green" },
          "& .MuiSnackbarContent-root": {
            background: "green",
            width: "250px",
          },
        }}
      />
      <Box
        component="form"
        sx={{
          mt: closeModal ? 0 : 4,
          mx: "auto",
          gap: "16px",
          "& .MuiTextField-root": { mb: 1 },
          "& .MuiTypography-root": { mb: 1, mt: 1 },
          "& .MuiButton-root": {
            mb: 1,
            mt: 1,
            marginLeft: "auto",
            marginRight: "auto",
          },
        }}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
      >
        <Typography variant="h4" gutterBottom sx={{ mt: 3 }}>
          Join the fastest growing sports community!
        </Typography>
        <Typography variant="h3">Sign Up</Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          It's quick and easy
        </Typography>
        <Divider sx={{ mb: 2 }} />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="First Name"
              variant="outlined"
              id="firstname"
              name="firstname"
              value={firstname}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Last Name"
              variant="outlined"
              id="lastname"
              name="lastname"
              value={lastname}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email Address"
              variant="outlined"
              fullWidth
              id="email"
              name="email"
              value={email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment-password">
                Password (8+ characters)
              </InputLabel>
              <OutlinedInput
                id="password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handleChange}
                name="password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password (8+ characters)"
                autoComplete="off"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment-password">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                id="password2"
                type={showPassword2 ? "text" : "password"}
                value={password2}
                onChange={handleChange}
                name="password2"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword2}
                      onMouseDown={handleMouseDownPassword2}
                      edge="end"
                    >
                      {showPassword2 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password (8+ characters)"
                autoComplete="off"
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            <Typography
              sx={{
                margin: "0!important",
                color: "#637381",
                lineHeight: "1.4375em",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "center",
                gap: "8px",
              }}
            >
              Date Of Birth <img src="/qusertion-mark.png" alt="" />
            </Typography>
            <Grid item xs={12} sx={{ display: "flex", gap: "16px" }}>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Day</InputLabel>
                  <Select
                    variant="outlined"
                    label="Day"
                    name="day"
                    value={dateOfBirth.day}
                    onChange={handleChange}
                  >
                    {daysArray.map((day) => (
                      <MenuItem key={day} value={day}>
                        {day}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Month</InputLabel>
                  <Select
                    variant="outlined"
                    label="Month"
                    name="month"
                    value={dateOfBirth.month}
                    onChange={handleChange}
                  >
                    {monthsArray.map((month) => (
                      <MenuItem key={month} value={month}>
                        {month}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Year</InputLabel>
                  <Select
                    variant="outlined"
                    label="Year"
                    name="year"
                    value={dateOfBirth.year}
                    onChange={handleChange}
                  >
                    {yearsArray.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <FormControl>
              <FormLabel
                id="genderLabel"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                Gender <img src="/qusertion-mark.png" alt="" />
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="genderLabel"
                name="gender"
                value={gender}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label="Other"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="fieldOfActivityLabel">Role</InputLabel>
              <Select
                labelId="fieldOfActivityLabel"
                id="role"
                value={role}
                name="role"
                label="Select your role"
                onChange={handleChange}
              >
                <MenuItem value={"player"}>Player</MenuItem>
                <MenuItem value={"coach"}>Coach</MenuItem>
                <MenuItem value={"manager"}>Manager</MenuItem>
                <MenuItem value={"press"}>Press</MenuItem>
                <MenuItem value={"fans"}>Fans</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <div style={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Typography variant="body2" sx={{ mb: 1 }}>
            People who use our service may have uploaded your contact
            information to Pic-me.
          </Typography>
        </div>
        <Typography variant="body2" sx={{ mt: 1, mb: 1 }}>
          By clicking Sign Up, you agree to our{" "}
          <Link to="/" style={{ color: "blue" }}>
            Terms
          </Link>
          ,
          <Link to="/" style={{ color: "blue" }}>
            {" "}
            Privacy Policy,
          </Link>{" "}
          and
          <Link to="/" style={{ color: "blue" }}>
            {" "}
            Cookies Policy.
          </Link>
          You may receive SMS notifications from us and can opt out at any time.
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "100%",
          }}
        >
          <Button variant="contained" color="primary" type="submit">
            Sign Up
          </Button>
        </div>

        <Typography variant="body1" sx={{ mt: 2 }}>
          Already have an account?{" "}
          {!!closeModal ? (
            <Link onClick={closeModal}>Sign In</Link>
          ) : (
            <Link to="/login">Sign In</Link>
          )}
        </Typography>
      </Box>
    </Container>
  );
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  closeModal: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert, register })(Register);
