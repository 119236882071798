import React from "react";
import { Box, Grid, Typography, Button, Paper, Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect } from "react";
import CoverImageDefault from "../../img/default-image.png";
import { getMyConnections } from "../../actions/connections";

const ProfileCard = ({
  profile,
  getMyConnections,
  connections: { myConnections },
}) => {
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    getMyConnections(userId);
  }, [getMyConnections, userId]);
  function stringAvatar(name) {
    return {
      children: `${name.split(" ")[0][0]} ${name.split(" ")[1][0]}`,
    };
  }
  return (
    <Grid item xs={12} md={4}>
      <Paper
        elevation={3}
        style={{
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "16px",
        }}
      >
        <Box
          height={120}
          width="100%"
          mb={2}
          sx={{
            background: "linear-gradient(45deg, #e0e0e0 30%, #616161 90%)",
            backgroundSize: 'cover',
            backgroundImage: `url(${
              profile?.coverImage !== ""
                ? `https://${
                    process.env.NODE_ENV === "development"
                      ? "localhost:8080"
                      : "picme.click:8080"
                  }/api/profile/photo/${profile?.coverImage}`
                : CoverImageDefault
            })`,
          }}
          borderRadius={4}
        ></Box>

        <Avatar
          alt={`${profile?.firstname || ""} ${profile?.lastname || ""}`}
          {...stringAvatar(`${profile?.firstname} ${profile?.lastname}`)}
          src={
            profile?.avatar && profile.avatar !== "empty"
              ? `https://${
                  process.env.NODE_ENV === "development"
                    ? "localhost:8080"
                    : "picme.click:8080"
                }/api/profile/photo/${profile?.avatar}`
              : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
          }
          sx={{
            width: 120,
            height: 120,
            marginBottom: 1,
            marginTop: -9,
            textTransform: "capitalize",
            fontSize: "32px",
          }}
        />

        <Typography
          variant="h6"
          sx={{
            fontSize: "24px",
            textTransform: "capitalize",
          }}
        >
          {profile?.firstname || ""} {profile?.lastname || ""}
        </Typography>

        {/* Connections */}
        <Typography
          variant="body2"
          sx={{
            fontSize: "16px",
          }}
        >
          {/* <span style={{ color: "blue" }}>529</span> */}
          {myConnections.list && myConnections.list.length} Connections
        </Typography>

        {/* Manage your network link */}

        <Button
          component={Link}
          to="/dashboard"
          sx={{
            height: "40px",
            display: "flex",
            alignItems: "center",
            fontSize: "16px",
            padding: "16px",
            marginTop: "8px",
          }}
        >
          Manage your network
        </Button>
      </Paper>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  connections: state.connections,
});

export default connect(mapStateToProps, {
  getMyConnections,
})(ProfileCard);
