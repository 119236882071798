import { useLocation, useNavigate } from "react-router-dom";
import { List, ListItem } from "@mui/material";
import { getCurrentProfile } from "../../actions/profile";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CreateIcon from '@mui/icons-material/Create';
import { SettingsNavListItemIcon, SettingsNavbarListItemButton, SettingsNavbarListItemText } from "../../components/list/settings-navbar-list";
import { useEffect } from "react";

const PostNavBar = ({ getCurrentProfile,
  profile: { profile } }) => {
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    getCurrentProfile();
  }, [getCurrentProfile, userId]);
  const experience = profile && profile.experience;
  const menus = [
    {
      title: "Post a free listing",
      route: "/post-listing",
      icon: <CreateIcon />,
      // subTitle: "530",
    },
    // {
    //   title: "Manage My Listing",
    //   // route: "/my-experience",
    //   icon: <ManageAccountsIcon />,
    //   // subTitle: `${experience?.length}`

    // },
  ];
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <List>
      {menus.map((item, index) => (
        <ListItem key={`settings-nav-item-${index}}`} disablePadding>
          <SettingsNavbarListItemButton
            selected={location.pathname === item.route}
            onClick={() => navigate(item.route)}
          >
            <SettingsNavListItemIcon
              sx={{
                color: "inherit",
              }}
            >
              {item.icon}
            </SettingsNavListItemIcon>
            <SettingsNavbarListItemText
              primary={item.title}
              secondary={item.subTitle}
            />
          </SettingsNavbarListItemButton>
        </ListItem>
      ))}
    </List>
  );
};
PostNavBar.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { getCurrentProfile })(PostNavBar);


