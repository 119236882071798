import {
  ListItemButton,
  listItemButtonClasses,
  ListItemText,
  ListItemIcon as MuiListItemIcon,
  listItemIconClasses,
  listItemTextClasses,
  styled,
} from "@mui/material";

export const SettingsNavbarListItemButton = styled(({ ...props }) => (
  <ListItemButton {...props} />
))((props) => ({
  [`&.${listItemButtonClasses.root}`]: {
    padding:"13px 12px",
    color: "#00000099",
    gap: "10px",
  },
  [`&.${listItemButtonClasses.selected}`]: {
    backgroundColor: "#DADADA57",
    color: "#00000099",
  },
}));

export const SettingsNavbarListItemText = styled(({ ...props }) => (
  <ListItemText {...props} />
))((props) => ({
  [`&.${listItemTextClasses.root}`]: {
    display: "flex",
    justifyContent: "space-between",
    margin: 0,
    [`& .${listItemTextClasses.primary}`]: {
      fontSize: 16,
    },
    [`& .${listItemTextClasses.secondary}`]: {
      fontSize: 16,
    },
  },
}));
export const SettingsNavListItemIcon = styled(({ ...props }) => (
  <MuiListItemIcon {...props} />
))((props) => ({
  [`&.${listItemIconClasses.root}`]: {
    minWidth: "34px",
    justifyContent: "center",
  },
}));
