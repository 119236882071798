import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import BusinessIcon from "@mui/icons-material/Business";
const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);
const card = (
  <React.Fragment>
    <CardContent>
      <BusinessIcon />
      <Typography variant="h5" component="div">
        Picmeclick LLC
      </Typography>
      <Divider />
      <Typography sx={{ mt: 1.5 }} color="text.secondary"></Typography>
      <Typography variant="body2" sx={{ mb: 1.5 }}>
        1310 {bull} 701 Tillery Street <br />
        Unit 12{bull}Austin{bull}TX, {bull}Travis <br /> US {bull}78702
      </Typography>
      <Divider />
      <Typography variant="body2">
        <a href="mailto:info@picme.click">info@picme.click</a>
      </Typography>
    </CardContent>
  </React.Fragment>
);

export default function AddressCard() {
  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">{card}</Card>
    </Box>
  );
}
