import { Box, Grid } from "@mui/material";
import React from "react";
import { AdminNavbar } from "./AdminNavbar";
export const AdminSidebar = () => {
  return (
    <Grid
      item
      xs={12}
      sx={{
        background: "#FFFFFF",
        boxShadow: "0px 0px 0px 2px rgb(0 0 0 / 10%)",
        borderRadius: "0.8rem",
        paddingY: "15px !important",
        overflow: "auto",
        minHeight: "500px",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "flex-start",
        maxWidth: "300px",
      }}
    >
      <Box
        sx={{
          width: "100%",
        }}
      >
        {/* <Typography
          sx={{ fontSize: "16px", fontWeight: "500", color: "#000000E6", paddingX: "12px" }}
        >
        </Typography> */}
        {<AdminNavbar />}
      </Box>
    </Grid>
  );
};
