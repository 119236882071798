import { SHOW_AGREEMENT, HIDE_AGREEMENT } from "../actions/types";

const initialState = [];

function agreementsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SHOW_AGREEMENT:
      return [true, payload.name];
    case HIDE_AGREEMENT:
      return [false, payload.name];
    default:
      return state;
  }
}

export default agreementsReducer;
