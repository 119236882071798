/* eslint-disable array-callback-return */
import { Box, Button, Grid, Typography, Avatar, Divider } from "@mui/material";
import React, { useEffect } from "react";
import { getMyInvitations } from "../../actions/connections";
import { connect } from "react-redux";
import { putInvitationAccepted } from "../../actions/connections";
import { deleteInvitationAccepted } from "../../actions/connections";

const Invitation = ({
  getMyInvitations,
  connections: {
    myInvitation,
    rejectInvitationRequest,
    acceptInvitationRequest,
  },
  putInvitationAccepted,
  deleteInvitationAccepted,
}) => {
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    if (rejectInvitationRequest.status === 200) {
      getMyInvitations(userId);
    }
    if (acceptInvitationRequest.status === 200) {
      getMyInvitations(userId);
    }
  }, [
    acceptInvitationRequest,
    getMyInvitations,
    rejectInvitationRequest,
    userId,
  ]);

  useEffect(() => {
    getMyInvitations(userId);
  }, [getMyInvitations, userId]);


  const onSubmit = (receiverId) => {
    putInvitationAccepted(receiverId);
  };

  const deleteRequest = (receiverinvitaionId) => {
    deleteInvitationAccepted(receiverinvitaionId);
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        background: "#FFFFFF",
        boxShadow: "0px 0px 0px 2px rgb(0 0 0 / 10%)",
        borderRadius: "0.8rem",
        // paddingY: "15px !important",
        overflow: "auto",
        // minHeight: "00px",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "flex-start",
        maxWidth: "829px !important",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          padding: "15px 20px",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            color: "#000000E6",
            // paddingX: "12px",
          }}
        >
          Invitations
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            color: "#00000099",
          }}
        >
          See all {myInvitation?.list && myInvitation?.list.length}
        </Typography>
      </Box>
      <Divider width={"100%"} />
      {myInvitation?.list && myInvitation?.list.length !== 0 ? (
        myInvitation.list.map((users, index) => {
          return (
            <>
              <Box
                key={index}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "15px 20px",
                }}
              >
                <a href={`/profile/${users?.friend?.user}`}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{}}>
                      <Avatar
                        alt={users.requestSender.profileId.firstname}
                        src={
                          users.requestSender.profileId.avatar &&
                          users.requestSender.profileId.avatar !== "empty"
                            ? `https://${
                                process.env.NODE_ENV === "development"
                                  ? "localhost:8080"
                                  : "picme.click:8080"
                              }/api/profile/photo/${
                                users.requestSender.profileId.avatar
                              }`
                            : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
                        }
                        sx={{
                          height: "70px",
                          width: "70px",
                          textTransform: "capitalize",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "15px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "700",
                          color: "rgba(0, 0, 0, .9);",
                        }}
                      >
                        {users.requestSender.profileId.firstname}
                        {""} {users.requestSender.profileId.lastname}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          // fontWeight: "400",
                          color: "rgba(0, 0, 0, .6);",
                        }}
                      >
                        {users.content}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          // fontWeight: "100",
                          color: "rgba(0, 0, 0, .6);",
                        }}
                      >
                        {users.subcontent}
                      </Typography>
                    </Box>
                  </Box>
                </a>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Button
                    variant="text"
                    sx={{
                      fontSize: "16px",
                      color: "#0a66c2",
                      height: "32px",
                      background: "transparent",
                      padding: "10px 30px",
                    }}
                    onClick={() => deleteRequest(users._id)}
                  >
                    Ignore
                  </Button>

                  <Button
                    variant="text"
                    sx={{
                      fontSize: "16px",
                      color: "#2065D1",
                      height: "32px",
                      background: "white",
                      padding: "10px 30px",
                      borderRadius: "20px",
                      border: "1px solid #0a66c2",
                      fontWeight: "600",
                    }}
                    onClick={() => onSubmit(users._id)}
                  >
                    Accept
                  </Button>
                </Box>
              </Box>
              <Divider width={"100%"} />
            </>
          );
        })
      ) : (
        <Box
          sx={{
            padding: "16px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              color: "black",
            }}
          >
            No Invitation Yet
          </Typography>
        </Box>
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  connections: state.connections,
});

export default connect(mapStateToProps, {
  getMyInvitations,
  putInvitationAccepted,
  deleteInvitationAccepted,
})(Invitation);
