import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";
import { getCurrentProfile } from "../../actions/profile";
import ProfilePopup from "./ProfilePopup";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import AppBar from "@mui/material/AppBar";
import { Box, TextField, Button, Typography } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Avatar from "@mui/material/Avatar";
import PicMeLogo from "../../img/logo.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ItemList from "../navbar/listItem";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDial from "@mui/material/SpeedDial";
import SearchBar from "./SearchBar";
import { postActivityDetails } from "../../actions/profile";

const initialState = {
  firstname: "",
  lastname: "",
  nickname: "",
  sport: "",
  country: "",
  city: "",
  website: "",
  bio: "",
  githubusername: "",
  experience: [
    {
      title: "",
      company: "",
      location: "",
      from: null,
      to: null,
      current: false,
      description: "",
    },
  ],
  education: [
    {
      school: "",
      degree: "",
      fieldofstudy: "",
      from: null,
      to: null,
      current: false,
      description: "",
    },
  ],
  social: {
    youtube: "",
    twitter: "",
    facebook: "",
    linkedin: "",
    instagram: "",
  },
  approved: false,
  teamName: "",
  coachingHistory: [],
  qualifications: [],
  height: 0,
  weight: 0,
  schooling: "",
  tertiaryEducation: "",
  favouriteMovie: "",
  favouriteArtist: "",
  favouriteFood: "",
  hobbies: [],
  playingPosition: "",
  sportingHistory: [],
  achievements: [],
  role: "",
  avatar: "empty",
  coverImage: "",
  dateOfBirth: "",
  gender: "",
  email: "",
  description: "",
};

const Navbar = ({
  auth: { isAuthenticated, user },
  profile: { profile, loading },
  getCurrentProfile,
  postActivityDetails,
  logout,
}) => {
  const [formData, setFormData] = useState(initialState);
  const navigate = useNavigate();
  const avatar = profile?.avatar;
  useEffect(() => {
    let userId = localStorage.getItem("userId");
    if (profile?.user?._id === userId) {
      localStorage.setItem("firstName", profile.firstname);
      localStorage.setItem("lastName", profile.lastname);
    }
  }, [profile]);
  useEffect(() => {
    getCurrentProfile();
  }, [getCurrentProfile]);
  useEffect(() => {
    // if (!profile) getCurrentProfile();
    if (!loading && profile) {
      const profileData = { ...initialState };
      for (const key in profile) {
        if (key in profileData) profileData[key] = profile[key];
      }

      setFormData(profileData);
    }
  }, [loading, profile]);

  // function randomColor() {
  //   let hex = Math.floor(Math.random() * 0xffffff);
  //   let color = "#" + hex.toString(16);
  //   return color;
  // }

  function AuthLinks() {
    const firstname = localStorage.getItem("firstName");
    const lastname = localStorage.getItem("lastName");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    function stringAvatar(name) {
      return {
        children: `${name.split(" ")[0][0]} ${name.split(" ")[1][0]}`,
      };
    }
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          gap: "16px",
        }}
      >
        {/* <Typography variant="button" sx={{ m: 0.5 }}>
          <Link sx={{ mr: 2 }} to="/profiles">
            Players
          </Link>
        </Typography>
        <Typography variant="button" sx={{ m: 0.5 }}>
          <Link to="/posts">Posts</Link>
        </Typography> */}
        {/* <Typography variant="button" sx={{ m: 0.5 }}>
          <Link to="/" onClick={logout}>
            Logout
          </Link>
        </Typography> */}

        {/* <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          badgeContent={
            !formData?.approved && formData?.avatar !== "empty" ? (
              <SmallAvatar
                sx={{ bgColor: "transparent", border: "0px" }}
                variant="square"
              >
                <ErrorOutlineIcon color="warning" />
              </SmallAvatar>
            ) : null
          }
        > */}
        {true ? (
          <div>
            <Avatar
              sx={{
                m: 0.5,
                textTransform: "capitalize",
                fontSize: "16px",
              }}
              aria-label="recipe"
              alt={`${(user && firstname) || ""} ${(user && lastname) || ""}`}
              {...stringAvatar(`${profile?.firstname} ${profile?.lastname}`)}
              src={
                avatar && avatar !== "empty"
                  ? `https://${
                      process.env.NODE_ENV === "development"
                        ? "localhost:8080"
                        : "picme.click:8080"
                    }/api/profile/photo/${avatar}`
                  : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
              }
              {...stringAvatar(`${user && firstname} ${user && lastname}`)}
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              {/* {formData.lastname} */}
            </Avatar>
            {open && (
              <ProfilePopup
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
              />
            )}
          </div>
        ) : (
          <Avatar
            sx={{ bgcolor: "transparent" }}
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <AccountCircleIcon fontSize="large" color="secondary" />
          </Avatar>
        )}
        {/* </Badge> */}

        {/* <Typography sx={{
          m: 0.5, fontSize: '16px',
          fontWeight: 600,
        }}> {user && firstName} {user && lastName}</Typography> */}
      </Box>
    );
  }

  function GuestLinks() {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          gap: "16px",
        }}
      >
        {/* <Typography variant="button" sx={{ m: 0.5 }}>
          <Link sx={{ mr: 2 }} to="/profiles">
            Players
          </Link>
        </Typography> */}

        <Typography variant="button" sx={{ m: 0.5 }}>
          <Link sx={{ mr: 2 }} to="/register">
            Register
          </Link>
        </Typography>

        <Typography variant="button" sx={{ m: 0.5 }}>
          <Link sx={{ mr: 2 }} to="/login">
            Login
          </Link>
        </Typography>
      </Box>
    );
  }

  function routeToMainPage() {
    navigate("/");
  }
  const [open, setOpen] = useState(false);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmitActivity = (e) => {
    e.preventDefault();
    const payload = {
      email: formData.email,
      description: formData.description,
    };
    postActivityDetails(payload);
    setPopUpOpen(true);
  };
  const handleClick = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
    setPopUpOpen(false);
    setFormData({});
  };

  useEffect(() => {
    if (open === true && popUpOpen === true) {
      handleClose();
    }
  }, [open, popUpOpen]);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar color="inherit" position="fixed">
        <Toolbar>
          <Box sx={{ display: { md: "flex" } }}>
            <Avatar
              alt="PicMeLogo"
              src={PicMeLogo}
              variant="square"
              onClick={routeToMainPage}
            ></Avatar>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
            ></Typography>
            {isAuthenticated ? <SearchBar /> : ""}
          </Box>

          <Box sx={{ display: { md: "flex" }, marginLeft: "auto" }}>
            {isAuthenticated ? <ItemList /> : ""}
          </Box>

          <Box sx={{ display: { md: "flex" }, marginLeft: "auto" }}>
            {isAuthenticated ? <AuthLinks /> : <GuestLinks />}
          </Box>
        </Toolbar>
      </AppBar>
      <TawkMessengerReact
        propertyId="658c4d9670c9f2407f83c060"
        widgetId="1hiq0ho86"
      />
      <Box
        sx={{ transform: "translateZ(0px)", flexGrow: 1 }}
        className="SpeedDial"
      >
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{
            position: "absolute",
            bottom: 16,
            right: 16,
            "& button.MuiButtonBase-root.MuiFab-root": {
              height: "64px",
              width: "64px",
            },
            "& div#SpeedDialbasicexample-actions": {
              display: !open ? "none !important" : "block !important",
            },
          }}
          icon={<SpeedDialIcon />}
          open={open}
          onClick={handleClick}
          direction="up"
        >
          {open && (
            <form
              onSubmit={handleSubmitActivity}
              onClick={(e) => e.stopPropagation()}
            >
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                margin="normal"
                name={`email`}
                value={formData.email}
                onChange={handleChange}
              />
              <TextField
                label="Description"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                margin="normal"
                name={`description`}
                value={formData.description}
                onChange={handleChange}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSubmitActivity}
              >
                Send
              </Button>
            </form>
          )}
        </SpeedDial>
      </Box>
    </Box>
  );
};

Navbar.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  postActivityDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, {
  getCurrentProfile,
  logout,
  postActivityDetails,
})(Navbar);
