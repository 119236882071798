/* eslint-disable array-callback-return */
import { Box, Grid, Typography, Divider, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { deleteExperienceDetail } from "../../actions/profile";


const ExperienceCard = ({ openExperienceModal,userId, getCurrentProfile, experience, setEditedData, deleteExperienceDetail, profile: { experienceDetailDelete, } }) => {

  const handleClickEditOpen = (data, action) => {
    openExperienceModal();
    setEditedData({ ...data, action });
  };

  useEffect(() => {
    if (experienceDetailDelete.status === 200) {
      getCurrentProfile();
    }
  }, [experienceDetailDelete]);
  
  const handleDeleteExperience = (user, experienceId) => {
    const payload = { user, experienceId }
    deleteExperienceDetail(payload);
  };
  return (
    <Grid
      item
      xs={12}
      sx={{
        background: "#FFFFFF",
        boxShadow: "0px 0px 0px 2px rgb(0 0 0 / 10%)",
        borderRadius: "0.8rem",
        // paddingY: "15px !important",
        overflow: "auto",
        // minHeight: "00px",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        // maxWidth: "829px !important",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          padding: "15px 20px",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            color: "#000000",
          }}
        >
          Experience
        </Typography>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: "#00000099",
              paddingRight: "16px",
            }}
          >
            <Button
              variant="contained"
              color="inherit"
              style={{
                width: "100%",
                marginBottom: "16px",
                background: "#e4e6eb",
                fontSize: "14px",
                fontWeight: 600,
              }}
              onClick={openExperienceModal}
            >
              <AddIcon />
            </Button>
          </Typography>
        </Box>
      </Box>

      <Divider width={"100%"} />
      {experience?.length !== 0 ?
        experience?.map((users, index) => {
          const fromDate = new Date(users.from);
          const toDate =
            users.current === true ? new Date() : new Date(users.to);

          const yearDifference = toDate.getFullYear() - fromDate.getFullYear();
          const monthDifference = toDate.getMonth() - fromDate.getMonth();

          // Construct the formatted string
          let formattedDistance = "";
          if (yearDifference > 0) {
            formattedDistance += `${yearDifference} ${yearDifference === 1 ? "year" : "years"
              }`;
          }
          if (monthDifference > 0) {
            if (formattedDistance !== "") {
              formattedDistance += " ";
            }
            formattedDistance += `${monthDifference} ${monthDifference === 1 ? "month" : "months"
              }`;
          }
          const formatDate = (date) => {
            const monthNames = [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ];
            const month = monthNames[date.getMonth()];
            const year = date.getFullYear();
            return `${month} ${year}`;
          };

          const formattedFromDate = formatDate(fromDate);
          const formattedToDate = formatDate(toDate);
          return (
            <>
              <Box
                key={index}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "15px 20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <Box sx={{}}>
                    <img
                      alt={users.name}
                      src={users.experienceImage && users.experienceImage !== "empty"
                        ? `https://${process.env.NODE_ENV === "development"
                          ? "localhost:8080"
                          : "picme.click:8080"
                        }/api/profile/experience/photo/${users?.experienceImage}`
                        : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"}

                      height={"50px"}
                      width={"50px"}
                    />
                  </Box>
                  <Box
                    sx={{
                      marginLeft: "15px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#000000",
                      }}
                    >
                      {users.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#0000009",
                      }}
                    >
                      {`${users.title} at ${users.company} , ${users.location}`}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "rgba(0, 0, 0, .6)",
                      }}
                    >
                      {`${formattedFromDate} - ${users.current === true ? "Present" : formattedToDate
                        } - ${formattedDistance} `}
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 1.0)",
                        paddingTop: "12px",
                      }}
                    >
                      {users.description}
                    </Typography>
                  </Box>
                </Box>
                <Button
                  sx={{
                    fontSize: "16px",
                    color: "none",
                    border: "none",
                  }}
                  onClick={() => handleClickEditOpen(users, "edit")}
                >
                  <EditIcon />
                </Button>

                <Button
                  sx={{
                    fontSize: "16px",
                    color: "none",
                    border: "none",
                  }}
                  onClick={() => handleDeleteExperience(userId, users._id)}
                >
                  <DeleteIcon />
                </Button>
              </Box >

              <Divider width={"100%"} />
            </>
          );
        }) : <Box
          sx={{
            padding: "16px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              color: "black",
            }}
          >
            No Experience Added Yet
          </Typography>
        </Box>}
    </Grid >
  );
};
const mapStateToProps = (state) => ({
  profile: state.profile,

});
export default connect(mapStateToProps, { deleteExperienceDetail })(
  ExperienceCard
);