import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
// import DashboardActions from "./DashboardActions";
// import Experience from "./Experience";
// import Education from "./Education";
import { deleteAccount } from "../../actions/profile";
import { Helmet } from "react-helmet-async";
import WhatsOnYourMind from "../../components/homepage/WhatsOnYourMind";
import NewFeeds from "../../components/homepage/NewFeeds";
import DashboardHeader from "../personalAccount/DashboardHeader";
import UserProfile from "../personalAccount/UserProfile";
import IntroCard from "../../components/personalAccount/IntroCard";
import { ListPage } from "../../pages/my-lists/List";
import ExperiencePart from "../../pages/my-lists/ExperiencePart";
import CertificateList from "../../pages/my-lists/CertificateList";
import AwardList from "../../pages/my-lists/AwardList";
import ShowReel from "../../pages/my-lists/ShowReel";
import ProfileFriends from "./ProfileFriends";
import { getUserPost } from "../../actions/post";

import {
  Grid,
  Container,
  // Paper,
  Typography,
  Button,
  // Modal,
} from "@mui/material";
// import { Close as CloseIcon } from "@mui/icons-material";
// import AddExperience from "../profile-forms/AddExperience";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import { CustomTabs, CustomTabsPanel } from "../../components/tabs/custom-tabs";
// import Tabs from "@mui/material/Tabs";
import { tabMenu } from "../../utils/consts";
// import { Users } from "../users/Users";
import PhotosAndVideos from "../../pages/dashboard/Photos";
import { getProfileById } from "../../actions/profile";

const Profile = ({
  getProfileById,
  profile: { profileByID },
  auth,
  getUserPost,
  post: { userPosts },
}) => {
  const { id } = useParams();
  useEffect(() => {
    getProfileById(id);
    getUserPost(id);
  }, [getProfileById, getUserPost, id]);

  const [value, setValue] = useState("posts");

  const handleChange = (event) => {
    setValue(event);
  };
  const ProfileProp = "Profile";
  // const experience = profileByID && profileByID.experience
  return (
    <Container sx={{ marginTop: 10, padding: "0 !important" }}>
      <Helmet>
        {/*
        <script
          async
          src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${"748650393078-b8o43mblb8i7bm9kbip16vggmoorum0s.apps.googleusercontent.com"}`}
          crossorigin="anonymous"
        />
        */}
      </Helmet>
      {profileByID !== null ? (
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ padding: "0 !important" }}>
            <DashboardHeader profile={profileByID} ProfileProp={ProfileProp} />
            <UserProfile
              profile={profileByID}
              deleteAccount={deleteAccount}
              ProfileProp={ProfileProp}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              background: "#FFFFFF",
              boxShadow: "0px 3px 2px 0px rgb(0 0 0 / 10%)",
              borderRadius: "6px",
              padding: "15px !important",
              overflow: "auto",
              borderTopRightRadius: "0px !important",
              borderTopLeftRadius: "0px !important",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                maxHeight: "29px",
                width: "max-content",
              }}
            >
              {tabMenu.map((val) => (
                <>
                  <CustomTabs
                    label={val.label}
                    onChange={handleChange}
                    value={val.value}
                    value1={value}
                  />
                </>
              ))}
            </Box>
          </Grid>
          <Box sx={{ width: "100%" }}>
            {tabMenu.map((tabs, ind) => (
              <CustomTabsPanel
                value={tabs.value}
                value1={value}
                sx={{
                  "& .MuiTabPanel-root": {
                    paddingRight: "0px !important",
                  },
                }}
              >
                {value === "posts" && (
                  <Grid
                    container
                    spacing={6}
                    sx={{
                      marginLeft: "-24px !important",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={4}
                      sx={{
                        paddingLeft: "0 !important",
                      }}
                    >
                      <IntroCard
                        profile={profileByID}
                        ProfileProp={ProfileProp}
                        // openBioModal={openBioModal}
                        // openExperienceModal={openExperienceModal}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={8}
                      sx={{
                        paddingLeft: "24px !important",
                      }}
                    >
                      {/* <WhatsOnYourMind profile={profileByID} /> */}
                      <NewFeeds
                        posts={userPosts.post}
                        id={id}
                        ProfileProp={ProfileProp}
                      />
                    </Grid>
                  </Grid>
                )}
                {value === "friends" && <ProfileFriends id={id} />}
                {value === "photoVideo" && (
                  <PhotosAndVideos userId={id} showVideo={true} />
                )}
                {/* {value === "socialCircle" && <h4>Social Circle</h4>}
                {value === "myListings" && <ListPage />}
                {value === "experience" && <ExperiencePart />}
                {value === "showReel" && <ShowReel />}
                {value === "certificates" && <CertificateList />}
                {value === "awards" && <AwardList />} */}
              </CustomTabsPanel>
            ))}
          </Box>

          {/* <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <IntroCard
                  profile={profile}
                  openBioModal={openBioModal}
                  openExperienceModal={openExperienceModal}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <WhatsOnYourMind profile={profile} />
                <NewFeeds />
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      ) : (
        <Grid container spacing={3} marginTop={20}>
          <Grid item xs={12}>
            <Typography variant="h4">
              You have not yet set up a profile, please add some info
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              component={Link}
              to="/create-profile"
              variant="contained"
              color="primary"
            >
              Create Profile
            </Button>
          </Grid>
        </Grid>
      )}
      {/* <Modal
        open={isBioModalOpen}
        onClose={closeBioModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            borderRadius: "15px",
            padding: "12px",
            height: "80vh",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <p>Fill in your Intro</p>

            <Button sx={{ height: "20px" }} onClick={closeBioModal}>
              <CloseIcon />
            </Button>
          </div>
        </div>
      </Modal> */}
      {/* <Modal
        open={isExperiernceModalOpen}
        onClose={closeExperienceModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            borderRadius: "15px",
            padding: "12px",
            height: "80vh",
            overflowY: "auto",
            width: isSmallScreen ? "90%" : "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <AddExperience closeExperienceModal={closeExperienceModal} />

            {/* <Button sx={{ height: "20px" }} onClick={closeExperienceModal}>
                  <CloseIcon />
                </Button> 
          </div>
        </div>
      </Modal> */}
    </Container>
    // <section className="container">
    //   {profile === null ? (
    //     <Spinner />
    //   ) : (
    //     <Fragment>
    //       <Link to="/profiles" className="btn btn-light">
    //         Back To Profiles
    //       </Link>
    //       {auth.isAuthenticated &&
    //         auth.loading === false &&
    //         auth.user._id === profile.user._id && (
    //           <Link to="/edit-profile" className="btn btn-dark">
    //             Edit Profile
    //           </Link>
    //         )}
    //       <div className="profile-grid my-1">
    //         <ProfileTop profile={profile} />
    //         <ProfileAbout profile={profile} />
    //         <div className="profile-exp bg-white p-2">
    //           <h2 className="text-primary">Experience</h2>
    //           {profile.experience.length > 0 ? (
    //             <Fragment>
    //               {profile.experience.map((experience) => (
    //                 <ProfileExperience
    //                   key={experience._id}
    //                   experience={experience}
    //                 />
    //               ))}
    //             </Fragment>
    //           ) : (
    //             <h4>No experience credentials</h4>
    //           )}
    //         </div>

    //         <div className="profile-edu bg-white p-2">
    //           <h2 className="text-primary">Education</h2>
    //           {profile.education.length > 0 ? (
    //             <Fragment>
    //               {profile.education.map((education) => (
    //                 <ProfileEducation
    //                   key={education._id}
    //                   education={education}
    //                 />
    //               ))}
    //             </Fragment>
    //           ) : (
    //             <h4>No education credentials</h4>
    //           )}
    //         </div>

    //         {profile.githubusername && (
    //           <ProfileGithub username={profile.githubusername} />
    //         )}
    //       </div>
    //     </Fragment>
    //   )}
    // </section>
  );
};

Profile.propTypes = {
  getProfileById: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
  post: state.post,
});

export default connect(mapStateToProps, { getProfileById, getUserPost })(
  Profile
);
