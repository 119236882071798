import { Dialog, Avatar } from "@mui/material";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";

export const UserReels = ({ onClose, open, reel, isVideo, isImage }) => {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      sx={{
        flexShrink: 0,
        "& .MuiDialog-paper": {
          height: "100%",
          width: "100%",
        },
      }}
      anchor="right"
    >
      <AwesomeSlider animation="cubeAnimation">
        {reel &&
          reel.map((reel, index) => {
            return (
              <div key={index}>
                <Avatar
                  className="story__avatar"
                  style={{
                    zIndex: 1,
                    position: "absolute",
                    top: "22px",
                    left: "10px",
                  }}
                  src={
                    reel?.profile?.avatar && reel.profile.avatar !== "empty"
                      ? `https://${
                          process.env.NODE_ENV === "development"
                            ? "localhost:8080"
                            : "picme.click:8080"
                        }/api/profile/photo/${reel.profile?.avatar}`
                      : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
                  }
                />
                <h4
                  style={{
                    zIndex: 1,
                    position: "absolute",
                    top: "40px",
                    left: "70px",
                    color: "#FFFFFF",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  {reel.profile.firstname} {reel.profile.lastname}
                </h4>

                <div className="story__video-container">
                  {(() => {
                    const fileExtension = reel?.image
                      ?.split(".")
                      .pop()
                      .toLowerCase();
                    if (
                      fileExtension === "jpg" ||
                      fileExtension === "jpeg" ||
                      fileExtension === "png"
                    ) {
                      return (
                        <div
                          style={{
                            backgroundImage: `url(${
                              reel?.image && reel?.image !== "empty"
                                ? `https://${
                                    process.env.NODE_ENV === "development"
                                      ? "localhost:8080"
                                      : "picme.click:8080"
                                  }/api/reels/image/${reel.image}`
                                : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
                            })`,
                            minHeight: "100% !important",
                            minWidth: "100% !important",
                            width: "100% !important",
                            height: "100% !important",
                            backgroundSize: "100% 100% !important",
                          }}
                          className="stories__image"
                        />
                      );
                    } else if (
                      fileExtension === "mp4" ||
                      fileExtension === "webm" ||
                      fileExtension === "ogg"
                    ) {
                      return (
                        <video
                          autoPlay
                          loop
                          muted
                          className="stories__video"
                          style={{
                            minHeight: "100% !important",
                            minWidth: "100% !important",
                            width: "100% !important",
                            height: "100% !important",
                            backgroundSize: "100% 100% !important",
                          }}
                        >
                          <source
                            src={
                              reel?.image && reel?.image !== "empty"
                                ? `https://${
                                    process.env.NODE_ENV === "development"
                                      ? "localhost:8080"
                                      : "picme.click:8080"
                                  }/api/reels/video/${reel.image}`
                                : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
                            }
                            type={`video/${fileExtension}`}
                          />
                          Your browser does not support the video tag.
                        </video>
                      );
                    } else {
                      return (
                        <img
                          key={index}
                          src={
                            "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
                          }
                          alt={reel.profile.firstname}
                        />
                      );
                    }
                  })()}
                </div>
              </div>
            );
          })}
      </AwesomeSlider>
    </Dialog>
  );
};
