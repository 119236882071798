import { Box, Grid, Typography } from "@mui/material";
import React from "react";

export const MediaAboutCard = () => {
  return (
    <Grid
      container
      spacing={3}
      sx={{
        padding: "35px",
        paddingRight: "10px",
        paddingTop: "34px",
        gap: "10px",
      }}
    >
      {/* <Grid
        item
        xs={4}
        sx={{ padding: "0 !important", }}
      >
        <AboutSidebar />
      </Grid> */}

      {/* <Grid item xs={7} sx={{
        display: "flex",
        flexDirection: 'column',
        gap: "16px",
        padding: "0 !important"
      }} > */}

      {/* <Grid item xs={12} sx={{
          backgroundColor: 'white',
          borderRadius: '0.8rem',
          boxShadow: '0px 5px 7px -7px rgba(0, 0, 0, 0.75)',
          padding: "12px !important",
          maxWidth: "829px !important"
        }}>
        </Grid> */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          // padding: "15px 20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          <Box sx={{}}>
            <img
              alt={""}
              src={"/experience-default-img.png"}
              height={"50px"}
              width={"70px"}
            />
          </Box>
          <Box
            sx={{
              marginLeft: "15px",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#000000",
              }}
            >
              Head of the Project
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#0000009",
              }}
            >
              HR Manager at Dextopia Technologies
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                color: "rgba(0, 0, 0, .6)",
              }}
            >
              Feb 2018 - Present - 5yr 10 mos
            </Typography>

            <Typography
              sx={{
                fontSize: "16px",
                color: "rgba(0, 0, 0, 1.0)",
                paddingTop: "12px",
              }}
            >
              is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s,
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* </Grid> */}
    </Grid>
  );
};
