import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  IconButton,
  Avatar,
  Typography,
  Box,
  Button,
} from "@mui/material";
import moment from "moment";
import CommentFormMui from "../post/CommentFormMui";
import { getPosts, getUserPost } from "../../actions/post";
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;

  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function PostItemMui({
  post,
  showActions,
  addLike,
  removeLike,
  deletePost,
  getPosts,
  posts,
  getUserPost,
  userId,
}) {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    approved,
    _id,
    image,
    text,
    user,
    profile,
    likes,
    comments,
    date,
    __v,
  } = post;
  debugger;
  const { id } = useParams();
  const likeEmployeeID = likes && likes?.map((val) => val.user);
  const userID = localStorage.getItem("userId");
  const currentUserId = !id ? localStorage.getItem("userId") : id;
  const [value, setValue] = useState(likeEmployeeID.includes(userID));
  useEffect(() => {
    setValue(likeEmployeeID.includes(userID));
  }, [userID, likeEmployeeID]);
  console.log(value);
  const {
    avatar: profileAvatar,
    _id: profileId,
    firstname,
    lastname,
  } = profile || {};

  const imageIsVideo = /\.(mp4|avi|mkv)$/i.test(image);

  const getImageSource = (imageName) => {
    const baseUrl =
      process.env.NODE_ENV === "development"
        ? "localhost:8080"
        : "picme.click:8080";

    if (imageIsVideo) {
      return `https://${baseUrl}/api/posts/video/${imageName}`;
    } else {
      return `https://${baseUrl}/api/posts/image/${imageName}`;
    }
  };

  const [expanded, setExpanded] = useState(false);
  const [imageError, setImageError] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleImageError = () => {
    setImageError(true);
  };
  // const handleAddComment = (e) => {
  //   e.preventDefault();
  //   navigate(`/posts/${_id}`);
  // };

  const postDate = moment.utc(date).local();
  const currentDate = moment();

  // Calculate the difference in months between the current date and the post date
  const monthsDiff = currentDate.diff(postDate, "months");

  let formattedDate;
  if (monthsDiff <= 1) {
    // If the post is exactly one month old, display "one month ago"
    formattedDate = moment.utc(date).local();
  }
  if (monthsDiff >= 1) {
    // If the post is one month or older, format the actual posted date
    formattedDate = postDate.format("DD MMMM YYYY"); // Example: 10 February 2023
  } else {
    // If the post is less than one month old, display the time difference using fromNow
    formattedDate = postDate.fromNow(); // Example: "2 days ago"
  }
  const handleAddLike = (_id) => {
    debugger;
    dispatch(addLike(_id));
    setValue(true);
    setTimeout(() => {
      getPosts();
      getUserPost(currentUserId);
    }, 500);
  };
  const handleRemoveLike = (_id) => {
    debugger;
    dispatch(removeLike(_id));
    setValue(false);
    setTimeout(() => {
      getPosts();
      getUserPost(currentUserId);
    }, 500);
  };
  console.log(likes, "likes", value);
  return (
    <Card style={{ marginBottom: "16px" }}>
      <CardHeader
        avatar={
          <Avatar
            alt={firstname}
            src={
              profileAvatar && profileAvatar !== "empty"
                ? `https://${
                    process.env.NODE_ENV === "development"
                      ? "localhost:8080"
                      : "picme.click:8080"
                  }/api/profile/photo/${profileAvatar}`
                : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
            }
          />
        }
        title={`${firstname || "John"} ${lastname || "Deo"}`}
        subheader={formattedDate}
        action={
          !auth?.loading &&
          user === auth?.user._id && (
            <IconButton
              aria-label="settings"
              onClick={() => dispatch(deletePost(_id))}
            >
              {/* <DeleteIcon /> */}
            </IconButton>
          )
        }
        sx={{
          "&.MuiCardHeader-root": {
            padding: "16px",
            paddingBottom: "0 !important",
          },
          "& .MuiCardHeader-title": {
            fontSize: "15px",
            color: "#050505",
            fontWeight: 600,
            margin: "0px !important",
            textTransform: "capitalize",
          },
          "& .MuiCardHeader-subheader": {
            fontSize: "13px",
            color: "#65676b",
            margin: "0px !important",
          },
          "& .MuiCardHeader-content": {
            textAlign: "start",
          },
        }}
      />
      <CardContent
        sx={{
          "&.MuiCardContent-root": {
            paddingBottom: "0 !important",
            padding: "16px",
            textAlign: "start",
          },
        }}
      >
        <Typography
          paragraph
          sx={{
            marginTop: "0px !important",
          }}
        >
          {text}
        </Typography>
      </CardContent>
      <CardContent
        sx={{
          "&.MuiCardContent-root": {
            padding: "0 !important",
          },
        }}
      >
        {!imageError && image && (
          <CardMedia
            component={imageIsVideo ? "video" : "img"}
            alt={text}
            src={getImageSource(image)}
            controls={imageIsVideo}
            style={{
              maxHeight: "400px",
              objectFit: "cover",
              width: "100%",
              marginTop: "16px",
            }}
            onError={handleImageError}
          />
        )}
        {imageError && (
          <Typography variant="body2" color="error" sx={{ paddingX: "16px" }}>
            Image or video failed to load.
          </Typography>
        )}
        {/* <CardMedia
          component={imageIsVideo ? "video" : "img"}
          alt={text}
          src={"https://picsum.photos/id/237/200/300"}
          controls={imageIsVideo}
          style={{
            maxHeight: "400px",
            objectFit: "cover",
            width: "100%",
          }}
          onError={handleImageError}
        /> */}
      </CardContent>
      {/* {!expanded && (
        <Typography variant="body1" style={{ padding: "16px" }}>
          {text?.length > 70 ? `${text.slice(0, 70)}...` : text}
        </Typography>
      )} */}

      {/* <Collapse style={{ padding: "16px" }} in={expanded} timeout="auto" unmountOnExit>

      </Collapse> */}
      <Box
        sx={{
          paddingY: "16px",
          marginX: "16px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {" "}
          <img
            src="/totalLikes.png"
            alt=""
            height={20}
            width={20}
            color="#65676b"
          />
          <Typography
            sx={{
              fontSize: "16px",
              color: "#65676b",
            }}
          >
            {/* John Deo, Holly Perk and 46 other */}
            {likes && (
              <Typography variant="body2">
                {likes?.length > 0 ? `${likes?.length} Like` : "0 Likes"}
              </Typography>
            )}
          </Typography>
        </Box>
        <Typography
          sx={{
            fontSize: "16px",
            color: "#65676b",
          }}
        >
          {comments && (
            <Typography variant="body2">
              {comments?.length > 0
                ? `${comments?.length} Comments`
                : "0 Comments"}{" "}
            </Typography>
          )}
        </Typography>
      </Box>
      <CardActions
        disableSpacing
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          borderBottom: " 1px solid #bcbcbc",
          borderTop: "1px solid #bcbcbc",
          marginBottom: "16px",
          marginX: "16px",
        }}
      >
        {showActions && (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              alignItems: "center",
              gap: "8px",
              width: "100%",
            }}
          >
            {value === false ? (
              <Button
                aria-label="add and remove to favorites"
                onClick={() => handleAddLike(_id)}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                  width: "100%",
                }}
              >
                <img
                  src="/thumbs-up.png"
                  alt=""
                  height={20}
                  width={20}
                  color="#65676b"
                />

                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#65676b",
                    fontWeight: 600,
                  }}
                >
                  Like
                </Typography>
              </Button>
            ) : (
              <Button
                aria-label="add and remove to favorites"
                onClick={() => handleRemoveLike(_id)}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                  width: "100%",
                }}
              >
                <img
                  src="/filled-thumbs-up.png"
                  alt=""
                  height={20}
                  width={20}
                  color="#65676b"
                />
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#65676b",
                    fontWeight: 600,
                  }}
                >
                  Like
                </Typography>
              </Button>
            )}
          </Box>
        )}

        <Box
          display="flex"
          alignItems="center"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
            width: "100%",
          }}
        >
          <Button
            aria-label="add comment"
            size="small"
            onClick={handleOpen}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              width: "100%",
            }}
          >
            <img
              src="/chat.png"
              alt=""
              height={20}
              width={20}
              color="#65676b"
            />
            <Typography
              sx={{
                fontSize: "16px",
                color: "#65676b",
                fontWeight: 600,
              }}
            >
              Comments
            </Typography>
            {/* {comments && (
                <Typography variant="body2">
                  {comments?.length > 0 ? comments?.length : null}{" "}
                </Typography>
              )} */}
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
            width: "100%",
          }}
        >
          <Button
            aria-label="show more"
            onClick={handleExpandClick}
            aria-expanded={expanded}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              width: "100%",
            }}
          >
            <img
              src="/share.png"
              alt=""
              height={20}
              width={20}
              color="#65676b"
            />
            <Typography
              sx={{
                fontSize: "16px",
                color: "#65676b",
                fontWeight: 600,
              }}
            >
              Share
            </Typography>
          </Button>
        </Box>
        {/* <IconButton
          aria-label="show more"
          onClick={handleExpandClick}
          aria-expanded={expanded}
        >
          <ExpandMoreIcon />
        </IconButton> */}
      </CardActions>
      <Box sx={{ paddingBottom: "16px" }}>
        {isOpen === true && (
          <CommentFormMui post={post} getPosts={getPosts} posts={posts} />
        )}
      </Box>
    </Card>
  );
}
CommentFormMui.propTypes = {
  getPosts: PropTypes.func.isRequired,
  getUserPost: PropTypes.func.isRequired,
};

export default connect(null, { getPosts, getUserPost })(PostItemMui);
