import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { getProfiles } from "../../actions/profile";
import ProfileCard from "./ProfileCard";

//MUI
/*import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';*/
//import IconButton from '@mui/material/IconButton';
import Typography from "@mui/material/Typography";
/*
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';*/
import { Box, Grid } from "@mui/material";
/*
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
*/
const Profiles = ({ getProfiles, profile: { profiles, loading } }) => {
  /*
    const [expanded, setExpanded] = React.useState(false);
  
    const handleExpandClick = () => {
      setExpanded(!expanded);
    };
  */
  useEffect(() => {
    getProfiles();
  }, [getProfiles, profiles]);

  return (
    <Grid
      container
      spacing={3}
      sx={{
        justifyContent: "center",
        padding: "35px",
      }}
    >
      <Box
        component="form"
        sx={{
          mx: "auto",
          "& .MuiTextField-root": { mb: 2 },
          "& .MuiPaper-root": { mb: 2 },
          "& .MuiTypography-root": { mb: 1, mt: 1 },
          "& .MuiButton-root": { mb: 1, mt: 1, mr: 1 },
        }}
        noValidate
        autoComplete="off"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        {loading ? (
          <Spinner />
        ) : (
          <>
            <Grid item xs={12} sx={{}}>
              <Typography variant="h4" align="center">
                Players
              </Typography>{" "}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingBottom: "10px",
                  justifyContent: "center"
                }}
              >
                <Typography
                  sx={{
                    fontSize: "22px",
                    fontWeight: "600",
                  }}
                >
                  Browse and connect with Players
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                width: "100%",
                gap: "16px",
                justifyContent: "center",
              }}
            >
              {profiles.length > 0 ? (
                profiles.map((profile) => (
                  <>
                    {/*<ProfileItem key={profile._id} profile={profile} />*/}
                    <ProfileCard key={profile._id} profile={profile} />
                  </>
                ))
              ) : (
                <Typography variant="h6" align="center">
                  No profiles found...
                </Typography>
              )}
            </Grid>
          </>
        )}
      </Box>
    </Grid>
  );
};

Profiles.propTypes = {
  getProfiles: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { getProfiles })(Profiles);
