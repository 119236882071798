import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCurrentProfile } from "../actions/profile";
import { setAlert } from "../actions/alert";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SearchIcon from "@mui/icons-material/Search";
import { getPosts } from "../actions/post";
import StoriesSection from "../components/homepage/StoriesSection";
// MUI
import {
  Box,
  Grid,
  Typography,
  Avatar,
  Paper,
  useMediaQuery,
} from "@mui/material";
import ProfileCard from "../components/homepage/ProfileCard";
import { getMyConnections } from "../actions/connections";
import WhatsOnYourMind from "../components/homepage/WhatsOnYourMind";
import NewFeeds from "../components/homepage/NewFeeds";
import AddFriends from "./users/AddFriends";

// const groupConversations = [
//   { id: "1", groupName: "Sports Club", members: [], avatar: "https://avatar.iran.liara.run/public/boy?username=Ash" },
//   { id: "1", groupName: "Club", members: [], avatar: "https://th.bing.com/th/id/OIP.aJ1uZzv6RwE-ywKw_o8wtwHaEo?pid=Api&rs=1" },
//   { id: "1", groupName: "Sports Part 1", members: [], avatar: "https://avatar.iran.liara.run/public/boy?username=Ash" },
//   { id: "1", groupName: "Sports Club", members: [], avatar: "https://th.bing.com/th/id/OIP.aJ1uZzv6RwE-ywKw_o8wtwHaEo?pid=Api&rs=1" },
// ];
const Contact = ({ name, avatar, href }) => {
  return (
    <a
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "8px",
        padding: "10px",
        backgroundColor: "#f1f1f1",
        borderRadius: "4px",
      }}
      href={href}
    >
      <Avatar
        alt={name}
        src={
          avatar && avatar !== "empty"
            ? `https://${
                process.env.NODE_ENV === "development"
                  ? "localhost:8080"
                  : "picme.click:8080"
              }/api/profile/photo/${avatar}`
            : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
        }
        sx={{ width: 30, height: 30, marginRight: 1 }}
      />
      <Typography
        variant="body2"
        sx={{
          fontSize: "16px",
          color: "#000000A1",
          fontWeight: 600,
          textTransform: "capitalize",
        }}
      >
        {name}
      </Typography>
    </a>
  );
};

// const GroupConversation = ({ groupName, members, avatar }) => {
//   return (
//     <div style={{ marginBottom: "8px", marginTop: "8px", display: "flex", padding: "8px", }}>
//       <Avatar
//         alt={groupName}
//         src={avatar}
//         sx={{ width: 24, height: 24, marginRight: 1 }}
//       />
//       <Typography variant="body2">{groupName}</Typography>
//       <div style={{ display: "flex", flexDirection: "column" }}>
//         {members.map((member) => (
//           <Contact key={member.id} name={member.name} avatar={member.avatar} />
//         ))}
//       </div>
//     </div>
//   );
// };
const HomePage = ({
  profile: { profile, loading },
  getPosts,
  post: { posts, payload },
  getCurrentProfile,
  setAlert,
  getMyConnections,
  connections: { myConnections},
}) => {
  // const matchesMd = useMediaQuery("(min-width:600px)");
  const matchesLg = useMediaQuery("(min-width:960px)");
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    if (!profile) getCurrentProfile();
  }, [loading, getCurrentProfile, profile]);
  useEffect(() => {
    getMyConnections(userId);
    getPosts();
  }, [getMyConnections, getPosts, userId]);

  const HoemPageProp = "HoemPageProp";
  return (
    <Box
      p={2}
      sx={{
        mx: "auto",
        mt: 10,
      }}
    >
      <Grid container spacing={2}>
        {/* First Column */}
        {matchesLg && <ProfileCard profile={profile} />}

        {/* Second Column */}
        <Grid item xs={12} md={matchesLg ? 6 : 12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <StoriesSection profile={profile} />

            <WhatsOnYourMind profile={profile} />
            <Box
              sx={{
                backgroundColor: "white",
                borderRadius: "15px",
                boxShadow: "0px 5px 7px -7px rgba(0, 0, 0, 0.75)",
                padding: "15px !important",
                overflow: "hidden",
                maxHeight: "333px",
              }}
            >
              <AddFriends />
            </Box>
          </Box>

          <NewFeeds posts={posts} getPosts={getPosts}/>
        </Grid>

        {/* Third Column */}
        {matchesLg && (
          <Grid item xs={12} md={2}>
            <Paper
              elevation={3}
              style={{ padding: "16px", borderRadius: "16px" }}
            >
              {/* Contacts */}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "8px",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6" style={{ marginBottom: "8px" }}>
                  Contacts
                </Typography>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                    justifyContent: "space-between",
                  }}
                >
                  <SearchIcon />
                  <MoreHorizIcon />
                </div>
              </div>
              {myConnections.list && myConnections.list.length > 0 ? (
                myConnections.list.map((contact) => {
                  const name = `${contact.friend.profileId.firstname || ""} ${
                    contact.friend.profileId.lastname || ""
                  }`;
                  return (
                    <Contact
                      key={contact._id}
                      name={name}
                      avatar={contact.friend.profileId.avatar}
                      href={`/profile/${contact.friend.user}`}
                    />
                  );
                })
              ) : (
                <Typography variant="body2">No contacts available</Typography>
              )}

              {/* <Divider style={{ margin: "16px 0" }} /> */}

              {/* Group Conversations */}
              {/* <Typography variant="h6">Group Conversations</Typography>
              {groupConversations && groupConversations.length > 0 ? (
                groupConversations.map((group) => (
                  <GroupConversation
                    key={group.id}
                    groupName={group.groupName}
                    members={group.members}
                    avatar={group.avatar}
                  />
                ))
              ) : (
                <Typography variant="body2">
                  No group conversations available.
                </Typography>
              )} */}
            </Paper>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

HomePage.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  getPosts: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  connections: state.connections,
  post: state.post,
});

export default connect(mapStateToProps, {
  getCurrentProfile,
  getMyConnections,
  setAlert,
  getPosts,
})(HomePage);
