import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getUserImages, getUserVideo } from "../../actions/post";
import PropTypes from "prop-types";
const PhotosAndVideos = ({
  getUserImages,
  post: { postImages, postVideo },
  showVideo,
  getUserVideo,
  userId,
}) => {
  useEffect(() => {
    const id = userId ? userId : localStorage.getItem("userId");
    getUserImages(id);
    getUserVideo(id);
  }, [getUserImages, getUserVideo]);
  const images = postImages.allImages;
  const video = postVideo.allVideos;
  const getImageSource = (imageName, imageIsVideo) => {
    const baseUrl =
      process.env.NODE_ENV === "development"
        ? "localhost:8080"
        : "picme.click:8080";

    if (imageIsVideo) {
      return `https://${baseUrl}/api/posts/video/${imageName}`;
    } else {
      return `https://${baseUrl}/api/posts/image/${imageName}`;
    }
  };
  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          background: "#FFFFFF",
          borderRadius: "0.8rem",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          padding: "10px",
          justifyContent: "space-between",
          alignItems: "flex-start",
          maxWidth: "100% !important",
        }}
      >
        <Box sx={{ paddingBottom: "10px" }}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: "#000000",
            }}
          >
            Photos
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {(images && images.length) !== 0 ? (
            images && images.map((user) => {
              const imageIsVideo = false;
              const image = user.images.image;
              return (
                <Card
                  style={{
                    maxWidth: 150,
                    marginLeft: "10px",
                    marginTop: "10px",
                    borderRadius: "5px",
                    marginRight: "0px!important",
                    marginBottom: "0px!important",
                  }}
                >
                  <CardContent
                    sx={{ padding: " 0 !important ", position: "relative" }}
                  >
                    <CardMedia
                      component={imageIsVideo ? "video" : "img"}
                      alt={user.images.text}
                      src={getImageSource(image, imageIsVideo)}
                      controls={imageIsVideo}
                      style={{
                        minHeight: "148px",
                        minWidth: "148px",
                        objectFit: "cover",
                        width: "100%",
                      }}
                    />
                  </CardContent>
                </Card>
              );
            }
            )
          ) : (showVideo && video && video.length) !== 0 ? (
            showVideo && video && video.map((user) => {
              const imageIsVideo = true;
              const image = user.video.image;
              return (
                <Card
                  style={{
                    maxWidth: 150,
                    marginLeft: "10px",
                    marginTop: "10px",
                    borderRadius: "5px",
                    marginRight: "0px!important",
                    marginBottom: "0px!important",
                  }}
                >
                  <CardContent
                    sx={{ padding: " 0 !important ", position: "relative" }}
                  >
                    <CardMedia
                      component={imageIsVideo ? "video" : "img"}
                      alt={user.video.text}
                      src={getImageSource(image, imageIsVideo)}
                      controls={imageIsVideo}
                      style={{
                        minHeight: "148px",
                        minWidth: "148px",
                        objectFit: "cover",
                        width: "100%",
                      }}
                    />
                  </CardContent>
                </Card>
              );
            })
          ) : (
            <Box
              sx={{
                padding: "16px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "black",
                }}
              >
                No Photos or Videos Yet
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>
    </>
  );
};

PhotosAndVideos.propTypes = {
  getUserImages: PropTypes.func.isRequired,
  postImages: PropTypes.object.isRequired,
  getUserVideo: PropTypes.func.isRequired,
  postVideo: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  post: state.post,
});

export default connect(mapStateToProps, { getUserImages, getUserVideo })(
  PhotosAndVideos
);
