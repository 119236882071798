import * as React from "react";
import store from "../../store";
import { hideAgreement } from "../../actions/agreements";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

theme.typography.h4 = {
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.6rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "0.6rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "0.8rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "0.8rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "0.8rem",
  },
};

export default function CookiePolicy() {
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "0",
        display: "flex",
        flexWrap: "wrap",
        "& > :not(style)": {
          m: 1,
        },
        "& .MuiTypography-root": { m: 1 },
        zIndex: 1102,
      }}
      onClick={() => store.dispatch(hideAgreement("CookiePolicy"))}
    >
      <Paper elevation={3}>
        {" "}
        <Typography variant="h4" gutterBottom theme={theme}>
          Cookie Policy
        </Typography>
        <Typography variant="h4" gutterBottom theme={theme}>
          At Pic Me, we believe in being clear and open about how we collect and
          use data related to you. This Cookie Policy applies to any Pic Me
          product or service that links to this policy or incorporates it by
          reference. We use cookies and similar technologies such as pixels,
          local storage and mobile ad IDs (collectively referred to in this
          policy as “cookies”) to collect and use data as part of our Services,
          as defined in our Privacy Policy (“Services”) and which includes our
          sites, communications, mobile applications and off-site Services, such
          as our ad services and the “Apply with Pic Me” and “Share with Pic Me”
          plugins or tags. In the spirit of transparency, this policy provides
          detailed information about how and when we use these technologies.
        </Typography>
      </Paper>
    </Box>
  );
}
