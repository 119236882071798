import {
  GET_PROFILE,
  GET_PROFILE_BY_ID,
  PROFILE_ERROR,
  CLEAR_PROFILE,
  UPDATE_PROFILE,
  GET_PROFILES,
  GET_REPOS,
  NO_REPOS,
  UPDATE_PROFILE_COVER,
  GET_AWARDS_DETAILS,
  GET_AWARDS_IMG,
  POST_AWARDS_DETAILS,
  PUT_AWARDS_DETAILS,
  DELETE_AWARDS_DETAILS,
  POST_EXPERIENCE_DETAILS,
  DELETE_EXPERIENCE_DETAILS,
  GET_CERTIFICATE_DETAILS,
  DELETE_CERTIFICATE_DETAILS,
  POST_CERTIFICATE_DETAILS,
  PUT_CERTIFICATE_DETAILS,
  GET_USER_REELS_DETAILS,
  POST_USER_REELS_DETAILS,
  POST_USER_STORIES_DETAILS,
  GET_USER_STORY_DETAILS,
  DELETE_USER_REELS_DETAILS,
  POST_BIO_DETAILS,
  POST_ACTIVITY_DETAILS,
  GET_USER_ACTIVITY_DETAILS,
  GET_USER_FRIENDS_REELS_DETAILS,
  GET_USER_FRIENDS_STORY_DETAILS,
} from "../actions/types";

const initialState = {
  profile: null,
  profileByID: null,
  profiles: [],
  awardDetails: [],
  certificateDetails: [],
  awardDetailImg: [],
  awardDetailUpdate: [],
  certificateDetailUpdate: {},
  awardDetailDelete: {},
  certificateDetailDelete: {},
  userReelDetailDelete: {},
  awardPost: [],
  certificatePost: {},
  userReelPost: {},
  userStoriesPost: {},
  users: [],
  repos: [],
  loading: true,
  error: {},
  experiencePost: [],
  experienceDetailDelete: [],
  userReelDetails: [],
  userFriendsReelDetails: [],
  userStoryDetails: [],
  userFriendStoryDetails: [],
  bioPost: [],
  activityPost: [],
  userActivityDetails: [],
};

function profileReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PROFILE:
      return {
        ...state,
        profile: payload,
        loading: false,
      };
    case GET_AWARDS_DETAILS:
      return {
        ...state,
        awardDetails: payload,
        loading: false,
      };
    case GET_CERTIFICATE_DETAILS:
      return {
        ...state,
        certificateDetails: payload,
        loading: false,
      };
    case GET_USER_STORY_DETAILS:
      return {
        ...state,
        userStoryDetails: payload,
        loading: false,
      };
    case GET_USER_FRIENDS_STORY_DETAILS:
      return {
        ...state,
        userFriendStoryDetails: payload,
        loading: false,
      };
    case GET_USER_ACTIVITY_DETAILS:
      return {
        ...state,
        userActivityDetails: payload,
        loading: false,
      };
    case GET_USER_REELS_DETAILS:
      return {
        ...state,
        userReelDetails: payload,
        loading: false,
      };
    case GET_USER_FRIENDS_REELS_DETAILS:
      return {
        ...state,
        userFriendsReelDetails: payload,
        loading: false,
      };
    case GET_AWARDS_IMG:
      return {
        ...state,
        awardDetailImg: payload,
        loading: false,
      };
    case GET_PROFILE_BY_ID:
      return {
        ...state,
        profileByID: payload,
        loading: false,
      };

    case UPDATE_PROFILE:
      return {
        ...state,
        profile: payload,
        loading: false,
      };
    case PUT_AWARDS_DETAILS:
      return {
        ...state,
        awardDetailUpdate: payload,
        loading: false,
      };
    case PUT_CERTIFICATE_DETAILS:
      return {
        ...state,
        certificateDetailUpdate: payload,
        loading: false,
      };
    case GET_PROFILES:
      return {
        ...state,
        profiles: payload,
        loading: false,
      };
    case PROFILE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        profile: null,
      };
    case CLEAR_PROFILE:
      return {
        ...state,
        profile: null,
        repos: [],
      };
    case DELETE_AWARDS_DETAILS:
      return {
        ...state,
        awardDetailDelete: payload,
      };
    case DELETE_CERTIFICATE_DETAILS:
      return {
        ...state,
        certificateDetailDelete: payload,
      };
    case DELETE_USER_REELS_DETAILS:
      return {
        ...state,
        userReelDetailDelete: payload,
      };
    case DELETE_EXPERIENCE_DETAILS:
      return {
        ...state,
        experienceDetailDelete: payload,
      };
    case UPDATE_PROFILE_COVER:
      return {
        ...state,
        repos: payload,
        loading: false,
      };
    case POST_AWARDS_DETAILS:
      return {
        ...state,
        awardPost: payload,
        loading: false,
      };
    case POST_USER_STORIES_DETAILS:
      return {
        ...state,
        userStoriesPost: payload,
        loading: false,
      };
    case POST_USER_REELS_DETAILS:
      return {
        ...state,
        userReelPost: payload,
        loading: false,
      };
    case POST_CERTIFICATE_DETAILS:
      return {
        ...state,
        certificatePost: payload,
        loading: false,
      };
    case POST_EXPERIENCE_DETAILS:
      return {
        ...state,
        experiencePost: payload,
        loading: false,
      };
    case POST_BIO_DETAILS:
      return {
        ...state,
        bioPost: payload,
        loading: false,
      };
    case POST_ACTIVITY_DETAILS:
      return {
        ...state,
        activityPost: payload,
        loading: false,
      };
    case GET_REPOS:
      return {
        ...state,
        repos: payload,
        loading: false,
      };
    case NO_REPOS:
      return {
        ...state,
        repos: [],
      };
    default:
      return state;
  }
}

export default profileReducer;
