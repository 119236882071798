/* eslint-disable array-callback-return */

import { Box, Grid, Typography, } from "@mui/material";
import React, { useEffect, } from "react";
import { connect } from "react-redux";
import FriendAndProfileCard from "../profile/FriendAndProfileCard";
import {
  getMyConnections,
} from "../../actions/connections";

const FriendList = ({ getMyConnections, connections: { myConnections, }, dashboard }) => {
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    getMyConnections(userId);
  }, [getMyConnections, userId
  ]);
  return (
    <Grid
      item
      xs={12}
      sx={{
        background: "#FFFFFF",
        boxShadow: "0px 0px 0px 2px rgb(0 0 0 / 10%)",
        borderRadius: "0.8rem",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        padding: "10px",
        justifyContent: "space-between",
        alignItems: "flex-start",
        maxWidth: "100% !important",
      }}
    >
      <Box sx={{ paddingBottom: "10px" }}>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 600,
            color: 'black',
          }}
        >
          Fiends
        </Typography>
      </Box>
      <Box sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      }}><FriendAndProfileCard
          myConnections={myConnections} dashboard={dashboard} /></Box>

    </Grid>
  );
};

const mapStateToProps = (state) => ({
  connections: state.connections,
});

export default connect(mapStateToProps, {
  getMyConnections,
})(FriendList);
