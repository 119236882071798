import { SHOW_APP_BAR, HIDE_APP_BAR } from "../actions/types";

const initialState = true;

function appBarReducer(state = initialState, action) {
  const { type } = action;

  switch (type) {
    case SHOW_APP_BAR:
      state = true;
      return state;
    case HIDE_APP_BAR:
      state = false;
      return state;
    default:
      return state;
  }
}

export default appBarReducer;
