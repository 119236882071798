import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PeopleCard from "./PeopleYouMightKnow";
import { Link } from "react-router-dom";
import { getAllUsers } from "../../actions/connections";
import { slice, concat } from "lodash";
import { connect } from "react-redux";
const AddFriends = ({ UsersProp, getAllUsers, connections: { users } }) => {
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    getAllUsers(userId);
  }, [getAllUsers, userId]);

  const LIMIT = 5;
  const [list, setList] = useState([]);
  const [index, setIndex] = useState(LIMIT);
  const [showMore, setShowMore] = useState(true);
  const loadMore = () => {
    const newIndex = index + LIMIT;
    const newShowMore = newIndex < users?.length - 0;
    const newList = concat(list, slice(users, index, newIndex));
    setIndex(newIndex);
    setList(newList);
    setShowMore(newShowMore);
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        padding: "0px !important",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        alignItems: "flex-start",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ fontSize: "14px", fontWeight: "600", color: "#000000" }}
        >
          People you may know
        </Typography>
        {!UsersProp && (
          <Button
            component={Link}
            to="/users"
            sx={{ fontSize: "14px", color: "#2065D1" }}
          >
            See all
          </Button>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <PeopleCard users={users} list={list} />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "10px",
        }}
      >
        {showMore && (
          <Button onClick={loadMore} sx={{ fontSize: "16px" }}>
            {" "}
            Load More{" "}
          </Button>
        )}
      </Box>
    </Grid>
  );
};
const mapStateToProps = (state) => ({
  connections: state.connections,
});
export default connect(mapStateToProps, { getAllUsers })(AddFriends);
