// MUI
import {Button } from "@mui/material";
import "./Story.css";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
function AddReel({setShowModal, image}) {
  return (
    <div className="Add-story">
      <div
        style={{ backgroundImage: `url(${"image"})` }}
        className="story__image"
      ></div>
      <div className="story__icon_container">
         <Button
        variant="contained"
        color="inherit"
        style={{
          background: "#2e81f4",
          fontSize: "14px",
          fontWeight: 600,
          width:"100%"
        }}
        onClick={() => setShowModal(true)}
      >
      <AddIcon/>
      </Button>
      </div>
    </div>
  );
}
export default AddReel;
