import { useLocation, useNavigate } from "react-router-dom";
import { List, ListItem } from "@mui/material";
import PostAddIcon from "@mui/icons-material/PostAdd";
import PhotoIcon from "@mui/icons-material/Photo";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import InfoIcon from "@mui/icons-material/Info";
import {
  SettingsNavListItemIcon,
  SettingsNavbarListItemButton,
  SettingsNavbarListItemText,
} from "../../components/list/settings-navbar-list";

export const AdminNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <List>
      {menus.map((item, index) => (
        <ListItem key={`settings-nav-item-${index}}`} disablePadding>
          <SettingsNavbarListItemButton
            selected={location.pathname === item.route}
            onClick={() => navigate(item.route)}
          >
            <SettingsNavListItemIcon
              sx={{
                color: "inherit",
              }}
            >
              {item.icon}
            </SettingsNavListItemIcon>
            <SettingsNavbarListItemText
              primary={item.title}
              secondary={item.subTitle}
            />
          </SettingsNavbarListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

const menus = [
  {
    title: "Admin Page",
    route: "/admin",
    icon: <PostAddIcon />,
    subTitle: "530",
  },
  {
    title: "User Activity",
    route: "/admin-user-activity",
    icon: <PhotoIcon />,
    subTitle: "573",
  },
  {
    title: "Users List",
    route: "/admin-user-list",
    icon: <VideoLibraryIcon />,
  },
];
