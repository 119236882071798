import * as React from "react";
import store from "../../store";
import { hideAgreement } from "../../actions/agreements";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

export default function PrivacyPolicy() {
  return (
    <Box
      sx={{
        position: "fixed",
        display: "flex",
        flexWrap: "wrap",
        "& > :not(style)": {
          m: 5,
        },
        zIndex: 15,
      }}
      onClick={() => store.dispatch(hideAgreement("PrivacyPolicy"))}
    >
      <Paper elevation={3}>
        {" "}
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="h5" gutterBottom>
          Pic Me’s mission is to connect the world’s professionals to allow them
          to be more productive and successful. Central to this mission is our
          commitment to be transparent about the data we collect about you, how
          it is used and with whom it is shared. This Privacy Policy applies
          when you use our Services (described below). We offer our users
          choices about the data we collect, use and share as described in this
          Privacy Policy, Cookie Policy, Settings and our Help Center.
        </Typography>
      </Paper>
    </Box>
  );
}
