import React, { useState, useEffect } from "react";

// MUI
import { Paper, Tabs, Tab, Grid } from "@mui/material";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// Icons
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import StoryReel from "../reels/StoryReels";
import ReelHome from "../reels/ReelHome";
import { getUserStory, getUserFriendsStory } from "../../actions/profile";
import { getUserReels } from "../../actions/profile";
import { getUserFriendsReels } from "../../actions/profile";
import { postUserStoriesDetails } from "../../actions/profile";
import { postUserReelDetails } from "../../actions/profile";

const StoriesSection = ({
  getUserStory,
  getUserFriendsStory,
  getUserReels,
  getUserFriendsReels,
  postUserStoriesDetails,
  postUserReelDetails,
  profile: {
    userStoryDetails,
    userFriendStoryDetails,
    userReelDetails,
    userFriendsReelDetails,
    userStoriesPost,
    userReelPost,
  },
}) => {
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    getUserStory(userId);
    getUserFriendsStory(userId);
    getUserReels(userId);
    getUserFriendsReels(userId);
  }, [
    getUserReels,
    getUserStory,
    getUserFriendsStory,
    getUserFriendsReels,
    userId,
  ]);

  const [tabValues, setTabValues] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValues(newValue);
  };

  return (
    <Paper elevation={3} style={{ padding: "16px", borderRadius: "16px" }}>
      <Tabs
        value={tabValues}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        onChange={handleTabChange}
      >
        <Tab
          label={
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                gap: "8px",
              }}
            >
              Stories <AutoStoriesIcon />
            </span>
          }
        />
        <Tab
          label={
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                gap: "8px",
              }}
            >
              Reels <VideoLibraryIcon />
            </span>
          }
        />
      </Tabs>
      <Grid container spacing={0} alignItems="center">
        {tabValues === 0 && (
          <Grid
            container
            item
            justifyContent="space-between"
            sx={{
              overflow: "auto",
            }}
          >
            <StoryReel
              getUserStory={getUserStory}
              getUserFriendsStory={getUserFriendsStory}
              userStoryDetails={userStoryDetails}
              userFriendStoryDetails={userFriendStoryDetails}
              userId={userId}
              postUserStoriesDetails={postUserStoriesDetails}
              userStoriesPost={userStoriesPost}
            />
          </Grid>
        )}
        {tabValues === 1 && (
          <Grid
            container
            item
            justifyContent="space-between"
            sx={{
              overflow: "auto",
            }}
          >
            <ReelHome
              getUserReels={getUserReels}
              getUserFriendsReels={getUserFriendsReels}
              userReelDetails={userReelDetails}
              postUserReelDetails={postUserReelDetails}
              userFriendsReelDetails={userFriendsReelDetails}
              userId={userId}
              userReelPost={userReelPost}
            />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

StoriesSection.propTypes = {
  getUserStory: PropTypes.func.isRequired,
  getUserFriendsStory: PropTypes.func.isRequired,
  getUserReels: PropTypes.func.isRequired,
  getUserFriendsReels: PropTypes.func.isRequired,
  postUserStoriesDetails: PropTypes.func.isRequired,
  postUserReelDetails: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  profile: state.profile,
});
export default connect(mapStateToProps, {
  getUserStory,
  getUserFriendsStory,
  getUserReels,
  getUserFriendsReels,
  postUserStoriesDetails,
  postUserReelDetails,
})(StoriesSection);
