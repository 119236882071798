import {
  Box,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getUserVideo } from "../../actions/post";
import PropTypes from "prop-types";
// const dummyData = [
//   {
//     id: 1,
//     avatar:
//       "https://p7.hiclipart.com/preview/9/907/727/video-sound-play-button-transparent-background-thumbnail.jpg",
//   },
//   {
//     id: 2,
//     avatar:
//       "https://w7.pngwing.com/pngs/567/741/png-transparent-play-music-movies-portable-display-start-youtube-video.png",
//   },
//   {
//     id: 3,
//     avatar: "https://clipart-library.com/images/Biar9xR6T.png",
//   },
//   {
//     id: 1,
//     avatar:
//       "https://p7.hiclipart.com/preview/9/907/727/video-sound-play-button-transparent-background-thumbnail.jpg",
//   },
//   {
//     id: 2,
//     avatar:
//       "https://w7.pngwing.com/pngs/567/741/png-transparent-play-music-movies-portable-display-start-youtube-video.png",
//   },
//   {
//     id: 3,
//     avatar: "https://clipart-library.com/images/Biar9xR6T.png",
//   },

//   {
//     id: 1,
//     avatar:
//       "https://p7.hiclipart.com/preview/9/907/727/video-sound-play-button-transparent-background-thumbnail.jpg",
//   },
//   {
//     id: 2,
//     avatar:
//       "https://w7.pngwing.com/pngs/567/741/png-transparent-play-music-movies-portable-display-start-youtube-video.png",
//   },
//   {
//     id: 3,
//     avatar: "https://clipart-library.com/images/Biar9xR6T.png",
//   },
//   {
//     id: 1,
//     avatar:
//       "https://p7.hiclipart.com/preview/9/907/727/video-sound-play-button-transparent-background-thumbnail.jpg",
//   },
//   {
//     id: 2,
//     avatar:
//       "https://w7.pngwing.com/pngs/567/741/png-transparent-play-music-movies-portable-display-start-youtube-video.png",
//   },
//   {
//     id: 3,
//     avatar: "https://clipart-library.com/images/Biar9xR6T.png",
//   },
//   {
//     id: 1,
//     avatar:
//       "https://p7.hiclipart.com/preview/9/907/727/video-sound-play-button-transparent-background-thumbnail.jpg",
//   },
//   {
//     id: 2,
//     avatar:
//       "https://w7.pngwing.com/pngs/567/741/png-transparent-play-music-movies-portable-display-start-youtube-video.png",
//   },
//   {
//     id: 3,
//     avatar: "https://clipart-library.com/images/Biar9xR6T.png",
//   },
// ];
const MediaVideosCards = ({ getUserVideo, post: { postVideo } }) => {
  useEffect(() => {
    const id = localStorage.getItem("userId");
    getUserVideo(id);
  }, [getUserVideo]);
  const video = postVideo.allVideos;
  const getImageSource = (imageName, imageIsVideo) => {
    const baseUrl =
      process.env.NODE_ENV === "development"
        ? "localhost:8080"
        : "picme.click:8080";

    if (imageIsVideo) {
      return `https://${baseUrl}/api/posts/video/${imageName}`;
    } else {
      return `https://${baseUrl}/api/posts/image/${imageName}`;
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      {/* {dummyData.map((user) => (
        <Card
          style={{
            maxWidth: 150,
            marginLeft: "10px",
            marginTop: "10px",
            borderRadius: "5px",
            marginRight: "0px!important",
            marginBottom: "0px!important",
            border: "2px solid #b1b1b1",
          }}
        >
          <CardContent sx={{ padding: " 0 !important ", position: "relative" }}>
            <img alt={user.avatar} src={user.avatar} />
            <IconButton
              sx={{
                position: "absolute",
                top: "5px",
                right: "5px",
                height: "25px",
                width: "25px",
                backgroundColor: "rgb(0 0 0 / 28%)",
              }}
            >
              <EditIcon
                sx={{
                  height: "15px",
                  width: "15px",
                  color: "white",
                }}
              />
            </IconButton>
          </CardContent>
        </Card>
      ))} */}
      {video &&
        video.map((user) => {
          const imageIsVideo = true;
          const image = user.video.image;
          return (
            <Card
              style={{
                maxWidth: 150,
                marginLeft: "10px",
                marginTop: "10px",
                borderRadius: "5px",
                marginRight: "0px!important",
                marginBottom: "0px!important",
              }}
            >
              <CardContent
                sx={{ padding: " 0 !important ", position: "relative" }}
              >
                <CardMedia
                  component={imageIsVideo ? "video" : "img"}
                  alt={user.video.text}
                  src={getImageSource(image, imageIsVideo)}
                  controls={imageIsVideo}
                  style={{
                    minHeight: "148px",
                    minWidth: "148px",
                    objectFit: "cover",
                    width: "100%",
                  }}
                />
              </CardContent>
            </Card>
          );
        })}
    </Box>
  );
};

MediaVideosCards.propTypes = {
  getUserVideo: PropTypes.func.isRequired,
  postVideo: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  post: state.post,
});
export default connect(mapStateToProps, { getUserVideo })(MediaVideosCards);
