import { Grid, Modal } from "@mui/material";
import ListCard from "./ListCard";

import { PostSectionSideBar } from "./PostSectionSideBar";
import React, { useState, useEffect } from "react";
import { ListSidebar } from "./ListSidebar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import UserListEdit from "./UserListEdit";

export const ListPage = () => {
  const theme = useTheme();
  const [isExperiernceModalOpen, setIsExperienceModalOpen] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [editedData, setEditedData] = useState({});
  const openExperienceModal = () => {
    setIsExperienceModalOpen(true);
  };
  const closeExperienceModal = () => {
    setIsExperienceModalOpen(false);
    setEditedData({});
  };
  return (
    <Grid
      container
      spacing={3}
      sx={{
        gap: "20px",
        padding: "24px",
        justifyContent: "center",
        paddingRight: "0 !important",
      }}
    >
      <Grid
        item
        xs={12}
        // sx={{
        //   // background: "#FFFFFF",
        //   // boxShadow: "0px 0px 0px 2px rgb(0 0 0 / 10%)",
        //   borderRadius: "0.8rem",
        //   overflow: "auto",
        //   display: "flex",
        //   flexDirection: "row",
        //   padding: "10px !important",
        //   paddingRight: "20px !important",
        //   alignItems: "flex-start",
        //   justifyContent: "center",
        //   maxWidth: "100% !important",
        // }}
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          padding: "0px !important",
          alignContent: "flex-start",
        }}
      >
        <Grid item xs={2} sx={{ padding: "0 !important" }}>
          <ListSidebar />
          <PostSectionSideBar />
        </Grid>
        {/* <Box sx={{ paddingBottom: "10px" }}>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              color: 'black',
            }}
          >
            My Listings 
          </Typography>
        </Box> */}

        <Grid item xs={6.35}>
          <ListCard
            openExperienceModal={openExperienceModal}
            setEditedData={setEditedData}
            closeExperienceModal={closeExperienceModal}
          />
        </Grid>
        <Modal
          open={isExperiernceModalOpen}
          onClose={closeExperienceModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              borderRadius: "15px",
              padding: "12px",
              width: isSmallScreen ? "90%" : "600px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                maxHeight: "85vh",
                overflowY: "auto",
              }}
            >
              <UserListEdit
                closeExperienceModal={closeExperienceModal}
                editedData={editedData}
              />
            </div>
          </div>
        </Modal>
      </Grid>
    </Grid>
  );
};
