// MUI
import React, { useState } from "react";
import "./MessageSender.css";


function MessageSender({ handleOpen, profile }) {
  const [input, setInput] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    //some db stuff

    setImageUrl("");
    setInput("");
  };
  return (
    <div className="messageSender">
      <div className="messageSender__top">
        {/* <Avatar
          alt="User Avatar"
          src={
            profile?.avatar && profile.avatar !== "empty"
              ? `https://${process.env.NODE_ENV === "development"
                ? "localhost:8080"
                : "picme.click:8080"
              }/api/profile/photo/${profile?.avatar}`
              : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
          }
          sx={{ width: 40, height: 40, marginRight: 1 }}
        /> */}
        <form action="" method="" style={{ height: "40px" }}>
          <input
            value={input}
            onClick={handleOpen}
            // onChange={(e) => setInput(e.target.value)}
            className="messageSender__input"
            placeholder={`What's on your mind ${profile?.firstname || ""}?`}
          />
          {/* <input
            placeholder="Image URL (Optional)"
            value={imageUrl} className="messageSender__input_image"
            onChange={(e) => {
              setImageUrl(e.target.value);
            }}
          /> */}
          <button type="submit" onClick={handleSubmit}>
            Hidden Submit
          </button>
        </form>
      </div>
      {/* <div className="messageSender__bottom">
        <div className="messageSender__option">
          <VideocamIcon style={{ color: "red" }} />
          <h3>Live Video</h3>
        </div>
        <div className="messageSender__option">
          <PhotoLibraryIcon style={{ color: "green" }} />
          <h3>Photo/Video</h3>
        </div>
        <div className="messageSender__option">
          <InsertEmoticonIcon style={{ color: "orange" }} />
          <h3>Feeling/Activity</h3>
        </div>
      </div> */}
    </div>
  );
}

export default MessageSender;
