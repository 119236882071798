import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import { ListItem } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import HomeIcon from "@mui/icons-material/Home";
import StorefrontIcon from "@mui/icons-material/Storefront";
import GroupsIcon from "@mui/icons-material/Groups";
import { Link } from "react-router-dom";
import CollectionsIcon from "@mui/icons-material/Collections";
import WorkIcon from "@mui/icons-material/Work";
// import { Jobnavbar } from '../../pages/opportunity-navsection/Jobnavbar';

const ItemList = () => {
  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <List
        component={Stack}
        direction="row"
        sx={{
          gap: "20px",
        }}
      >
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/home"
            selected={window.location.pathname === "/home"}
            sx={{
              "&.Mui-selected": {
                background: "#E0E0E0",
              },
              borderRadius: "6px",
            }}
          >
            <HomeIcon sx={{ color: "#637381" }} />
          </ListItemButton>
        </ListItem>

        {/* <ListItem disablePadding>
          <ListItemButton>
              <VideoLibraryIcon  sx={{ color: "#637381" }}/>
          </ListItemButton>
        </ListItem> */}

        <ListItem disablePadding>
          <ListItemButton
            selected={window.location.pathname === "/store"}
            sx={{
              "&.Mui-selected": {
                background: "#E0E0E0",
              },
              borderRadius: "6px",
            }}
          >
            <StorefrontIcon sx={{ color: "#637381" }} />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/users"
            selected={window.location.pathname === "/users"}
            sx={{
              "&.Mui-selected": {
                background: "#E0E0E0",
              },
              borderRadius: "6px",
            }}
          >
            <GroupsIcon sx={{ color: "#637381" }} />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/my-listing"
            selected={window.location.pathname === "/my-listing"}
            sx={{
              "&.Mui-selected": {
                background: "#E0E0E0",
              },
              borderRadius: "6px",
            }}
          >
            <WorkIcon sx={{ color: "#637381" }} />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/media-posts"
            selected={window.location.pathname === "/media-posts"}
            sx={{
              "&.Mui-selected": {
                background: "#E0E0E0",
              },
              borderRadius: "6px",
            }}
          >
            <CollectionsIcon sx={{ color: "#637381" }} />
          </ListItemButton>
        </ListItem>

        {/* <ListItem disablePadding>
          <ListItemButton>
              <CircleNotificationsIcon  sx={{ color: "#637381" }}/>
          </ListItemButton>
        </ListItem> */}
      </List>
    </Box>
  );
};

export default ItemList;
