export let SportList = [
  "Aerobics",
  "American Football",
  "Aquathlon",
  "Archery",
  "Archery Tag",
  "Australian Rules Football",
  "Badminton",
  "Base Jumping",
  "Baseball",
  "Basketball",
  "Biathlon",
  "Billiards",
  "Bobsleigh",
  "Bodyboarding",
  "Bodybuilding",
  "Bowls Indoor",
  "Bowls Lawn",
  "Boxing",
  "Bungee Jumping",
  "Canadian Football",
  "Canicross (cross-country running with dogs)",
  "Canoeing/Kayaking Flatwater",
  "Canoeing/Kayaking Marathon",
  "Canoeing/Kayaking Slalom",
  "Capoeira",
  "Cheerleading",
  "Chess",
  "Cricket All",
  "Cricket Five Day",
  "Cricket T20",
  "Criket One Day ",
  "CrossFit",
  "Curling",
  "Cycling BMX",
  "Cycling Mountain Biking",
  "Cycling Road",
  "Cycling Track",
  "Dance Sports (Ballroom, Latin, Hip Hop)",
  "Darts",
  "Decathlon",
  "Dragon Boat Racing",
  "Duathlon",
  "Esports (Various games and genres)",
  "Fencing",
  "Field Hockey",
  "Gaelic Football",
  "Gaelic Hurling",
  "Golf",
  "Handball Beach",
  "Handball Team",
  "Hang Gliding",
  "Heptathlon",
  "Highlining",
  "Ice Climbing",
  "Ice Hockey",
  "Jai Alai",
  "Judo",
  "Kabaddi",
  "Karate",
  "Kendo",
  "Kitesurfing",
  "Krav Maga",
  "Lacrosse",
  "Luge",
  "Mixed Martial Arts (MMA)",
  "Modern Pentathlon",
  "Motorsports Formula 1",
  "Motorsports Formula E",
  "Motorsports MotoGP",
  "Motorsports NASCAR",
  "Motorsports Rally",
  "Mountain Boarding",
  "Muay Thai",
  "Netball",
  "Paragliding",
  "Parkour",
  "Pentathlon",
  "Pétanque",
  "Pickleball",
  "Powerboat Racing",
  "Powerlifting",
  "Quidditch",
  "Racquetball",
  "Robot Combat (BattleBots, Robot Wars)",
  "Rock Climbing",
  "Roller Skating Inline ",
  "Roller Skating Roller Derby",
  "Rowing",
  "Rugby League",
  "Rugby Sevens",
  "Rugby Union ",
  "Sailing",
  "Sepak Takraw",
  "Sepaktakraw",
  "Shooting",
  "Skateboarding",
  "Skeleton",
  "Skiing Alpine",
  "Skiing Freestyle",
  "Skiing Nordic",
  "Skydiving",
  "Snooker",
  "Snowboarding",
  "Softball",
  "Squash",
  "Start typing your sport ",
  "Sumo Wrestling",
  "Surfing",
  "Table Tennis",
  "Taekwondo",
  "Tennis",
  "Tenpin Bowling ",
  "Triathlon",
  "Ultimate Frisbee",
  "Volleyball Beach",
  "Volleyball Indoor",
  "Water Polo",
  "Weightlifting",
  "Windsurfing",
  "Wrestling Freestyle",
  "Wrestling Greco-Roman",
  "Wushu",
];
