import { Grid, Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { getUserActivity } from "../../../actions/profile";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { useEffect } from "react";

const columns = [
  {
    field: "jj",
    headerName: "",
    width: 26,
    minWidth: 10,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
  },
];
const UserActivityTable = ({
  getUserActivity,
  profile: { userActivityDetails, activityPost },
}) => {
  useEffect(() => {
    getUserActivity();
    if (activityPost?.status === 200) {
      getUserActivity();
    }
  }, [getUserActivity, activityPost]);
  if (!userActivityDetails.activity) {
    return null;
  }
  const rows = userActivityDetails.activity
    .slice()
    .reverse()
    .map((item) => ({
      id: item._id,
      email: item.email,
      description: item.description,
    }));

  return (
    <>
      {userActivityDetails.activity !== 0 ? (
        <Grid
          item
          xs={12}
          sx={{
            background: "#FFFFFF",
            boxShadow: "0px 0px 0px 2px rgb(0 0 0 / 10%)",
            borderRadius: "0.8rem",
            display: "flex",
            flexDirection: "column",
            paddingLeft: "0px !important",
            paddingTop: "24px",
            marginTop: "0",
            height: "85vh",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              minWidth: {
                lg: "auto",
                md: "1100px",
                sm: "1100px",
                xs: "1100px",
              },
            }}
          >
            <Box
              sx={{
                paddingX: "24px !important",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#0C345D",
                  fontWeight: 600,
                }}
              >
                User Activities
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              height: "717px !important",
              minHeight: "400px",
              width: "100%",
            }}
          >
            <DataGrid
              rows={rows || []}
              columns={columns.map((col) => ({
                ...col,
                renderCell: (params) => (
                  <Typography
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    {params.value}
                  </Typography>
                ),

                sortable: false,
              }))}
              disableColumnSelector
              disableDensitySelector
              disableColumnFilter
              disableColumnMenu
              hideFooter
              pageSizeOptions={[]}
              sx={{
                border: 0,
                paddingY: 2,
                "& .MuiDataGrid-main": {
                  minWidth: {
                    lg: "auto",
                    md: "1100px",
                    sm: "1100px",
                    xs: "1100px",
                  },
                },
              }}
            />
          </Box>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};
UserActivityTable.propTypes = {
  getUserActivity: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  profile: state.profile,
});
export default connect(mapStateToProps, { getUserActivity })(UserActivityTable);
