import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PostItemMui from "../../components/posts/PostItemMui";
import { getUserPost } from "../../actions/post";
import { addLike, removeLike, deletePost } from "../../actions/post";
import { getPosts } from "../../actions/post";

const MediaPostsCard = ({ getUserPost, post: { userPosts } }) => {
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    getUserPost(userId);
  }, [getPosts, getUserPost, userId]);
  return (
    <>
      {" "}
      <Box
        sx={{
          background: "#FFFFFF",
          boxShadow: "0px 3px 2px 0px rgb(0 0 0 / 10%)",
          padding: "16px",
          borderRadius: "6px",
          marginBottom: "16px",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 600,
          }}
        >
          Posts
        </Typography>
      </Box>
      {!userPosts.post || userPosts.post.length === 0 ? (
        <Typography variant="body1" color="text.secondary">
          No feeds to display.
        </Typography>
      ) : (
        userPosts.post.map((post) => (
          <PostItemMui
            key={post._id}
            post={post}
            showActions={true}
            deletePost={deletePost}
            addLike={addLike}
            removeLike={removeLike}
            getUserPost={getUserPost}
            userPosts={userPosts}
            userId={userId}
          />
        ))
      )}
    </>
  );
};

MediaPostsCard.propTypes = {
  getUserPost: PropTypes.func.isRequired,
  getPosts: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  post: state.post,
});
export default connect(mapStateToProps, { getUserPost, getPosts })(
  MediaPostsCard
);
