/* eslint-disable array-callback-return */

import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import FriendAndProfileCard from "../profile/FriendAndProfileCard";
import { getMyConnections } from "../../actions/connections";

const ProfileFriends = ({
  getMyConnections,
  connections: { myConnections },
  id,
}) => {
  const userId = id;

  useEffect(() => {
    getMyConnections(userId);
  }, [getMyConnections, userId]);

  return (
    <Grid
      item
      xs={12}
      sx={{
        background: "#FFFFFF",
        boxShadow: "0px 0px 0px 2px rgb(0 0 0 / 10%)",
        borderRadius: "0.8rem",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "flex-start",
        maxWidth: "100% !important",
        padding: "10px",
      }}
    >
      {/* <Box sx={{paddingBottom:"10px"}}>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            fontSize: '16px',
            fontWeight: 600,
            color: 'black',
          }}
        >
          My friends
        </Typography>
      </Box> */}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        <FriendAndProfileCard myConnections={myConnections} />
      </Box>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  connections: state.connections,
});

export default connect(mapStateToProps, {
  getMyConnections,
})(ProfileFriends);
