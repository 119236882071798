import api from "./api";

// store our JWT in LS and set axios headers if we do have a token

const setAuthToken = (token, user) => {
  if (token) {
    api.defaults.headers.common["x-auth-token"] = token;
    localStorage.setItem("token", token);
    if (user !== undefined && user.user && user.user.id !== undefined) {
      localStorage.setItem("userId", user?.user?.id);
    }
  } else {
    delete api.defaults.headers.common["x-auth-token"];
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
  }
};
export default setAuthToken;
