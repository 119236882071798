import { useLocation, useNavigate } from "react-router-dom";
import { List, ListItem } from "@mui/material";
import PostAddIcon from "@mui/icons-material/PostAdd";
import PhotoIcon from "@mui/icons-material/Photo";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import InfoIcon from "@mui/icons-material/Info";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  SettingsNavListItemIcon,
  SettingsNavbarListItemButton,
  SettingsNavbarListItemText,
} from "../../components/list/settings-navbar-list";
import { getUserPost } from "../../actions/post";
import { useEffect } from "react";

const MediaNavbar = ({ getUserPost, post: { userPosts } }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    getUserPost(userId);
  }, [getUserPost, userId]);
  const menus = [
    {
      title: "Posts",
      route: "/media-posts",
      icon: <PostAddIcon />,
      subTitle: `${userPosts?.post?.length}`,
    },
    {
      title: "Photos",
      route: "/media-photos",
      icon: <PhotoIcon />,
    },
    {
      title: "Videos",
      route: "/media-videos",
      icon: <VideoLibraryIcon />,
    },
    {
      title: "About",
      route: "/media-about",
      icon: <InfoIcon />,
    },
  ];
  return (
    <List>
      {menus.map((item, index) => (
        <ListItem key={`settings-nav-item-${index}}`} disablePadding>
          <SettingsNavbarListItemButton
            selected={location.pathname === item.route}
            onClick={() => navigate(item.route)}
          >
            <SettingsNavListItemIcon
              sx={{
                color: "inherit",
              }}
            >
              {item.icon}
            </SettingsNavListItemIcon>
            <SettingsNavbarListItemText
              primary={item.title}
              secondary={item.subTitle}
            />
          </SettingsNavbarListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

MediaNavbar.propTypes = {
  getUserPost: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  post: state.post,
});

export default connect(mapStateToProps, { getUserPost })(MediaNavbar);
