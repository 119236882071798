import axios from "axios";
import store from "../store";
import { LOGOUT } from "../actions/types";

// Create an instance of axios
const api = axios.create({
  baseURL: `https://${
    process.env.NODE_ENV === "development"
      ? "localhost:8080"
      : "picme.click:8080"
  }/api`,
  headers: {
    "Content-Type": "application/json",
  },
});
/*
  NOTE: intercept any error responses from the api
 and check if the token is no longer valid.
 ie. Token has expired or user is no longer
 authenticated.
 logout the user if the token has expired
*/

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});
api.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 401) {
      
      store.dispatch({ type: LOGOUT });
    }
    return Promise.reject(err);
  }
);

export const fileUploadApi = axios.create({
  baseURL: `https://${
    process.env.NODE_ENV === "development"
      ? "localhost:8080"
      : "picme.click:8080"
  }/api`,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

fileUploadApi.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

export default api;
