import { Close, UploadFile, } from "@mui/icons-material";
import {
  DialogTitle,
  IconButton, Dialog, Box, Button, DialogContent
} from "@mui/material";
import React, { useMemo, useState, } from "react";


export const AddNewPostDialog = ({onClose,open, postUserReelDetails, postUserStoriesDetails, userId,}) => {
  const handleClose = () => {
    onClose();
  };
  const [postImage, setUpPostImage] = useState(null);
  const [reelData, setReelData] = useState({
    user:userId,
    postReels:null,
  });

  const handleChange = (e) => {
    if (e.target.name === "postReels") {
      setReelData({ ...reelData, postReels: e.target.files[0]});
    } 
    setUpPostImage(e.target.files[0])
  };
  const mediaUrl = useMemo(() => {
    if (!postImage) {
      return null;
    }
    // Create the media URL and return it
    return URL.createObjectURL(postImage);
  }, [postImage]);
  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataWithImage = new FormData();
    if (postUserReelDetails) {
      formDataWithImage.append("postReels", reelData.postReels);
      formDataWithImage.append("user", reelData.user);
      postUserReelDetails(formDataWithImage);
    } else {
      formDataWithImage.append("postStory", reelData.postReels);
      formDataWithImage.append("user", reelData.user);
      postUserStoriesDetails(formDataWithImage);
    }
    }
    const renderSelectedMedia = () => {
      if (!postImage) {
        return null;
      }
      return (
        <div style={{paddingTop:"10px"}}> 
          {postImage.type.startsWith("image") ? (
            <img
              src={mediaUrl}
              alt="Selected_Image"
              style={{
                maxWidth: "100%",
                maxHeight: "300px",
                borderRadius: "15px",
              }}
            />
          ) : postImage.type.startsWith("video") ? (
            <video
              controls
              src={mediaUrl}
              style={{
                maxWidth: "100%",
                maxHeight: "300px",
                borderRadius: "15px",
              }}
            />
          ) : null}
        </div>
      );
    };
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        flexShrink: 0,
        "& .MuiDialog-paper": {
          width: "350px",
        },
      }}
      anchor="right"
    >
      <DialogTitle
        sx={{
          fontSize: "14px !important",
          color: "#0A2A4A",
          m: 0,
          fontWeight:"600",
          padding: "20px"

        }}
      >
        Add New Story or Reels
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 20,
          top: 19,
          height: "25px",
          width: "25px",
        }}
      >
        <Close />
      </IconButton>
      <DialogContent sx={{
        padding: "20px",
        borderBottom:"0 !important"
      }} dividers>
       {renderSelectedMedia()}
      <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            paddingTop="25px"
            
          >
            <IconButton
              variant="contained"
              component="label"
              sx={{ marginRight: "10px", }}
            >
              <UploadFile />
              <input
                type="file"
                hidden
                name="postReels"
                onChange={handleChange}
              />
            </IconButton>
            <Box>
              <Button variant="contained" type="submit" onClick={handleSubmit}>
                Post
              </Button>
            </Box>
          </Box></DialogContent>
    </Dialog>
  );
};
