import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  Avatar,

} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect } from "react";


export const CertificateCard = ({
  openExperienceModal,
  getCertificate,
  certificateDetails,
  deleteCertificateDetail,
  certificateDetailDelete,
  setEditedData,
  userId,

}) => {
  const deleteRequest = (receiverId) => {
    deleteCertificateDetail(receiverId);
  };

  const handleClickEditOpen = (data, action) => {
    openExperienceModal();
    setEditedData({ ...data, action });
  };
  useEffect(() => {
    if (certificateDetailDelete.status === 200) {
      getCertificate(userId);
    }
  }, [certificateDetailDelete]);
  return (
    <Grid
      item
      xs={12}
      sx={{
        background: "#FFFFFF",
        boxShadow: "0px 0px 0px 2px rgb(0 0 0 / 10%)",
        borderRadius: "0.8rem",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          padding: "15px 20px",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            color: "#000000",
          }}
        >
          Certificates
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            color="inherit"
            style={{
              marginBottom: "16px",
              background: "#e4e6eb",
              fontSize: "14px",
              fontWeight: 600,
            }}
            onClick={openExperienceModal}
          >
            <AddIcon />
          </Button>
        </Box>
      </Box>

      <Divider width={"100%"} />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          padding: "15px",
          paddingBottom: "25px",
        }}
      >
        {certificateDetails?.Certificate?.length !== 0 ?
          certificateDetails?.Certificate?.map((user, index) => (

            <Card
              key={index}
              style={{
                maxWidth: "200px",
                width:"100%",
                borderRadius: "0.8rem",
                boxShadow: "0px 0px 0px 2px rgb(0 0 0 / 10%)",
                marginLeft: "10px",
                marginTop: "10px",
                height: "fit-content",
              }}
            >
              <CardContent
                sx={{ padding: " 0 !important ", position: "relative" }}
              >
                <Box sx={{
                  textAlign: "right", padding: " 5px 0px", display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                }}>
                  <Button
                    sx={{
                      fontSize: "12px",
                      color: "none",
                      border: "none",
                      borderRadius: "20px",
                      minWidth: "0px"

                    }}
                    onClick={() => deleteRequest(user._id)}
                  >
                    <DeleteIcon />
                  </Button>

                  <Button
                    sx={{
                      fontSize: "12px",
                      color: "none",
                      border: "none",
                      borderRadius: "20px",
                      minWidth: "0px",
                    }}
                    onClick={() => handleClickEditOpen(user, "edit")}
                  >
                    <EditIcon />
                  </Button>
                </Box>
                <Avatar
                  alt={user.certificateName}
                  src={
                    user.certificationImage &&
                      user.certificationImage !== "empty"
                      ? `https://${process.env.NODE_ENV === "development"
                        ? "localhost:8080"
                        : "picme.click:8080"
                      }/api/awards/photo/${user.certificationImage}`
                      : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
                  }
                  sx={{
                    height: "150px",
                    width: "100%",
                    borderRadius: "0px !important"
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "rgba(0, 0, 0, 1.0)",
                    textAlign: "center",
                    marginY: "5px",
                    marginX: "10px"
                  }}
                >
                  {user.certificateName}
                </Typography>

              </CardContent>
            </Card>

          )) : <Box
            sx={{
              padding: "16px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                fontSize: "16px",
                fontWeight: 600,
                color: "black",
              }}
            >
              No Experience Added Yet
            </Typography>
          </Box>}
      </Box>
    </Grid >
  );
};
