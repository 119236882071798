import api, { fileUploadApi } from "../utils/api";
import { setAlert } from "./alert";

import {
  GET_PROFILE,
  GET_PROFILE_BY_ID,
  GET_PROFILES,
  PROFILE_ERROR,
  UPDATE_PROFILE,
  CLEAR_PROFILE,
  ACCOUNT_DELETED,
  GET_REPOS,
  NO_REPOS,
  LOGOUT,
  UPDATE_PROFILE_COVER,
  GET_AWARDS_DETAILS,
  GET_AWARDS_IMG,
  POST_AWARDS_DETAILS,
  PUT_AWARDS_DETAILS,
  DELETE_AWARDS_DETAILS,
  GET_AWARDS_DETAILS_ERROR,
  POST_AWARDS_DETAILS_ERROR,
  PUT_AWARDS_DETAILS_ERROR,
  DELETE_AWARDS_DETAILS_ERROR,
  GET_AWARDS_IMG_ERROR,
  POST_EXPERIENCE_DETAILS,
  POST_EXPERIENCE_DETAILS_ERROR,
  DELETE_EXPERIENCE_DETAILS,
  DELETE_EXPERIENCE_DETAILS_ERROR,
  GET_CERTIFICATE_DETAILS,
  GET_CERTIFICATE_DETAILS_ERROR,
  DELETE_CERTIFICATE_DETAILS,
  DELETE_CERTIFICATE_DETAILS_ERROR,
  POST_CERTIFICATE_DETAILS,
  POST_CERTIFICATE_DETAILS_ERROR,
  PUT_CERTIFICATE_DETAILS,
  PUT_CERTIFICATE_DETAILS_ERROR,
  GET_USER_REELS_DETAILS,
  GET_USER_REELS_DETAILS_ERROR,
  GET_USER_STORY_DETAILS,
  GET_USER_STORY_DETAILS_ERROR,
  POST_USER_REELS_DETAILS,
  POST_USER_REELS_DETAILS_ERROR,
  DELETE_USER_REELS_DETAILS,
  DELETE_USER_REELS_DETAILS_ERROR,
  POST_BIO_DETAILS,
  POST_USER_STORIES_DETAILS,
  POST_USER_STORIES_DETAILS_ERROR,
  POST_ACTIVITY_DETAILS,
  GET_USER_ACTIVITY_DETAILS,
  GET_USER_FRIENDS_REELS_DETAILS,
  GET_USER_FRIENDS_REELS_DETAILS_ERROR,
  GET_USER_FRIENDS_STORY_DETAILS,
  GET_USER_FRIENDS_STORY_DETAILS_ERROR,
} from "./types";

/*
  NOTE: we don't need a config object for axios as the
 default headers in axios are already Content-Type: application/json
 also axios stringifies and parses JSON for you, so no need for 
 JSON.stringify or JSON.parse
*/

// Get current users profile
export const getCurrentProfile = () => async (dispatch) => {
  try {
    const res = await api.get("/profile/me");
    console.log(res.data.isEmailVerified);
    if (!res.data.isEmailVerified)
      /*dispatch({
        type: GET_PROFILE,
        payload: res.data,
      });*/
      dispatch({ type: LOGOUT });
    else
      dispatch({
        type: GET_PROFILE,
        payload: res.data,
      });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

// Get all profiles
export const getProfiles = () => async (dispatch) => {
  dispatch({ type: CLEAR_PROFILE });

  try {
    const res = await api.get("/profile");

    dispatch({
      type: GET_PROFILES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

// Get profile by ID
export const getProfileById = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`/profile/user/${userId}`);

    dispatch({
      type: GET_PROFILE_BY_ID,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

// Get Github repos
export const getGithubRepos = (username) => async (dispatch) => {
  try {
    const res = await api.get(`/profile/github/${username}`);

    dispatch({
      type: GET_REPOS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: NO_REPOS,
    });
  }
};

// Create or update profile
export const createProfile =
  (formData, navigate, edit = false, profile = null) =>
  async (dispatch) => {
    try {
      const newFormData = new FormData();

      const dateOfBirth = formData.dateOfBirth;

      // Append all fields to newFormData
      for (const key in formData) {
        // Handle special cases
        if (key === "avatar") {
          // Check if a new profile photo is uploaded
          if (formData.avatar instanceof File) {
            console.log("instance of file");
            newFormData.append("avatar", formData.avatar);
          } else {
            console.log("not an instance of file");
            // If it's not a file, append the existing avatar URL (assuming it's a string)
            newFormData.append("avatar", formData.avatar);
          }
        } else if (key === "dateOfBirth") {
          // Append formatted dateOfBirth
          newFormData.append("dateOfBirth", dateOfBirth);
        } else if (
          key === "coachingHistory" ||
          key === "qualifications" ||
          key === "hobbies" ||
          key === "achievements" ||
          key === "sportingHistory" ||
          key === "achievementsList" ||
          key === "managerialHistory" ||
          key === "qualificationsList"
        ) {
          newFormData.append(key, JSON.stringify(formData[key]));
        } else {
          // Append other fields
          newFormData.append(key, formData[key]);
        }
      }

      // newFormData.append(
      //   "coachingHistory",
      //   JSON.stringify(formData.coachingHistory)
      // );
      // newFormData.append(
      //   "qualifications",
      //   JSON.stringify(formData.qualifications)
      // );
      // newFormData.append("hobbies", JSON.stringify(formData.hobbies));
      // newFormData.append(
      //   "sportingHistory",
      //   JSON.stringify(formData.sportingHistory)
      // );
      // newFormData.append("achievements", JSON.stringify(formData.achievements));

      const res = await fileUploadApi.post("/profile", newFormData);
      dispatch({
        type: GET_PROFILE,
        payload: res.data,
      });

      dispatch(
        setAlert(edit ? "Profile Updated" : "Profile Created", "success")
      );

      if (!edit) {
        navigate("/dashboard");
      }

      // Check if a new profile photo is uploaded and delete the old photo
      if (
        edit &&
        formData.avatar instanceof File &&
        formData.avatar !== profile.avatar
      ) {
        const oldProfilePhoto = profile.avatar;
        console.log("old profile photo", oldProfilePhoto);
        const deleteRes = await fileUploadApi.delete(
          `/profile/photo/${oldProfilePhoto}`
        );
        console.log(deleteRes);
      }
    } catch (err) {
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }

      dispatch({
        type: PROFILE_ERROR,
        payload: {
          msg: err?.response?.statusText,
          status: err?.response?.status,
        },
      });
    }
  };

export const postCoverImage = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/profile/cover-image", formData);
    console.log("response of post api :", res);
    dispatch({
      type: UPDATE_PROFILE_COVER,
      payload: res.data,
    });

    dispatch(setAlert("Cover Image Updated ", "success"));
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

// Add Experience
export const addExperience = (formData, navigate) => async (dispatch) => {
  try {
    const res = await api.put("/profile/experience", formData);
    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data,
    });

    dispatch(setAlert("Experience Added", "success"));

    navigate("/dashboard");
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

// Add Education
export const addEducation = (formData, navigate) => async (dispatch) => {
  try {
    const res = await api.put("/profile/education", formData);

    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data,
    });

    dispatch(setAlert("Education Added", "success"));

    navigate("/dashboard");
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

// Delete experience
export const deleteExperience = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/profile/experience/${id}`);

    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data,
    });

    dispatch(setAlert("Experience Removed", "success"));
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

// Delete education
export const deleteEducation = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/profile/education/${id}`);

    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data,
    });

    dispatch(setAlert("Education Removed", "success"));
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

// Delete account & profile
export const deleteAccount = () => async (dispatch) => {
  if (window.confirm("Are you sure? This can NOT be undone!")) {
    try {
      await api.delete("/profile");

      dispatch({ type: CLEAR_PROFILE });
      dispatch({ type: ACCOUNT_DELETED });

      dispatch(setAlert("Your account has been permanently deleted"));
    } catch (err) {
      dispatch({
        type: PROFILE_ERROR,
        payload: {
          msg: err?.response?.statusText,
          status: err?.response?.status,
        },
      });
    }
  }
};
// *********************************Awards API****************************

// Get Awards details
export const getAwards = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`/awards/details/${userId}`);
    dispatch({
      type: GET_AWARDS_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_AWARDS_DETAILS_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

// Get Awards
export const getAwardsImg = () => async (dispatch) => {
  try {
    const res = await api.get(
      `/awards/photo//awardImage-1711977019580-823865599.jpg`
    );

    dispatch({
      type: GET_AWARDS_IMG,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_AWARDS_IMG_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};
// Post Awards details
export const postAwardDetails = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/awards/details", formData);
    console.log("response of post api :", res);
    dispatch({
      type: POST_AWARDS_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: POST_AWARDS_DETAILS_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

// update Awards details
export const putAwardDetails = (formData, navigate) => async (dispatch) => {
  try {
    const res = await api.put("awards/update-award", formData);
    dispatch({
      type: PUT_AWARDS_DETAILS,
      payload: res.data,
    });
    dispatch(setAlert("Award details Added", "success"));
    navigate("/dashboard");
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: PUT_AWARDS_DETAILS_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

// Delete Awards details
export const deleteAwardDetail = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/awards/delete-award/${id}`);
    dispatch({
      type: DELETE_AWARDS_DETAILS,
      payload: res.data,
    });

    dispatch(setAlert("Award Removed", "success"));
  } catch (err) {
    dispatch({
      type: DELETE_AWARDS_DETAILS_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

// Post Experience details
export const postExperienceDetails = (formData) => async (dispatch) => {
  try {
    const res = await api.post("profile/experience", formData);
    console.log("response of post api :", res);
    dispatch({
      type: POST_EXPERIENCE_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: POST_EXPERIENCE_DETAILS_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

// Delete Experience details
export const deleteExperienceDetail = (payload) => async (dispatch) => {
  try {
    const res = await api.post(`/profile/experience/delete`, payload);
    dispatch({
      type: DELETE_EXPERIENCE_DETAILS,
      payload: res.data,
    });

    dispatch(setAlert("Award Removed", "success"));
  } catch (err) {
    dispatch({
      type: DELETE_EXPERIENCE_DETAILS_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

// Get certificate details
export const getCertificate = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`/certificate/details/${userId}`);
    dispatch({
      type: GET_CERTIFICATE_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_CERTIFICATE_DETAILS_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

// Delete Certificate details
export const deleteCertificateDetail = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/certificate/delete-certificate/${id}`);
    dispatch({
      type: DELETE_CERTIFICATE_DETAILS,
      payload: res.data,
    });
    dispatch(setAlert("Certificate Removed", "success"));
  } catch (err) {
    dispatch({
      type: DELETE_CERTIFICATE_DETAILS_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

// Post Certificate details
export const postCertificateDetails = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/certificate/details", formData);
    console.log("response of post api :", res);
    dispatch({
      type: POST_CERTIFICATE_DETAILS,
      payload: res.data,
    });
    dispatch(setAlert("Certificate details Added", "success"));
  } catch (err) {
    dispatch({
      type: POST_CERTIFICATE_DETAILS_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

// update Certificate details
export const putCertificateDetails = (formData) => async (dispatch) => {
  try {
    const res = await api.put("/certificate/update-certificate", formData);
    dispatch({
      type: PUT_CERTIFICATE_DETAILS,
      payload: res.data,
    });
    dispatch(setAlert("Certificate details Updated", "success"));
  } catch (err) {
    // const errors = err.response.data.errors;
    // if (errors) {
    //   errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    // }
    dispatch({
      type: PUT_CERTIFICATE_DETAILS_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

// Get USER REELS details
export const getUserReels = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`/reels/user_reels/${userId}`);
    dispatch({
      type: GET_USER_REELS_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_USER_REELS_DETAILS_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

// Get USER REELS details
export const getUserFriendsReels = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`/reels/friends_reels/${userId}`);
    dispatch({
      type: GET_USER_FRIENDS_REELS_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_USER_FRIENDS_REELS_DETAILS_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

// Post USER REELS details
export const postUserReelDetails = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/reels", formData);
    console.log("response of post api :", res);
    dispatch({
      type: POST_USER_REELS_DETAILS,
      payload: res.data,
    });
    dispatch(setAlert("Reel Added", "success"));
  } catch (err) {
    dispatch({
      type: POST_USER_REELS_DETAILS_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

// Post USER Stories details
export const postUserStoriesDetails = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/story", formData);
    console.log("response of post api :", res);
    dispatch({
      type: POST_USER_STORIES_DETAILS,
      payload: res.data,
    });
    dispatch(setAlert("story Added", "success"));
  } catch (err) {
    dispatch({
      type: POST_USER_STORIES_DETAILS_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};
// Delete User reel details
export const deleteUserReelDetail = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/reels/${id}`);
    dispatch({
      type: DELETE_USER_REELS_DETAILS,
      payload: res.data,
    });
    dispatch(setAlert("Reel Removed", "success"));
  } catch (err) {
    dispatch({
      type: DELETE_USER_REELS_DETAILS_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

// Get USER story details
export const getUserStory = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`/story/user_story/${userId}`);
    dispatch({
      type: GET_USER_STORY_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_USER_STORY_DETAILS_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};
// Get USER story details
export const getUserFriendsStory = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`/story/friends_story/${userId}`);
    dispatch({
      type: GET_USER_FRIENDS_STORY_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_USER_FRIENDS_STORY_DETAILS_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

export const postBioDetails = (payload) => async (dispatch) => {
  try {
    const res = await api.put("/profile/bio", payload);
    console.log("response of post api :", res);
    dispatch({
      type: POST_BIO_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: POST_BIO_DETAILS,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

export const postActivityDetails = (payload) => async (dispatch) => {
  try {
    const res = await api.post("/dashboard/activity", payload);
    console.log("response of post api :", res);
    dispatch({
      type: POST_ACTIVITY_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: POST_ACTIVITY_DETAILS,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

// Get user activity details
export const getUserActivity = () => async (dispatch) => {
  try {
    const res = await api.get(`/dashboard/activity`);
    dispatch({
      type: GET_USER_ACTIVITY_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_USER_ACTIVITY_DETAILS,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};
