import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Button,
  Typography,
  TextField,
  Box
} from "@mui/material";
import PropTypes from "prop-types";
import {
  Edit,
  Work,
  School,
  LocationOn,
  Home,
  Favorite,
} from "@mui/icons-material";
const IntroCard = ({
  ProfileProp,
  openExperienceModal,
  profile, postBioDetails, userId
}) => {
  const [open, setOpen] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleClick = (e) => {
    e.stopPropagation()
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [formData, setFormData] = useState({
    bio:  profile.bio,
    user: userId,
  });
  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      bio: formData.bio,
      user: formData.user,
    };
    postBioDetails(payload);
    handleClose()
  };

  return (
    <Card>
      <CardContent sx={{ paddingX: "16px", paddingY: "20px" }}>
        <Typography
          gutterBottom
          sx={{
            fontSize: "20px",
            fontWeight: 600,
          }}
        >
          Intro
        </Typography>
        <Typography
          gutterBottom
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            textAlign: 'center'

          }}
        >
          {profile.bio}
        </Typography>
        {open && (
          <form onSubmit={handleSubmit} onClick={(e) => e.stopPropagation()}>
            <TextField
              label="Bio"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              margin="normal"
              name={`bio`}
              value={formData.bio}
              onChange={handleChange}
            />
            <Box sx={{
              marginBottom: "10px",
              width: "100%",
              display: "flex",
              justifyContent: 'flex-end',
            }}>
              <Button type="submit" variant="contained" color="primary"
                onClick={handleSubmit}
              >
                Send
              </Button></Box></form>

        )}
        {!ProfileProp && !open && (
          <Button
            variant="contained"
            color="inherit"
            style={{
              marginBottom: "16px",
              width: "100%",
              background: "#e4e6eb",
              fontSize: "14px",
              fontWeight: 600,
            }}
            onClick={handleClick}
          >
            Add Bio
          </Button>
        )}

        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <Work style={{ marginRight: "12px", color: "rgb(166 166 166)" }} />
            <Typography variant="body2">
              Works at:{" "}{profile.pressCompanyName}
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <School
              style={{ marginRight: "12px", color: "rgb(166 166 166)" }}
            />
            <Typography variant="body2">
              Studied at:{" "}
              {profile.education && profile.education[0]?.degree
                ? profile.education[0].degree
                : ""}{" "}
              {profile.schooling}
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <LocationOn
              style={{ marginRight: "12px", color: "rgb(166 166 166)" }}
            />
            <Typography variant="body2">
              Lives in:{" "}
              {profile.pressCityLocated || profile.pressCountryLocated
                ? `${profile.pressCityLocated} ,
              ${profile.pressCountryLocated}`
                : ""}
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <Home style={{ marginRight: "12px", color: "rgb(166 166 166)" }} />
            <Typography variant="body2">
              From:{" "}
              {profile.cityOfBirth || profile.countryOfBirth
                ? `${profile.cityOfBirth} ,
              ${profile.countryOfBirth}`
                : ""}
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <Favorite
              style={{ marginRight: "12px", color: "rgb(166 166 166)" }}
            />
            <Typography variant="body2">In a relationship with:{" "}</Typography>
          </div>
        </div>

        {!ProfileProp && (
          <Button
            variant="contained"
            color="inherit"
            style={{
              width: "100%",
              marginBottom: "16px",
              background: "#e4e6eb",
              fontSize: "14px",
              fontWeight: 600,
            }}
            startIcon={<Edit />}
            onClick={openExperienceModal}
          >
            Edit Details / Experience
          </Button>
        )}
      </CardContent>
    </Card >
  );
};

IntroCard.prototype = {
  postBioDetails: PropTypes.func.isRequired,
  openBioModal: PropTypes.func,
  openExperienceModal: PropTypes.func,
};
export default IntroCard

