import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import { logout } from "../../actions/auth";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';

 function ProfilePopup({open,anchorEl, handleClose, logout}) {


  return ( 
  <div>
    
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem component={Link} to="/dashboard">  <PersonIcon style={{marginRight:"5px"}}></PersonIcon>Profile</MenuItem>
      <MenuItem component={Link} to="/login" onClick={logout}><LogoutIcon style={{marginRight:"5px"}}></LogoutIcon>Logout</MenuItem>
    </Menu>
  </div>
  );
}
ProfilePopup.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, { logout })(ProfilePopup);