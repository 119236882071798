import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { postExperienceDetails } from "../../actions/profile";
import { Close } from "@mui/icons-material";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const ExperiencePop = ({
  postExperienceDetails,
  closeExperienceModal,
  editedData,
  userId,
}) => {
  const [objectURL, setObjectURL] = useState("");
  const [isEdited, setIsEdited] = useState(
    editedData.title !== undefined ? true : false
  );
  const fileInput = React.createRef();
  const [formData, setFormData] = useState({
    experienceId: editedData ? editedData._id : "",
    title: editedData ? editedData.title : "",
    company: editedData ? editedData.company : "",
    location: editedData ? editedData.location : "",
    from: editedData ? editedData.from : "",
    to: editedData ? editedData.to : "",
    current: false,
    description: editedData ? editedData.description : "",
    experienceImage: editedData ? editedData.experienceImage : null,
    user: userId,
  });

  const handleChange = (e) => {
    if (e.target.name === "experienceImage") {
      setFormData({ ...formData, experienceImage: e.target.files[0] });
      setObjectURL(URL.createObjectURL(e.target.files[0]));
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataWithImage = new FormData();
    formDataWithImage.append("title", formData.title);
    formDataWithImage.append("company", formData.company);
    formDataWithImage.append("location", formData.location);
    formDataWithImage.append("from", formData.from);
    formDataWithImage.append("to", formData.to);
    formDataWithImage.append("current", formData.current);
    formDataWithImage.append("description", formData.description);
    formDataWithImage.append("experienceImage", formData.experienceImage);
    formDataWithImage.append("user", formData.user);
    if (editedData.action === "edit") {
      formDataWithImage.append("experienceId", formData.experienceId);
    }
    postExperienceDetails(formDataWithImage);
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        background: "#FFFFFF",
        borderRadius: "6px",
        gap: "16px",
        paddingX: "20px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: " space-between" }}>
        {" "}
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 600,
          }}
        >
          {editedData.action === "edit" ? "Edit Experience" : "Add Experience"}
        </Typography>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 600,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={closeExperienceModal}
            sx={{
              //position: "absolute",
              right: 0,
              top: 0,
              height: "25px",
              width: "25px",
            }}
          >
            <Close />
          </IconButton>
        </Typography>
      </Box>
      <Box>
        <Grid
          item
          xs={12}
          sx={{
            background: "rgb(241 241 241 / 55%)",
            borderRadius: "6px",
            padding: "20px",
            gap: "16px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              paddingRight: "0px !important",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              gap: "20px",
            }}
          >
            <Grid
              item
              xs={6}
              sx={{
                paddingRight: "0px !important",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                position: "relative",
                gap: "10px",
              }}
            >
              {" "}
              <Typography
                sx={{
                  fontSize: "12px",
                }}
              >
                Job Title
              </Typography>
              <TextField
                type="text"
                placeholder=""
                name={`title`}
                height="30px"
                fontSize="12px"
                border="1px solid rgb(0 0 0 / 30%)"
                value={formData.title}
                onChange={handleChange}
                // disabled={editedData && editedData.action === "view"}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                paddingRight: "0px !important",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                position: "relative",
                gap: "10px",
              }}
            >
              {" "}
              <Typography
                sx={{
                  fontSize: "12px",
                }}
              >
                Company Name
              </Typography>
              <TextField
                type="text"
                placeholder=""
                name={`company`}
                height="30px"
                fontSize="12px"
                border="1px solid rgb(0 0 0 / 30%)"
                value={formData.company}
                onChange={handleChange}
                // disabled={editedData && editedData.action === "view"}
              />
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              paddingRight: "0px !important",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              position: "relative",
              gap: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
              }}
            >
              Role
            </Typography>
            <TextField
              type="text"
              placeholder=""
              name={`location`}
              sx={{
                fontSize: "12px",
                width: "100%",
              }}
              value={formData.location}
              onChange={handleChange}
              // disabled={editedData && editedData.action === "view"}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              paddingRight: "0px !important",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              gap: "20px",
            }}
          >
            <Grid
              item
              xs={6}
              sx={{
                paddingRight: "0px !important",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                position: "relative",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                }}
              >
                Start Date
              </Typography>
              <TextField
                type="date"
                variant="outlined"
                format={"DD/MM/YYYY"}
                fullWidth
                name={`from`}
                fontSize={"12px"}
                value={formData.from}
                onChange={handleChange}
                // disabled={editedData && editedData.action === "view"}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                paddingRight: "0px !important",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                position: "relative",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                }}
              >
                End Date
              </Typography>
              <TextField
                type="date"
                variant="outlined"
                format={"DD/MM/YYYY"}
                fullWidth
                name={`to`}
                fontSize={"12px"}
                value={formData.to}
                onChange={handleChange}
                // disabled={editedData && editedData.action === "view"}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              paddingRight: "0px !important",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              position: "relative",
              gap: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
              }}
            >
              Description
            </Typography>
            <TextField
              type="text"
              placeholder=""
              name={`description`}
              sx={{
                fontSize: "12px",
                width: "100%",
              }}
              value={formData.description}
              onChange={handleChange}
              // disabled={editedData && editedData.action === "view"}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              paddingRight: "0px !important",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              gap: "20px",
            }}
          >
            {" "}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: 3,
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  padding: "10px",
                }}
              >
                Experience Image
              </Typography>
              {objectURL !== "" ? (
                <img
                  src={objectURL}
                  alt="Profile"
                  style={{
                    maxHeight: "200px",
                    maxWidth: "200px",
                    marginBottom: "8px",
                  }}
                />
              ) : (
                editedData.experienceImage && (
                  <img
                    alt={editedData.name}
                    src={
                      editedData.experienceImage &&
                      editedData.experienceImage !== "empty"
                        ? `https://${
                            process.env.NODE_ENV === "development"
                              ? "localhost:8080"
                              : "picme.click:8080"
                          }/api/profile/experience/photo/${
                            editedData?.experienceImage
                          }`
                        : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
                    }
                    style={{
                      maxHeight: "200px",
                      maxWidth: "200px",
                      marginBottom: "8px",
                    }}
                  />
                )
              )}

              <Button variant="contained" component="label">
                {!isEdited
                  ? `${objectURL !== "" ? "Image Uploaded" : "Upload Image"}`
                  : `${objectURL !== "" ? "Image Updated" : "Update Image"}`}
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  name="experienceImage"
                  onChange={handleChange}
                  hidden
                  ref={fileInput}
                />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          my: 2,
        }}
      >
        <Button
          sx={{
            width: 70,
            height: 30,
            borderRadius: "3px",
            padding: "6px 8px !important",
            lineHeight: "30px",
            backgroundColor: "#0C345D",
            color: "#FFFFFF",
            fontSize: "12px",
            "&:hover": {
              backgroundColor: "transparent",
              color: "#0C345D",
              border: "1px solid #0C345D",
            },
            "&.Mui-disabled": {
              color: "#FFFFFF",
              opacity: 0.8,
            },
            paddingX: "40px",
          }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Box>
    </Grid>
  );
};

ExperiencePop.propTypes = {
  postExperienceDetails: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  profile: state.profile,
});
export default connect(mapStateToProps, { postExperienceDetails })(
  ExperiencePop
);

// export default ExperiencePop;
