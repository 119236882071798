
import { Grid } from "@mui/material";
import { SideBar } from "./Sidebar";
import AddFriends from "./AddFriends";
import Invitation from "./Invitations";
const UserList = () => {

  const UsersProp = "Users"
  return (
    <>
      <Grid
        container
        spacing={3}
        sx={{
          padding: "35px",
          paddingRight: "10px",
          paddingTop: "34px",
          gap: "20px",
          paddingLeft: "58px",
          justifyContent: "center",
        }}
      >
        <Grid item xs={2} sx={{ padding: "0 !important" }}>
          <SideBar />
        </Grid>
        <Grid
          item
          xs={6.35}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            padding: "0 !important",
          }}
        >
          <Grid item xs={12}>
            <Invitation />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              backgroundColor: "white",
              borderRadius: "0.8rem",
              boxShadow: "0px 5px 7px -7px rgba(0, 0, 0, 0.75)",
              padding: "12px !important",
              maxWidth: "829px !important",
            }}
          >
            <AddFriends UsersProp={UsersProp} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UserList;
