import { useSelector } from "react-redux";
import {
  Grid,
  Typography,
  Avatar,
  Stack,
  List,
  ListItem,
  Paper,
  Divider,
} from "@mui/material";

import AddressCard from "./AddressCard";
import PicMeLogo from "../../img/logo.png";
import { styled } from "@mui/material/styles";

const Div = styled("div")(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));

export default function AboutUs() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return (
    <>
      <Paper
        sx={{
          p: 2,
          margin: "auto",
          mt: 10,
          backgroundColor: (theme) =>
            theme.palette.mode === "dark" ? "#F0F8FF" : "#F0F8FF",
          boxShadow: 3,
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            p: 2,
            "& .MuiButton-root": { width: 100 },
          }}
          direction="column"
          alignItems="center"
          textAlign="center"
          justifyContent="center"
          justify="center"
        >
          <Grid item xs={3}>
            <Avatar
              alt="PicMeLogo"
              src={PicMeLogo}
              sx={{ width: 100, height: 100 }}
              variant="square"
            />
          </Grid>

          <Grid item xs={3}>
            <Typography variant="h4" sx={{ m: 2 }}>
              New tech startup looking for developers to get involved for equity
              share.
            </Typography>{" "}
            <Divider />
            <Typography variant="h5">
              <List>
                <ListItem>
                  <Typography variant="h5">
                    We are looking for developers to assist in helping us build
                    a platform. We are looking to launch by the end of August
                    2023. The contract is for 3months and you will receive an
                    equity share based upon tasks completed. For each task
                    verified by our head developer you will be awarded the
                    shares related to this task. All interested parties please
                    send your C.V. or LinkedIn details to{" "}
                    <a href="mailto:dh@picme.click">dh@picme.click</a>.
                  </Typography>
                </ListItem>
                <Divider />
                <ListItem sx={{ m: 2 }}>Our technology stack:</ListItem>
                <Divider />
                <Typography variant="h6">
                  <ListItem>1. Front End Development - React.js, MUI</ListItem>
                  <ListItem>
                    2. Back End Development - node.js, express.js, MongoDB
                  </ListItem>
                  <ListItem>
                    3. DevOps Development - Docker, Kubernetes, AWS
                  </ListItem>
                  <ListItem>
                    4. iOS Development - Swift, ReactNative.js
                  </ListItem>
                  <ListItem>
                    5. Android Development - Java, ReactNative.js
                  </ListItem>
                </Typography>
                <Divider />
                <ListItem>
                  We will be in touch shortly thereafter and send you details of
                  information and contract to be signed Best Directors
                </ListItem>
                <ListItem>
                  <AddressCard />
                </ListItem>
              </List>
            </Typography>
          </Grid>
          <Stack
            direction="row"
            spacing={3}
            sx={{
              p: 2,
            }}
          >
            {/*
            <Button variant="outlined" href="/register">
              Sign Up
            </Button>

            <Button variant="contained" href="/login">
              Login
            </Button>
            */}
          </Stack>
        </Grid>
      </Paper>
      
    </>
  );
}
