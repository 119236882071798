import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { postCertificateDetails } from "../../actions/profile";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Close } from "@mui/icons-material";
import { putCertificateDetails } from "../../actions/profile";

const CertificatesPopup = ({
  editedData,
  postCertificateDetails,
  putCertificateDetails,
  profile: { certificatePost, certificateDetailUpdate },
  closeExperienceModal,
  getCertificate,
  userId,
}) => {
  const [objectURL, setObjectURL] = useState(""); // Change initial state to null
  // const [uploadError, setUploadError] = useState(null);
  const fileInput = React.createRef();
  const [isEdited, setIsEdited] = useState(
    editedData.certificateName !== undefined ? true : false
  );

  useEffect(() => {
    if (certificateDetailUpdate.status === 200) {
      getCertificate(userId);
      closeExperienceModal();
    }
  }, [certificateDetailUpdate]);

  const [formData, setFormData] = useState({
    id: editedData ? editedData._id : "",
    certificateName: editedData ? editedData.certificateName : "",
    certificateImage: editedData ? editedData.certificateImage : null,
    user: userId,
  });
  const handleChange = (e) => {
    if (e.target.name === "certificateImage") {
      setFormData({ ...formData, certificateImage: e.target.files[0] });
      setObjectURL(URL.createObjectURL(e.target.files[0]));
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataWithImage = new FormData();

    if (editedData.action === "edit") {
      formDataWithImage.append("id", formData.id);
      formDataWithImage.append("certificateName", formData.certificateName);
      formDataWithImage.append("certificateImage", formData.certificateImage);
      formDataWithImage.append("user", formData.user);
      putCertificateDetails(formDataWithImage);
    } else {
      formDataWithImage.append("certificateName", formData.certificateName);
      formDataWithImage.append("certificateImage", formData.certificateImage);
      formDataWithImage.append("user", formData.user);
      postCertificateDetails(formDataWithImage);
    }
  };
  // const handlePhoto = (e) => {
  //   const image = e.target.files[0];
  //   if (image && image.size > 1024 * 1024 * 1) { // Change "Image" to "image"
  //     setUploadError("File size exceeds 1MB");
  //     return;
  //   }
  //   setUploadError(null);
  //   setObjectURL(URL.createObjectURL(image));
  // };

  return (
    <Grid
      item
      xs={12}
      sx={{
        background: "#FFFFFF",
        borderRadius: "6px",
        paddingX: "20px",
        gap: "16px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: " space-between" }}>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 600,
          }}
        >
          {editedData.action === "edit"
            ? "Edit certificate"
            : "Add certificate"}
        </Typography>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 600,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={closeExperienceModal}
            sx={{
              position: "absolute",
              right: 38,
              top: 19,
              height: "25px",
              width: "25px",
            }}
          >
            <Close />
          </IconButton>
        </Typography>
      </Box>
      <form>
        <Box>
          <Grid
            item
            xs={12}
            sx={{
              background: "rgb(241 241 241 / 55%)",
              borderRadius: "6px",
              padding: "20px",
              gap: "16px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                paddingRight: "0px !important",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                gap: "20px",
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  paddingRight: "0px !important",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  position: "relative",
                  gap: "10px",
                }}
              >
                {" "}
                <Typography
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  Certificate Name
                </Typography>
                <TextField
                  type="text"
                  placeholder=""
                  name="certificateName"
                  height="30px"
                  fontSize="12px"
                  border="1px solid rgb(0 0 0 / 30%)"
                  value={formData.certificateName}
                  onChange={handleChange}
                  sx={{ width: "100%" }}
                />
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                paddingRight: "0px !important",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                gap: "20px",
              }}
            ></Grid>

            <Grid
              item
              xs={12}
              sx={{
                paddingRight: "0px !important",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                gap: "20px",
              }}
            >
              {" "}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mt: 3,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    padding: "10px",
                  }}
                >
                  Certificate Image
                </Typography>
                {objectURL !== "" ? (
                  <img
                    src={objectURL}
                    alt="Profile"
                    style={{
                      maxHeight: "200px",
                      maxWidth: "200px",
                      marginBottom: "8px",
                    }}
                  />
                ) : (
                  editedData.certificationImage && (
                    <img
                      alt={editedData.certificateName}
                      src={
                        editedData.certificationImage &&
                        editedData.certificationImage !== "empty"
                          ? `https://${
                              process.env.NODE_ENV === "development"
                                ? "localhost:8080"
                                : "picme.click:8080"
                            }/api/awards/photo/${editedData.certificationImage}`
                          : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
                      }
                      style={{
                        maxHeight: "200px",
                        maxWidth: "200px",
                        marginBottom: "8px",
                      }}
                    />
                  )
                )}

                <Button variant="contained" component="label">
                  {!isEdited
                    ? `${objectURL !== "" ? "Image Uploaded" : "Upload Image"}`
                    : `${objectURL !== "" ? "Image Updated" : "Update Image"}`}
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    name="certificateImage"
                    onChange={handleChange}
                    hidden
                    ref={fileInput}
                  />
                </Button>
                {/* {uploadError && (
                  <Typography variant="body2" color="error" mt={1}>
                    {uploadError}
                  </Typography>
                )} */}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            my: 3,
          }}
        >
          <Button
            type="submit"
            variant="contained"
            style={{ marginRight: "10px" }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </form>
    </Grid>
  );
};

CertificatesPopup.propTypes = {
  postCertificateDetails: PropTypes.func.isRequired,
  putCertificateDetails: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  profile: state.profile,
});
export default connect(mapStateToProps, {
  postCertificateDetails,
  putCertificateDetails,
})(CertificatesPopup);
