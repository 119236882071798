export const DATA_ROLES = {
  dateOfBirth: "Birthdate",
  countryOfBirth: "Country of Birth (*required)",
  cityOfBirth: "City of Birth (*required)",
  height: "Height in Cms or Feet (*required)",
  weight: "Weight in Kg's or Pounds (*required)",
  schooling: "Schooling (*required)",
  tertiaryEducation: "Tertiary Education (*required)",
  favouriteMovie: "Favourite Movie",
  favouriteArtist: "Favourite Artist or Group",
  favouriteFood: "Favourite Food",
  hobbies: "Hobbies",
  playingPosition: "Playing Position (*required)",
  sportingHistory: "List your Sporting History (*required)",
  achievements: "List your achievements (*required)",
  coachingHistory: "List your Coaching History ",
  managerialHistory: "List your Managerial History ",
  qualificationsList: "List your qualifications ",
  achievementsList: "List your achievements",
  pressCompanyName: "Company Name (*required)",
  pressConceptionDate: "Conception date (*required) Cell Format(date format entry)",
  pressCountryLocated: "Country where Company is located (*required) Cell Format (text entry of Country Name)",
  pressCityLocated: "City where Company is located (*required) Cell Format (text entry of City Name)",
  fanFavoriteTeam: "Favourite Team or Sports star (*required)",
};
export const DATA_PLAYER = [
  "dateOfBirth",
  "countryOfBirth",
  "cityOfBirth",
  "height",
  "weight",
  "schooling",
  "tertiaryEducation",
  "favouriteMovie",
  "favouriteArtist",
  "favouriteFood",
  "hobbies",
  "playingPosition",
  "sportingHistory",
  "achievements",
];

export const DATA_PLAYER_EXAMPLE = [
  { data: "dateOfBirth", required: true },
  { data: "countryOfBirth", required: true },
  { data: "cityOfBirth", required: true },
  { data: "height", required: true },
  { data: "weight", required: true },
  { data: "schooling", required: true },
  { data: "tertiaryEducation", required: true },
  { data: "favouriteMovie", required: false },
  { data: "favouriteArtist", required: false },
  { data: "favouriteFood", required: false },
  { data: "hobbies", required: false },
  { data: "playingPosition", required: true },
  { data: "sportingHistory", required: true },
  { data: "achievements", required: true }
];


export const DATA_COACH = [
  "dateOfBirth",
  "countryOfBirth",
  "cityOfBirth",
  "schooling",
  "tertiaryEducation",
  "favouriteMovie",
  "favouriteArtist",
  "favouriteFood",
  "hobbies",
  "coachingHistory",
  "qualificationsList",
  "achievementsList",
];

export const DATA_MANAGER = [
  "dateOfBirth",
  "countryOfBirth",
  "cityOfBirth",
  "schooling",
  "tertiaryEducation",
  "favouriteMovie",
  "favouriteArtist",
  "favouriteFood",
  "hobbies",
  "managerialHistory",
  "qualificationsList",
  "achievementsList",
];

export const DATA_PRESS = [
  "pressCompanyName",
  "pressConceptionDate",
  "pressCityLocated",
  "pressCountryLocated",
];

export const DATA_FAN = [
  "dateOfBirth",
  "countryOfBirth",
  "cityOfBirth",
  "height",
  "schooling",
  "tertiaryEducation",
  "favouriteMovie",
  "favouriteArtist",
  "favouriteFood",
  "hobbies",
  "fanFavoriteTeam",
];

export const roleDataArray = [
  {
    value: "dateOfBirth",
    description: "Birthdate",
  },
  {
    value: "countryOfBirth",
    description: "Country of Birth (*required)",
  },
  {
    value: "cityOfBirth",
    description: "City of Birth (*required)",
  },
  {
    value: "height",
    description: "Height in Cms or Feet (*required)",
  },
  {
    value: "weight",
    description: "Weight in Kg's or Pounds (*required)",
  },
  {
    value: "schooling",
    description: "Schooling (*required)",
  },
  {
    value: "tertiaryEducation",
    description: "Tertiary Education (*required)",
  },
  {
    value: "favouriteMovie",
    description: "Favourite Movie",
  },
  {
    value: "favouriteArtist",
    description: "Favourite Artist or Group",
  },
  {
    value: "favouriteFood",
    description: "Favourite Food",
  },
  {
    value: "hobbies",
    description: "Hobbies",
  },
  {
    value: "playingPosition",
    description: "Playing Position (*required)",
  },
  {
    value: "sportingHistory",
    description: "List your Sporting History (*required)",
  },
  {
    value: "achievements",
    description: "List your acheivments (*required)",
  },
  {
    value: "coachingHistory",
    description: "List your Coaching History ",
  },
  {
    value: "managerialHistory",
    description: "List your Managerial History ",
  },
  {
    value: "qualificationsList",
    description: "List your qualifications ",
  },
  {
    value: "achievementsList",
    description: "List your achievements",
  },
  {
    value: "pressCompanyName",
    description: "Company Name (*required)",
  },
  {
    value: "pressConceptionDate",
    description: "Conception date (*required)",
  },
  {
    value: "pressCityLocated",
    description: "City where Company is located(*re..)",
  },
  {
    value: "pressCountryLocated",
    description: "Country where Company is located (*r",
  },
  {
    value: "fanFavoriteTeam",
    description: "Favourite Team or Sports star (*requir",
  },
];

export const roleRequiredFields = {
  player: [
    "firstname",
    "lastname",
    "email",
    "sport",
    "dateOfBirth",
    "countryOfBirth",
    "cityOfBirth",
    "height",
    "weight",
    "schooling",
    "tertiaryEducation",
    "playingPosition",
    "sportingHistory",
    "achievements",
  ],
  coach: [
    "firstname",
    "lastname",
    "email",
    "sport",
    "dateOfBirth",
    "countryOfBirth",
    "cityOfBirth",
    "schooling",
    "tertiaryEducation",
  ],
  manager: [
    "firstname",
    "lastname",
    "email",
    "sport",
    "dateOfBirth",
    "countryOfBirth",
    "cityOfBirth",
    "schooling",
    "tertiaryEducation",
  ],
  press: [
    "firstname",
    "lastname",
    "email",
    "sport",
    "pressCompanyName",
    "pressConceptionDate",
    "pressCountryLocated",
    "pressCityLocated",
  ],
  fans: [
    "firstname",
    "lastname",
    "email",
    "sport",
    "countryOfBirth",
    "cityOfBirth",
    "height",
    "schooling",
    "tertiaryEducation",
  ],
};

export const listFields = [
  "sportingHistory",
  "achievements",
  "coachingHistory",
  "managerialHistory",
  "qualificationsList",
  "achievementsList",
  "hobbies",
];
export const countryFieldsArray = ["countryOfBirth", "pressCountryLocated"];
export const cityFieldsArray = ["cityOfBirth", "pressCityLocated"];
