import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { SettingsNavbar } from "../../components/user-settings/settings-navbar";
export const SideBar = () => {
  return (
    <Grid
      item
      xs={12}
      sx={{
        background: "#FFFFFF",
        boxShadow: "0px 0px 0px 2px rgb(0 0 0 / 10%)",
        borderRadius: "0.8rem",
        paddingY: "15px !important",
        overflow: "auto",
        minHeight: "500px",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "flex-start",
        maxWidth: "300px"
      }}
    >
      <Box sx={{
        width: "100%"
      }}>
        <Typography
          sx={{ fontSize: "16px", fontWeight: "500", color: "#000000E6", paddingX: "12px" }}
        >
          Manage my network
        </Typography>
        <SettingsNavbar />
      </Box>
      <Box sx={{
        paddingX: "12px"
      }}>
        {/* <Button
          variant="text"
          endIcon={<ExpandLessIcon />}
          sx={{ fontSize: "16px", color: "#00000099", fontWeight: "500" }}
        >
          Show Less
        </Button> */}
        </Box>
    </Grid>
  );
};
