/* eslint-disable array-callback-return */

import { Box, Button, Grid, Typography, Avatar, Divider } from "@mui/material";
import React, { useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { connect } from "react-redux";
import { deleteInvitationAccepted } from "../../actions/connections";

import {
  getMyConnections,
  postConnectionRequest,
} from "../../actions/connections";

const Connection = ({
  getMyConnections,
  connections: { myConnections, rejectInvitationRequest },
  deleteInvitationAccepted,
}) => {
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    if (rejectInvitationRequest.status === 200) {
      getMyConnections(userId);
    }
  }, [getMyConnections, rejectInvitationRequest, userId]);
  useEffect(() => {
    getMyConnections(userId);
  }, [getMyConnections, userId]);

  const deleteRequest = (receiverinvitaionId) => {
    deleteInvitationAccepted(receiverinvitaionId);
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        background: "#FFFFFF",
        boxShadow: "0px 0px 0px 2px rgb(0 0 0 / 10%)",
        borderRadius: "0.8rem",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "flex-start",
        maxWidth: "829px !important",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          padding: "15px 20px",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            color: "#000000E6",
          }}
        >
          {myConnections.list && myConnections.list.length} Connections
        </Typography>
      </Box>
      <Divider width={"100%"} />
      {myConnections.list && myConnections.list.length !== 0 ? (
        myConnections.list.map((users, index) => {
          return (
            <>
              <Box
                key={index}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "15px 20px",
                }}
              >
                <a
                  href={`/profile/${users.friend.user}`}
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{}}>
                      <Avatar
                        alt={users.friend.profileId.firstname}
                        src={
                          users.friend.profileId.avatar &&
                          users.friend.profileId.avatar !== "empty"
                            ? `https://${
                                process.env.NODE_ENV === "development"
                                  ? "localhost:8080"
                                  : "picme.click:8080"
                              }/api/profile/photo/${
                                users.friend.profileId.avatar
                              }`
                            : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
                        }
                        //src={users.friend.profileId.avatar}
                        sx={{
                          height: "70px",
                          width: "70px",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "15px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "700",
                          color: "rgba(0, 0, 0, .9);",
                          textTransform: "capitalize",
                        }}
                      >
                        {users.friend.profileId.firstname}{" "}
                        {users.friend.profileId.lastname}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          // fontWeight: "400",
                          color: "rgba(0, 0, 0, .6);",
                        }}
                      >
                        {users.content}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          // fontWeight: "100",
                          color: "rgba(0, 0, 0, .6);",
                        }}
                      >
                        {users.subcontent}
                      </Typography>
                    </Box>
                  </Box>
                </a>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Button
                    variant="text"
                    sx={{
                      fontSize: "16px",
                      color: "#2065D1",
                      height: "32px",
                      background: "white",
                      padding: "10px 30px",
                      borderRadius: "20px",
                      border: "1px solid #0a66c2",
                      fontWeight: "600",
                    }}
                  >
                    Message
                  </Button>

                  <Button
                    sx={{
                      fontSize: "16px",
                      color: "none",
                      // height: "32px",
                      border: "none",
                    }}
                    onClick={() => deleteRequest(users._id)}
                  >
                    <DeleteIcon />
                  </Button>
                </Box>
              </Box>
              <Divider width={"100%"} />
            </>
          );
        })
      ) : (
        <Box
          sx={{
            padding: "16px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              color: "black",
            }}
          >
            No Connections Yet
          </Typography>
        </Box>
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  connections: state.connections,
});

export default connect(mapStateToProps, {
  getMyConnections,
  postConnectionRequest,
  deleteInvitationAccepted,
})(Connection);
