import React, { useState, useEffect } from "react";
import { Close } from "@mui/icons-material";
import { Navigate } from 'react-router-dom';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { postUserListing } from "../../actions/connections";
import { putUserListing } from "../../actions/connections";
import { getUserListing } from "../../actions/connections";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
const UserListEdit = ({
  putUserListing,
  connections: { userListUpdate, getUserListing },
  editedData,
  closeExperienceModal,
}) => {
  const userId = localStorage.getItem("userId");
  const [objectURL, setObjectURL] = useState("");
  const [uploadError, setUploadError] = useState(null);
  const [isEdited, setIsEdited] = useState(
  );
  const fileInput = React.createRef();

  const [formData, setFormData] = useState({
    position: editedData ? editedData.position : "",
    location:editedData ? editedData.location :  "",
    package:editedData ? editedData.package : "",
    listingImage: editedData ? editedData.listingImage: null,
    user: userId,
    id:editedData ? editedData._id :"",
  });
  
  const navigate = useNavigate();
  const handleChange = (e) => {
    if (e.target.name === "listingImage") {
      setFormData({ ...formData, listingImage: e.target.files[0] });
      setObjectURL(URL.createObjectURL(e.target.files[0]));
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataWithImage = new FormData();
    formDataWithImage.append("position", formData.position);
    formDataWithImage.append("location", formData.location);
    formDataWithImage.append("package", formData.package);
    formDataWithImage.append("listingImage", formData.listingImage);
    formDataWithImage.append("user", formData.user);
    formDataWithImage.append("id", formData.id)
    putUserListing(formDataWithImage)
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        background: "#FFFFFF",
        borderRadius: "6px",
        paddingX: "20px",
        gap: "16px",
        display: "flex",
        flexDirection: "column",
      }}
    >
       <Box sx={{ display: "flex", justifyContent: " space-between" }}>
        {" "}
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 600,
          }}
        >
       User List Edit 
        </Typography>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 600,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={closeExperienceModal}
            sx={{
              //position: "absolute",
              right: 0,
              top: 0,
              height: "25px",
              width: "25px",
            }}
          >
            <Close />
          </IconButton>
        </Typography>
      </Box>
      <form>
        <Box>
          <Grid
            item
            xs={12}
            sx={{
              background: "rgb(241 241 241 / 55%)",
              borderRadius: "6px",
              padding: "20px",
              gap: "16px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                paddingRight: "0px !important",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                gap: "20px",
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  paddingRight: "0px !important",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  position: "relative",
                  gap: "10px",
                  width: "100%",
                }}
              >
                {" "}
                <Typography
                  sx={{
                    fontSize: "12px",
                  }}
                >
                Position
                </Typography>
                <TextField
                  type="text"
                  placeholder=""
                  name="position"
                  height="30px"
                  fontSize="12px"
                  border="1px solid rgb(0 0 0 / 30%)"
                  value={formData.position}
                  onChange={handleChange}
                  sx={{
                    fontSize: "12px",
                    width: "100%",
                  }}
                />
              </Grid>
              {/* <Grid
                item
                xs={6}
                sx={{
                  paddingRight: "0px !important",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  position: "relative",
                  gap: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  Company Name
                </Typography>
                <TextField
                  type="text"
                  placeholder=""
                  name="companyName"
                  fontSize="12px"
                  border="1px solid rgb(0 0 0 / 30%)"
                  value={formData.companyName}
                  onChange={handleChange}
                />
              </Grid> */}
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                paddingRight: "0px !important",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                gap: "20px",
              }}
            >
              <Grid
                item
                xs={6}
                sx={{
                  paddingRight: "0px !important",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  position: "relative",
                  gap: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  Location
                </Typography>
                <TextField
                  type="text"
                  placeholder=""
                  fullWidth
                  name="location"
                  fontSize={"12px"}
                  value={formData.location}
                  onChange={handleChange}
                />
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  paddingRight: "0px !important",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  position: "relative",
                  gap: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  Package
                </Typography>
                <TextField
                  type="text"
                  placeholder=""
                  fullWidth
                  name="package"
                  fontSize={"12px"}
                  value={formData.package}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            {/* <Grid
              item
              xs={12}
              sx={{
                paddingRight: "0px !important",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                position: "relative",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                }}
              >
                Package
              </Typography>
              <TextField
                type="text"
                placeholder=""
                name="description"
                sx={{
                  fontSize: "12px",
                  width: "100%",
                }}
                value={formData.description}
                onChange={handleChange}
              />
            </Grid> */}
            <Grid
              item
              xs={12}
              sx={{
                paddingRight: "0px !important",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                gap: "20px",
              }}
            >
              {" "}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mt: 3,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    padding: "10px",
                  }}
                >
                   Image
                </Typography>
                {objectURL !== "" ? (
                  <img
                    src={objectURL}
                    alt="Profile"
                    style={{
                      maxHeight: "200px",
                      maxWidth: "200px",
                      marginBottom: "8px",
                    }}
                  />
                ) : (
                  editedData.listingImage && (
                    <img
                      alt={"hg"}
                      src={
                        editedData.listingImage &&
                        editedData.listingImage !== "empty"
                          ? `https://${
                              process.env.NODE_ENV === "development"
                                ? "localhost:8080"
                                : "picme.click:8080"
                            }/api/listing/photo/${
                              editedData?.listingImage
                            }`
                          : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
                      }
                      style={{
                        maxHeight: "200px",
                        maxWidth: "200px",
                        marginBottom: "8px",
                      }}
                    />
                  )    
                )}

                <Button variant="contained" component="label">
                  {!isEdited
                    ? `${objectURL !== "" ? "Image Uploaded" : "Upload Image"}`
                    : `${objectURL !== "" ? "Image Updated" : "Update Image"}`}
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    name="listingImage"
                    onChange={handleChange}
                    hidden
                    ref={fileInput}
                  />
                </Button>
                {uploadError && (
                  <Typography variant="body2" color="error" mt={1}>
                    {uploadError}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            my: 3,
          }}
        >
          <Button
            type="submit"
            variant="contained"
            style={{ marginRight: "10px" }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </form>
    </Grid>
  );
};

UserListEdit.propTypes = {
  postUserListing: PropTypes.func.isRequired,
  putUserListing: PropTypes.func.isRequired,
  getUserListing: PropTypes.func.isRequired,
  connections: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  connections: state.profile,
});
export default connect(mapStateToProps, { postUserListing, putUserListing, getUserListing})(
  UserListEdit
);

