/* eslint-disable react/jsx-no-undef */
import React, { useState } from "react";
import { Button, Card, CardActions, CardMedia } from "@mui/material";
import CoverImageDefault from "../../img/default-img.png";
import { postCoverImage } from "../../actions/profile";
import PropTypes from "prop-types";
import { connect } from "react-redux";
const DashboardHeader = ({ profile, postCoverImage, ProfileProp }) => {
  const [objectURL, setObjectURL] = useState(false);
  const fileInputRef = React.createRef();
  // const handleEditCoverPhoto = () => {
  //   // Programmatically trigger file input click
  //   if (fileInputRef.current) {
  //     fileInputRef.current.click();
  //   }
  // };
  //setObjectURL(URL.createObjectURL(image));
  // const handleFileChange = (event) => {
  //   const selectedFile = event.target.files[0];

  //   if (selectedFile) {
  //     // Convert the selected file to a data URL
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setCoverImage(reader.result);
  //     };
  //     reader.readAsDataURL(selectedFile);
  //   }
  // };
  const handleEditCoverPhoto = (e) => {
    const user = localStorage.getItem("userId");
    const image = e.target.files[0];
    // if (Image && image?.size > 1024 * 1024 * 1) {
    //   setUploadError("File size exceeds 1MB");
    //   return;
    // }
    // setUploadError(null);
    setObjectURL(URL.createObjectURL(image));
    const formData = new FormData();
    formData.append("user", user);
    image && formData.append("coverImage", image);
    postCoverImage(formData);
  };
  return (
    <Card
      sx={{
        position: "relative",
        borderRadius: "0px !important",
        border: "0px !important",
        "&.MuiPaper-root": {
          boxShadow: "none !important",
        },
      }}
    >
      <CardMedia
        component="img"
        alt=""
        height="350"
        sx={{ padding: 0 }}
        image={
          objectURL
            ? objectURL
            : profile?.coverImage !== ""
              ? `https://${process.env.NODE_ENV === "development"
                ? "localhost:8080"
                : "picme.click:8080"
              }/api/profile/photo/${profile?.coverImage}`
              : CoverImageDefault
        }
      //image={coverImage || objectURL}
      />

      <CardActions
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {/* <Button
          variant="contained"
          sx={{
            background: "rgba(0, 0, 0, 0.5)",
            color: "white",
          }}
          startIcon={<PhotoCamera />}
        >
          Create with Avatar
        </Button> */}
        {!ProfileProp && (
          <Button
            variant="contained"
            component="label"
            sx={{
              background: "rgba(0, 0, 0, 0.5)",
              color: "white",
            }}
          // startIcon={<Edit/>}
          >
            Edit Cover Photo
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              name="coverImage"
              onChange={handleEditCoverPhoto}
              hidden
              ref={fileInputRef}
            />
          </Button>
        )}

        {/* Hidden file input for selecting cover photo */}
        {/* <input
          type="file"
          accept=".png, .jpg, .jpeg"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        /> */}
      </CardActions>
    </Card>
  );
};
DashboardHeader.prototype = {
  postCoverImage: PropTypes.func.isRequired,
};

export default connect(null, { postCoverImage })(DashboardHeader);
