import { Grid } from "@mui/material";
import { AwardCard } from "./AwardCard";
import { Modal } from "@mui/material";
import { getAwards } from "../../actions/profile";
import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";
import AwardsPopUp from "./AwardsPopUp";
import { deleteAwardDetail } from "../../actions/profile";
import { ListSidebar } from "./ListSidebar";
import { PostSectionSideBar } from "./PostSectionSideBar";
const AwardList = ({ getAwards, deleteAwardDetail, profile: { awardDetails, awardDetailDelete, awardPost, awardDetailUpdate } }) => {

  const userId = localStorage.getItem("userId");
  useEffect(() => {
    getAwards(userId);
  }, [getAwards, userId]);
  const [editedData, setEditedData] = useState({});
  const [isExperiernceModalOpen, setIsExperienceModalOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const openExperienceModal = () => {
    setIsExperienceModalOpen(true);
  };
  const closeExperienceModal = () => {
    setIsExperienceModalOpen(false);
    setEditedData({});
  };

  useEffect(() => {
    if (
      (awardPost.status === 200) ||
      (awardDetailUpdate.status === 200)
    ) {
      getAwards(userId);
      closeExperienceModal();
    }
  }, [awardPost, awardDetailUpdate,]);
  return (
    <>
      <Grid
        container
        spacing={3}
        sx={{
          gap: "20px",
          padding: "24px",
          justifyContent: "center",
          paddingRight: "0px !important"
        }}
      >
        <Grid item xs={12}
          sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap", gap: "20px", padding: "0px !important" }}>
          <Grid
            item
            xs={2}
            sx={{ padding: "0 !important", }}
          >
            <ListSidebar />
            <PostSectionSideBar/>
          </Grid>

          <Grid item xs={6.35} sx={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
            <AwardCard openExperienceModal={openExperienceModal} awardDetails={awardDetails} awardDetailDelete={awardDetailDelete} deleteAwardDetail={deleteAwardDetail} userId={userId} getAwards={getAwards} setEditedData={setEditedData} />
          </Grid>
         </Grid>
      </Grid >
      <Modal
        open={isExperiernceModalOpen}
        onClose={closeExperienceModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            borderRadius: "15px",
            padding: "12px",
            width: isSmallScreen ? "90%" : "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              overflow: 'auto',
              height: "64vh",
            }}
          >
            <AwardsPopUp closeExperienceModal={closeExperienceModal} userId={userId} getAwards={getAwards} awardDetailDelete={awardDetailDelete} editedData={editedData}/>
            {/* <Button sx={{ height: "20px" }} onClick={closeExperienceModal}>
                <CloseIcon />
              </Button> */}
          </div>
        </div>
      </Modal>
    </>

  );
};
AwardList.propTypes = {
  getAwards: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  profile: state.profile,

});
export default connect(mapStateToProps, { getAwards, deleteAwardDetail })(
  AwardList
);