import { Grid, Modal } from "@mui/material";
import React from "react";
import { PostSectionSideBar } from "./PostSectionSideBar";
import { ListSidebar } from "./ListSidebar";
// import { ListCard } from "./ListCard";
import PostCard from "./PostCard";
export const PostListing = () => {
  const userId = localStorage.getItem("userId");


  return (
    <Grid
      container
      spacing={3}
      sx={{
        gap: "20px",
        padding: "24px",
        justifyContent: "center",
        paddingRight: "0 !important"
      }}
    >
      <Grid item xs={12}
        // sx={{
        //   // background: "#FFFFFF",
        //   // boxShadow: "0px 0px 0px 2px rgb(0 0 0 / 10%)",
        //   borderRadius: "0.8rem",
        //   overflow: "auto",
        //   display: "flex",
        //   flexDirection: "row",
        //   padding: "10px !important",
        //   paddingRight: "20px !important",
        //   alignItems: "flex-start",
        //   justifyContent: "center",
        //   maxWidth: "100% !important",

        // }}
        sx={{ display: "flex", justifyContent: "center", gap: "20px", padding: "0px !important" }}
      >
        <Grid
          item
          xs={2}
          sx={{ padding: "0 !important", }}
        >
         <ListSidebar/>
          <PostSectionSideBar/>
        </Grid>
        {/* <Box sx={{ paddingBottom: "10px" }}>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              color: 'black',
            }}
          >
            My Listings 
          </Typography>
        </Box> */}

        <Grid item xs={6.35} sx={{
          display: "flex", flexWrap: "wrap", gap: "20px",
        }}>

        {/* <ListCard/> */}
        <PostCard userId={userId}/>
        </Grid>
     

      </Grid>
    </Grid >
  );
};
