import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography, Avatar, Divider } from "@mui/material";
import { getMyContactList } from "../../actions/connections";
import { deleteInvitationAccepted } from "../../actions/connections";
import { connect } from "react-redux";
import UserPageWithdraw from "./UserPageWithdraw";

const ContactList = ({
  getMyContactList,
  connections: { contactList, rejectInvitationRequest },
  deleteInvitationAccepted,
}) => {
  const userId = localStorage.getItem("userId");
 
  const [connectionId, setConnectionId] = useState("");

  useEffect(() => {
    if (rejectInvitationRequest.status === 200) {
      getMyContactList(userId);
    }
  }, [getMyContactList, rejectInvitationRequest, userId]);

  useEffect(() => {
    getMyContactList(userId);
  }, [getMyContactList, userId]);

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (connectionId !== "") {
      setOpen(true);
    }
  }, [connectionId]);

  const handleClickOpen = (users) => {
    setConnectionId(users);
  };

  const handleClose = () => {
    setConnectionId("");
    setOpen(false);
  };

  const deleteRequest = (receiverinvitaionId) => {
    deleteInvitationAccepted(receiverinvitaionId);
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        background: "#FFFFFF",
        boxShadow: "0px 0px 0px 2px rgb(0 0 0 / 10%)",
        borderRadius: "0.8rem",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "flex-start",
        maxWidth: "829px !important",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          padding: "15px 20px",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            color: "#000000E6",
          }}
        >
          Pending Request List
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            color: "#00000099",
          }}
        >
          See all {contactList.list && contactList.list.length}
        </Typography>
      </Box>
      <Divider width={"100%"} />
      {contactList.list && contactList.list.length !== 0 ? (
        contactList.list.map((users, index) => (
          <React.Fragment>
            <Box
              key={index}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                padding: "15px 20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Box sx={{}}>
                  <Avatar
                    alt={users.requestReceiver.profileId.firstname}
                    src={
                      users.requestReceiver.profileId.avatar &&
                      users.requestReceiver.profileId.avatar !== "empty"
                        ? `https://${
                            process.env.NODE_ENV === "development"
                              ? "localhost:8080"
                              : "picme.click:8080"
                          }/api/profile/photo/${
                            users.requestReceiver.profileId.avatar
                          }`
                        : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
                    }
                    sx={{
                      height: "70px",
                      width: "70px",
                      textTransform: "capitalize",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    marginLeft: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "700",
                      color: "rgba(0, 0, 0, .9);",
                    }}
                  >
                    {users.requestReceiver.profileId.firstname}{" "}
                    {users.requestReceiver.profileId.lastname}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "rgba(0, 0, 0, .6);",
                    }}
                  >
                    {users.content}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "rgba(0, 0, 0, .6);",
                    }}
                  >
                    {users.subcontent}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    fontSize: "16px",
                    color: "#00000099",
                    height: "32px",
                    background: "transparent",
                    padding: "10px 30px",
                  }}
                  onClick={() => {
                    handleClickOpen(users._id);
                  }}
                >
                  Withdraw
                </Button>
              </Box>
            </Box>
            <Divider width={"100%"} />
            <UserPageWithdraw
              open={open}
              onClose={handleClose}
              userId={connectionId}
              deleteRequest={deleteRequest}
            />
          </React.Fragment>
        ))
      ) : (
        <Box
          sx={{
            padding: "16px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              color: "black",
            }}
          >
            No Pending Request Yet
          </Typography>
        </Box>
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  connections: state.connections,
});

export default connect(mapStateToProps, {
  getMyContactList,
  deleteInvitationAccepted,
})(ContactList);
