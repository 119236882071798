import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getCurrentProfile } from "../../actions/profile";
import { addLike, removeLike, deletePost } from "../../actions/post";
import {
  approvePost,
  rejectPost,
  getProfilePicturePosts,
  getRegularPosts,
  rejectRegularPost,
  approveRegularPost,
} from "../../actions/admin";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Divider,
  Grid,
  MenuItem,
  Select,
  CircularProgress,
  Avatar,
} from "@mui/material";
import moment from "moment";
import PostItemMui from "../../components/posts/PostItemMui";
const HANDLETYPES = {
  profilePicture: "profilePicture",
  regularPost: "regularPost",
};
const Admin = ({
  profilePicturePosts,
  regularPosts,
  approvePost,
  rejectPost,
  getProfilePicturePosts,
  getRegularPosts,
  approveRegularPost,
  rejectRegularPost,
  profile: { profile },
  getCurrentProfile,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const [profileCurrentPage, setProfileCurrentPage] = useState(1);
  const [profilePostsPerPage, setProfilePostsPerPage] = useState(10);
  // const [isImage, setImage] = useState(null);
  // const [isVideo, setVideo] = useState(null);

  useEffect(() => {
    if (!profile) getCurrentProfile();
  }, [getCurrentProfile, profile]);

  useEffect(() => {
    setIsProfileLoading(true);
    getProfilePicturePosts(profileCurrentPage, profilePostsPerPage)
      .then(() => setIsProfileLoading(false))
      .catch(() => setIsProfileLoading(false));
  }, [getProfilePicturePosts, profileCurrentPage, profilePostsPerPage]);

  useEffect(() => {
    setIsLoading(true);
    getRegularPosts(currentPage, postsPerPage)
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [getRegularPosts, currentPage, postsPerPage]);


  const handleApprove = (postId, type) => {
    switch (type) {
      case HANDLETYPES.profilePicture:
        approvePost(postId);
        break;
      case HANDLETYPES.regularPost:
        approveRegularPost(postId);
        break;
      default:
        // Handle default case or error
        break;
    }
  };

  const handleReject = (postId, type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case HANDLETYPES.profilePicture:
        rejectPost(postId);
        break;
      case HANDLETYPES.regularPost:
        rejectRegularPost(postId);
    }
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextProfilePage = () => {
    setProfileCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevProfilePage = () => {
    setProfileCurrentPage((prevPage) => prevPage - 1);
  };

  return (
    <Grid
      container
      spacing={3}
      sx={{
        padding: "24px",
        justifyContent: "center",
        paddingRight: "0px !important",
        background: "#FFFFFF",
        boxShadow: "0px 0px 0px 2px rgb(0 0 0 / 10%)",
        borderRadius: "0.8rem",
        margin: "0 !important",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          width: "100%",
          padding: "1rem",
          textAlign: "center",
        }}
      >
        {/* <Typography variant="h4" sx={{ marginBottom: "2rem" }}>
          Admin Page
        </Typography> */}

        {/* Profile Picture Posts */}
        <Typography variant="h5" sx={{ marginBottom: "1rem" }}>
          Profile Picture Updates
        </Typography>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              justifyContent: "center",
              gap: "20px",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {isProfileLoading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="200px"
              >
                <CircularProgress />
              </Box>
            ) : Array.isArray(profilePicturePosts) &&
              profilePicturePosts?.length > 0 ? (
              profilePicturePosts.map((post) => (
                <Card
                  key={post._id}
                  sx={{ border: 1, boxShadow: 1, width: "350px" }}
                >
                  <CardMedia
                    component="img"
                    height="250"
                    image={`https://${
                      process.env.NODE_ENV === "development"
                        ? "localhost:8080"
                        : "picme.click:8080"
                    }/api/profile/photo/${post.avatar}`}
                    alt={post.title}
                  />
                  <CardContent>
                    {/* Additional information */}
                    <Box display="flex" alignItems="center">
                      <Avatar
                        src={
                          post?.avatar && post.avatar !== "empty"
                            ? `https://${
                                process.env.NODE_ENV === "development"
                                  ? "localhost:8080"
                                  : "picme.click:8080"
                              }/api/profile/photo/${post.avatar}`
                            : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
                        }
                        alt="User Avatar"
                        sx={{
                          width: "40px",
                          height: "40px",
                          objectFit: "cover",
                          marginRight: "1rem",
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: "15px",
                          color: "#050505",
                          fontWeight: "600",
                          margin: "0px !important",
                          textTransform: "capitalize",
                        }}
                      >
                        {post.teamName}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          color: "#050505",
                          fontWeight: "600",
                          margin: "0px !important",
                          textTransform: "capitalize",
                        }}
                      >
                        {post.firstname} {post.lastname}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "15px",
                          color: "#050505",
                          fontWeight: "600",
                          margin: "0px !important",
                          textTransform: "capitalize",
                          textAlign: "left",
                        }}
                      >
                        Posted by: {post.firstname && post.firstname}{" "}
                        {post.lastname && post.lastname}
                      </Typography>
                      {/* Time posted */}
                      <Typography
                        sx={{
                          fontSize: "15px",
                          color: "#050505",
                          fontWeight: "600",
                          margin: "0px !important",
                          textTransform: "capitalize",
                          textAlign: "left",
                        }}
                      >
                        Posted on: {moment(post.date).format("MMMM DD, YYYY")}
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions sx={{ justifyContent: "space-evenly" }}>
                    <Button
                      onClick={() =>
                        handleApprove(post._id, HANDLETYPES.profilePicture)
                      }
                    >
                      Approve
                    </Button>
                    <Button
                      onClick={() =>
                        handleReject(post._id, HANDLETYPES.profilePicture)
                      }
                    >
                      Reject
                    </Button>
                  </CardActions>
                </Card>
              ))
            ) : (
              <Grid item xs={12}>
                <Typography>No new profile picture posts available.</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Divider sx={{ margin: "2rem 0" }} />
        {Array.isArray(profilePicturePosts) &&
          profilePicturePosts?.length > 0 &&
          !isProfileLoading && (
            <Box display="flex" justifyContent="center" mt={2}>
              <Button
                disabled={profileCurrentPage === 1}
                onClick={handlePrevProfilePage}
              >
                Previous Profile Page
              </Button>
              <Button
                disabled={
                  Array.isArray(profilePicturePosts) &&
                  profilePicturePosts.length < profilePostsPerPage
                }
                onClick={handleNextProfilePage}
              >
                Next Profile Page
              </Button>
            </Box>
          )}

        {/* Select Box for profile posts per page */}
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <Typography variant="subtitle1">Profile Posts per page:</Typography>
          <Select
            value={profilePostsPerPage}
            onChange={(e) => setProfilePostsPerPage(parseInt(e.target.value))}
            sx={{ marginLeft: "1rem" }} // Adjust the spacing between the text and the select box
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
          </Select>
        </Box>

        {/* Divider */}
        <Divider sx={{ margin: "2rem 0" }} />

        {/* Regular Posts */}
        <Typography variant="h5" sx={{ marginBottom: "1rem" }}>
          New Post Updates
        </Typography>
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="200px"
          >
            <CircularProgress />
          </Box>
        ) : Array.isArray(regularPosts) && regularPosts.length > 0 ? (
          regularPosts.map((post) => (
            <Card
              key={post._id}
              sx={{
                maxWidth: 400,
                margin: "1rem auto",
                border: 1,
                boxShadow: 1,
              }}
            >
              <PostItemMui key={post._id} post={post} showActions={false} deletePost={deletePost} addLike={addLike} removeLike={removeLike}/>
              <CardActions>
                <Button
                  onClick={() =>
                    handleApprove(post._id, HANDLETYPES.regularPost)
                  }
                >
                  Approve
                </Button>
                <Button
                  onClick={() =>
                    handleReject(post._id, HANDLETYPES.regularPost)
                  }
                >
                  Reject
                </Button>
              </CardActions>
            </Card>
          ))
        ) : (
          <Typography>No regular posts available.</Typography>
        )}

        <Divider sx={{ margin: "2rem 0" }} />
        {Array.isArray(regularPosts) &&
          regularPosts.length > 0 &&
          !isLoading && (
            <Box display="flex" justifyContent="center" mt={2}>
              <Button disabled={currentPage === 1} onClick={handlePrevPage}>
                Previous Page
              </Button>
              <Button
                disabled={
                  Array.isArray(regularPosts) &&
                  regularPosts.length < postsPerPage
                }
                onClick={handleNextPage}
              >
                Next Page
              </Button>
            </Box>
          )}

        {/* Select Box for posts per page */}
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <Typography variant="subtitle1">Posts per page:</Typography>
          <Select
            value={postsPerPage}
            onChange={(e) => setPostsPerPage(parseInt(e.target.value))}
            sx={{ marginLeft: "1rem" }} // Adjust the spacing between the text and the select box
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
          </Select>
        </Box>
      </Box>
    </Grid>
  );
};

Admin.propTypes = {
  profilePicturePosts: PropTypes.array.isRequired,
  regularPosts: PropTypes.array.isRequired,
  approvePost: PropTypes.func.isRequired,
  rejectPost: PropTypes.func.isRequired,
  getProfilePicturePosts: PropTypes.func.isRequired,
  getRegularPosts: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profilePicturePosts: state?.admin?.profilePicturePosts || [],
  regularPosts: state?.admin?.regularPosts || [],
  profile: state.profile,
});

export default connect(mapStateToProps, {
  approvePost,
  rejectPost,
  getProfilePicturePosts,
  getRegularPosts,
  approveRegularPost,
  rejectRegularPost,
  getCurrentProfile,
})(Admin);
