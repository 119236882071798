import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  Divider,
} from "@mui/material";
import { connect } from "react-redux";
import { getUserListing } from "../../actions/connections";
import { deleteUserListing } from "../../actions/connections";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
const ListCard = ({
  getUserListing,
  closeExperienceModal,
  setEditedData,
  deleteUserListing,
  openExperienceModal,
  connections: { userListDetail, userListDelete, userListUpdate },
}) => {
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    getUserListing(userId);
  }, [getUserListing, userId]);
  const deleteRequest = (receiverId) => {
    deleteUserListing(receiverId);
  };
  useEffect(() => {
    if (userListDetail.status === 200) {
      getUserListing(userId);
    }
  }, [userListDetail]);
  useEffect(() => {
    if (userListDelete.status === 200) {
      getUserListing(userId);
    }
  }, [userListDelete]);
  useEffect(() => {
    if (userListUpdate.status === 200) {
      getUserListing(userId);
      closeExperienceModal();
    }
  }, [userListUpdate]);
  const handleClickEditOpen = (data, action) => {
    openExperienceModal();
    setEditedData({ ...data, action });
  };
  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          background: "#FFFFFF",
          boxShadow: "0px 0px 0px 2px rgb(0 0 0 / 10%)",
          borderRadius: "0.8rem",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            padding: "15px 20px",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: "#000000",
            }}
          >
            My Listing
          </Typography>
        </Box>{" "}
        <Divider width={"100%"} />
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            padding: "15px",
            paddingBottom: "25px",
          }}
        >
          {userListDetail?.list && userListDetail?.list?.length !== 0 ? (
            userListDetail?.list.map((user, index) => (
              <Card
                key={index}
                style={{
                  maxWidth: "200px",
                  width:"100%",
                  borderRadius: "0.8rem",
                  boxShadow: "0px 0px 0px 2px rgb(0 0 0 / 10%)",
                  marginLeft: "10px",
                  marginTop: "10px",
                  height: "fit-content",
                }}
              >
                <CardContent
                  sx={{
                    padding: 0,
                    paddingBottom: "0px !important",
                    // height:"195px",
                    //  width:"100%"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "self-end",
                      gap: "10px",
                  // width:"100%"
                    }}
                  >
                    <Button
                      sx={{
                        fontSize: "12px",
                        color: "none",
                        border: "none",
                        borderRadius: "20px",
                      }}
                      onClick={() => deleteRequest(user._id)}
                    >
                      <DeleteIcon />
                    </Button>

                    <Button
                      sx={{
                        fontSize: "12px",
                        color: "none",
                        border: "none",
                        borderRadius: "20px",
                        minWidth: "0px",
                      }}
                      onClick={() => handleClickEditOpen(user)}
                    >
                      <EditIcon />
                    </Button>
                  </Box>
                  <Box sx={{height:"195px", width:"100%"}}>
                  <img
                    alt={"user"}
                    src={
                      user.listingImage && user.listingImage !== "empty"
                        ? `https://${
                            process.env.NODE_ENV === "development"
                              ? "localhost:8080"
                              : "picme.click:8080"
                          }/api/listing/photo/${user.listingImage}`
                        : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
                    }
                    height={"195px"}
                    width={"100%"}
                    style={{ objectFit: "cover"}}
                  />
                  </Box>
                  <Box
                    sx={{
                      padding: "10px 10px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        fontWeight: "500",
                        color: "#000000e6",
                        padding: "6px",
                        fontSize: "14px",
                        textAlign: "left",
                        paddingBottom: "0 !important",
                      }}
                    >
                      {`Position – ${user.position}`}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        color: "#000000e6",
                        padding: "6px",
                        fontSize: "14px",
                        textAlign: "left",
                        paddingBottom: "0 !important",
                      }}
                    >
                      {`Location – ${user.location}`}
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1px",
                        justifyContent: "start",
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          color: "#000000e6",
                          padding: "6px",
                          fontSize: "14px",
                          textAlign: "center",
                          paddingBottom: "0 !important",
                        }}
                      >
                        {`package – ${user.package}`}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            ))
          ) : (
            <Box
              sx={{
                padding: "16px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "black",
                }}
              >
                No User Listing Added Yet
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => ({
  connections: state.connections,
});

export default connect(mapStateToProps, {
  getUserListing,
  deleteUserListing,
})(ListCard);
