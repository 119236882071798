import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addExperience } from "../../actions/profile";
import {
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  TextareaAutosize,
} from "@mui/material";

const AddExperience = ({ addExperience, closeExperienceModal,}) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    company: "",
    title: "",
    location: "",
    from: "",
    to: "",
    current: false,
    description: "",
  });

  const { company, title, location, from, to, current, description } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const handleSubmit = (e) => {
    e.preventDefault();
    addExperience(formData, navigate);
  };
  return (
    <section style={{ padding: "20px" }}>
      <Typography variant="h4" sx={{ marginBottom: "1rem" }}>
        Add An Experience
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
        <i className="fas fa-code-branch" /> Add any developer/programming
        positions that you have had in the past
      </Typography>
      <Typography variant="body2" sx={{ color: "red" }}>
        * = required field
      </Typography>
      <form className="form" onSubmit={handleSubmit}>
        <TextField
          label="* Job Title"
          variant="outlined"
          fullWidth
          margin="normal"
          name="title"
          value={title}
          onChange={onChange}
          required
        />
        <TextField
          label="* Company"
          variant="outlined"
          fullWidth
          margin="normal"
          name="company"
          value={company}
          onChange={onChange}
          required
        />
        <TextField
          label="Location"
          variant="outlined"
          fullWidth
          margin="normal"
          name="location"
          value={location}
          onChange={onChange}
        />
        <div style={{ marginBottom: "1rem" }}>
          <Typography variant="h6">From Date</Typography>
          <TextField
            type="date"
            variant="outlined"
            fullWidth
            name="from"
            value={from}
            onChange={onChange}
          />
        </div>
        <div style={{ marginBottom: "1rem" }}>
          <FormControlLabel
            control={
              <Checkbox
                name="current"
                checked={current}
                onChange={() => setFormData({ ...formData, current: !current })}
              />
            }
            label="Current Job"
          />
        </div>
     
          <Typography variant="h6">To Date</Typography>
          
    
          <TextField
            type="date"
            variant="outlined"
            fullWidth
            name="to"
            value={to}
            onChange={onChange}
            disabled={current}
          />
              <div style={{display:"flex", alignItems: "center", marginTop:"1rem", flexDirection: "column"}}>
        <TextareaAutosize
          placeholder="Job Description"
          minRows={5}
          maxRows={10}
          style={{ width: "100%", padding: "10px", marginBottom: "1rem" }}
          name="description"
          value={description}
          onChange={onChange}
        />
        <div style={{marginleft: "1rem"}}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ color: "white", marginRight: "1rem" }}
        >
          Submit
        </Button>
        <Button variant="outlined" onClick={closeExperienceModal}>
          Go Back
        </Button>
        </div>

        </div>
      </form>
    </section>
  );
};

AddExperience.propTypes = {
  addExperience: PropTypes.func.isRequired,
  closeExperienceModal: PropTypes.func.isRequired,
};

export default connect(null, { addExperience })(AddExperience);
