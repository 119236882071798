import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Avatar,
  Button,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import SimpleDialog from "./Withdraw";
import { connect } from "react-redux";
import { getAllUsers, postConnectionRequest } from "../../actions/connections";

const PeopleCard = ({
  getAllUsers,
  users,
  list,
  postConnectionRequest,
  connections: { requestConnection },
}) => {
  const userId = localStorage.getItem("userId");

  // useEffect(() => {
  //   getAllUsers(userId);
  // }, [getAllUsers, userId]);

  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState({
    requestSender: userId,
    requestReceiver: "",
  });
  const { requestSender, requestReceiver } = formData;

  useEffect(() => {
    if (requestConnection.status === 200) {
      getAllUsers(userId);
    }
  }, [getAllUsers, requestConnection.status, userId, formData.requestReceiver]);
  // open
  const handleClickOpen = () => {
    setOpen(true);
  };
  // close
  const handleClose = (value) => {
    setOpen(false);
  };

  useEffect(() => {
    if (formData.requestReceiver !== "") {
      postConnectionRequest(requestSender, requestReceiver);
      setFormData({ ...formData, requestReceiver: "" });
    }
  }, [formData.requestReceiver]);

  const onSubmit = (receiverId) => {
    setFormData({ ...formData, requestReceiver: receiverId });
  };
  // const LIMIT = 2
  // const [list, setList] = useState(slice(users, 0, LIMIT))
  // const [index, setIndex] = useState(LIMIT);
  // const [showMore, setShowMore] = useState(true);
  // const loadMore = () => {
  //   const newIndex = index + LIMIT;
  //   const newShowMore = newIndex < (users?.length - 0);
  //   const newList = concat(list, slice(users, index, newIndex));
  //   setIndex(newIndex);
  //   setList(newList);
  //   setShowMore(newShowMore);
  // }
  return (
    <>
      {users &&
        users.slice(0, 5).map((user, index) => (
          <Card
            key={index}
            style={{
              minWidth: 185,
              maxWidth: 185,
              margin: "8px",
              borderRadius: "5px",
              boxShadow: "0px 0px 0px 2px rgb(0 0 0 / 10%)",
            }}
          >
            <a href={`/profile/${user.user?._id}`}>
              <CardContent
                sx={{
                  padding: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  minHeight: "200px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "62px",
                    background: "#A7A7A7",
                    marginBottom: "50px",
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "10px",
                  }}
                >
                  <Avatar
                    alt={user.firstname}
                    src={
                      user.avatar && user.avatar !== "empty"
                        ? `https://${
                            process.env.NODE_ENV === "development"
                              ? "localhost:8080"
                              : "picme.click:8080"
                          }/api/profile/photo/${user.avatar}`
                        : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
                    }
                    sx={{
                      height: "104px",
                      width: "104px",
                      textTransform: "capitalize",
                    }}
                  />
                </Box>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    fontWeight: "600",
                    color: "#000000e6",
                    padding: "6px",
                    fontSize: "16px",
                    textAlign: "center",
                    paddingBottom: "0 !important",
                    textTransform: "capitalize",
                  }}
                >
                  {user.firstname || ""} {user.lastname || ""}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    color: "#00000099",
                    padding: "6px",
                    fontSize: "14px",
                    textAlign: "center",
                    paddingTop: "0 !important",
                    paddingX: "12px",
                  }}
                >
                  {user.position} | {user.role} | {user.skills}
                </Typography>
              </CardContent>
            </a>
            <CardActions
              style={{
                justifyContent: "center",
                gap: "5px",
                flexDirection: "column",
                padding: "12px",
              }}
            >
              {/* <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  color: "#00000099",
                  fontSize: "12px",
                }}
              >
              
              </Typography> */}
              <Button
                size="small"
                sx={{
                  width: "100%",
                  background: "transparent ",
                  color: "#0a66c2",
                  // `${user.buttonText === "Connect" ? "#0a66c2" : "#00000099"
                  // }`,
                  border: "1px solid #0a66c2",
                  //  `1px solid ${user.buttonText === "Connect" ? "#0a66c2" : "#00000099"
                  //   }`,
                  borderRadius: "20px",
                  "& svg": {
                    fontSize: "20px",
                    fontWeight: 600,
                    color: "#0a66c2",
                    // `${user.buttonText === "Connect" ? "#0a66c2" : "#00000099"
                    //   }`,
                  },
                  "& span.MuiButton-startIcon": {
                    marginRight: "4px !important",
                  },
                  fontSize: "16px",
                  fontWeight: 600,
                }}
                startIcon={
                  <PersonAddAltIcon />
                  // <ScheduleIcon />,
                }
                onClick={() => onSubmit(user.user?._id)}
              >
                Connect
              </Button>
            </CardActions>
          </Card>
        ))}
      {list &&
        list.map((user, index) => (
          <Card
            key={index}
            style={{
              minWidth: 185,
              maxWidth: 185,
              margin: "8px",
              borderRadius: "5px",
              boxShadow: "0px 0px 0px 2px rgb(0 0 0 / 10%)",
            }}
          >
            <a href={`/profile/${user.user?._id}`}>
              <CardContent
                sx={{
                  padding: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  minHeight: "200px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "62px",
                    background: "#A7A7A7",
                    marginBottom: "50px",
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "10px",
                  }}
                >
                  <Avatar
                    alt={user.firstname}
                    src={user.profilePhoto}
                    sx={{
                      height: "104px",
                      width: "104px",
                      textTransform: "capitalize",
                    }}
                  />
                </Box>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    fontWeight: "600",
                    color: "#000000e6",
                    padding: "6px",
                    fontSize: "16px",
                    textAlign: "center",
                    paddingBottom: "0 !important",
                    textTransform: "capitalize",
                  }}
                >
                  {user.firstname || ""} {user.lastname || ""}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    color: "#00000099",
                    padding: "6px",
                    fontSize: "14px",
                    textAlign: "center",
                    paddingTop: "0 !important",
                    paddingX: "12px",
                  }}
                >
                  {user.position} | {user.role} | {user.skills}
                </Typography>
              </CardContent>
            </a>
            <CardActions
              style={{
                justifyContent: "center",
                gap: "5px",
                flexDirection: "column",
                padding: "12px",
              }}
            >
              {/* <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  color: "#00000099",
                  fontSize: "12px",
                }}
              >
              
              </Typography> */}
              <Button
                size="small"
                sx={{
                  width: "100%",
                  background: "transparent ",
                  color: "#0a66c2",
                  // `${user.buttonText === "Connect" ? "#0a66c2" : "#00000099"
                  // }`,
                  border: "1px solid #0a66c2",
                  //  `1px solid ${user.buttonText === "Connect" ? "#0a66c2" : "#00000099"
                  //   }`,
                  borderRadius: "20px",
                  "& svg": {
                    fontSize: "20px",
                    fontWeight: 600,
                    color: "#0a66c2",
                    // `${user.buttonText === "Connect" ? "#0a66c2" : "#00000099"
                    //   }`,
                  },
                  "& span.MuiButton-startIcon": {
                    marginRight: "4px !important",
                  },
                  fontSize: "16px",
                  fontWeight: 600,
                }}
                startIcon={
                  <PersonAddAltIcon />
                  // <ScheduleIcon />,
                }
                onClick={() => onSubmit(user.user?._id)}
              >
                Connect
              </Button>
            </CardActions>
          </Card>
        ))}
      {/* {showMore && <Button onClick={loadMore}> Load More </Button>} */}
      <SimpleDialog open={open} onClose={handleClose} />
    </>
  );
};

const mapStateToProps = (state) => ({
  connections: state.connections,
});

export default connect(mapStateToProps, { postConnectionRequest, getAllUsers })(
  PeopleCard
);
