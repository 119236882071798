import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Tab } from "@mui/material";

export const CustomTabs = ({ label, value, onChange, value1, sx, icon }) => {
  return (
    <TabContext value={value1}>
      <Box>
        <TabList
          onChange={(event, newValue) => onChange(newValue)}
          sx={{
            // "& span.MuiTabs-indicator": {
            //   display: "none",
            // },
            "& .MuiTabs-flexContainer": {
              display: "flex",
              gap: "10px !important",
            },
            alignItems: "center",
          }}
        >
          <Tab icon={icon} label={label} value={value} sx={sx} />
        </TabList>
      </Box>
    </TabContext>
  );
};
export const CustomTabsPanel = ({ value, children, sx, value1 }) => {
  return (
    <TabContext value={value1}>
      <TabPanel value={value} style={{ padding: "0 !important" }} sx={{
        "& .MuiTabPanel-root": {
          padding: "0 !important",
        }
      }}> {children}</TabPanel>
    </TabContext >
  );
};

export const CustomTab = ({ label, value, onChange, value1, sx, icon }) => {
  return (
    <TabContext value={value1}>
      <Box>
        <TabList
          onChange={(event, newValue) => onChange(newValue)}
          sx={{
            "& span.MuiTabs-indicator": {
              backgroundColor: "#0C345D",
            },
            "& .MuiTabs-flexContainer": {
              display: "flex",
              gap: "10px !important",
            },
            "& .MuiButtonBase-root": {
              fontSize: "12px",
              paddingX: "5px",
            },
            alignItems: "center",
          }}
        >
          <Tab icon={icon} label={label} value={value} sx={sx} />
        </TabList>
      </Box>
    </TabContext>
  );
};
