import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import PostItemMui from "../posts/PostItemMui";
//import CommentForm from './CommentForm';
import CommentFormMui from "./CommentFormMui";
import CommentItemMui from "./CommentItemMui";
import { getPost } from "../../actions/post";
import { Box, Button } from "@mui/material/";
import { getPosts } from "../../actions/post";
import { addLike, removeLike, deletePost } from "../../actions/post";
const Post = ({ getPost, getPosts, post: { post, loading } }) => {
  const { id } = useParams();
  useEffect(() => {
    getPost(id);
  }, [getPost, id]);

  return loading || post === null ? (
    <Spinner />
  ) : (
    <Box
      sx={{
        mx: "auto",
        mt: 14,
        maxWidth: 500,
        "& .MuiTextField-root": { mb: 2 },
        "& .MuiPaper-root": { mb: 2 },
        "& .MuiTypography-root": { mb: 1, mt: 1 },
        "& .MuiButton-root": { mb: 1, mt: 1, mr: 1 },
      }}
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      noValidate
      autoComplete="off"
      textAlign="center"
      justify="center"
    >
      <Button component={Link} to={"/posts"}>
        Back To Posts
      </Button>
      <PostItemMui
        post={post}
        showActions={false}
        postId={post._id}
        addLike={addLike}
        removeLike={removeLike}
        deletePost={deletePost}
        getPosts={getPosts}
      />
      {/*<CommentForm postId={post._id} />*/}
      <CommentFormMui postId={post._id} />
      {post.comments.map((comment) => (
        <CommentItemMui key={comment._id} comment={comment} postId={post._id} />
      ))}
    </Box>
  );
};
Post.propTypes = {
  getPost: PropTypes.func.isRequired,
  getPosts: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  post: state.post,
});

export default connect(mapStateToProps, { getPost, getPosts })(Post);
