import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function ProfileCard({
  profile: {
    // user: { _id, name, avatar },
    status,
    company,
    location,
    skills,
    profilePhoto,
    approved,
    lastName,
    _id,
    avatar,
    name,
  },
}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  function randomColor() {
    let hex = Math.floor(Math.random() * 0xffffff);
    let color = "#" + hex.toString(16);

    return color;
  }

  return (
    <Card key={_id}>
      <CardHeader
        avatar={
          <Avatar
            sx={{ bgcolor: randomColor() }}
            aria-label="recipe"
            src={`https://${process.env.NODE_ENV === "development"
              ? "localhost:8080"
              : "picme.click:8080"
              }/api/profile/photo/${approved && avatar !== "empty" ? profilePhoto : null
              }`}
            alt={lastName}
          >
            {/*name*/}
            {approved ? null : <CircularProgress />}
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={name}
        subheader={"highlights"}
      />
      {/* <CardMedia
                  component="img"
                  height="400"
                  image={`https://${
                    process.env.NODE_ENV === "development"
                      ? "localhost:8080"
                      : "picme.click:8080"
                  }/api/profile/photo/${post.profilePhoto}`}
                  alt={post.title}
                /> */}
      {/*
      <CardMedia
        component="img"
        height="194"
        image="/static/images/cards/paella.jpg"
        alt="Paella dish"
      />
      */}
      <CardContent sx={{
        paddingY: "16px"
      }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "16px"
          }}
        >
          <Typography
            variant="subtitle2"
            color="text.primary"

          >
            {"Status:"}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"

          >
            {status}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "16px"
          }}
        >
          <Typography
            variant="subtitle2"
            color="text.primary"

          >
            {"Skills:"}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"

          >
            {skills}
          </Typography>
        </Box>
      </CardContent>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Button variant="contained" href={`/profile/${_id}`} sx={{ margin: "0px !important" }}>
          View Profile
        </Button></Box>
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      {/*  
      <div className='profile bg-light'>
        <Avatar src={avatar} alt='' sx={{ width: 56, height: 56 }} />
        <div>
          <h2>{name}</h2>
          <p>
            {status} {company && <span> at {company}</span>}
          </p>
          <p className='my-1'>{location && <span>{location}</span>}</p>
          <Link to={`/profile/${_id}`} className='btn btn-primary'>
            View Profile
          </Link>
        </div>
        <ul>
          {skills.slice(0, 4).map((skill, index) => (
            <li key={index} className='text-primary'>
              <i className='fas fa-check' /> {skill}
            </li>
          ))}
        </ul>
      </div>
      */}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>Method:</Typography>
          <Typography paragraph>!!!!</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
