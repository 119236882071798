/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

//pages
import Navbar from "./components/layout/Navbar";
// //import Landing from "./components/layout/Landing";
// import LandingPage from "./components/layout/LandingPage";
// import Register from "./pages/auth/Register";
// import Login from "./pages/auth/Login";
import Alert from "./components/layout/Alert";
import Dashboard from "./pages/dashboard/Dashboard";
import ProfileForm from "./pages/profile-forms/ProfileForm";
import AddExperience from "./pages/profile-forms/AddExperience";
import AddEducation from "./pages/profile-forms/AddEducation";
import Profiles from "./components/profiles/Profiles";
import Profile from "./components/profile/Profile";
import Posts from "./components/posts/Posts";
import Post from "./components/post/Post";
// import NotFound from "./components/layout/NotFound";
import PrivateRoute from "./components/routing/PrivateRoute";
import { LOGOUT } from "./actions/types";
import SplashPage from "./pages/SplashPage";

// import Userpost from "./Userpost.js"

import LoginPage from "./pages/LoginPage";

// Redux
//import { useSelector } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";

import "./App.css";
import RegisterPage from "./pages/RegisterPage";
import HomePage from "./pages/HomePage";
// import { Users } from "./pages/users/Users";
import { Box } from "@mui/material";
import { SocialCircle } from "./pages/social-circle/SocialCircle";
import { ListPage } from "./pages/my-lists/List";
import ExperiencePart from "./pages/my-lists/ExperiencePart";
import ShowReel from "./pages/my-lists/ShowReel";
import CertificateList from "./pages/my-lists/CertificateList";
import { MediaPosts } from "./pages/media-section/MediaPosts";
import { MediaPhotos } from "./pages/media-section/MediaPhotos";
import { MediaVideos } from "./pages/media-section/MediaVideos";
import { MediaAbout } from "./pages/media-section/MediaAbout";
// import { Overview } from "./pages/media-section/Overview";
import UserList from "./pages/users/UserList";
import AboutUs from "./components/layout/AboutUs";
import { ContactsUser } from "./pages/users/ContactsUser";
import { InvitationPage } from "./pages/users/InvitationPage";
import AwardList from "./pages/my-lists/AwardList";
import { Admin } from "./pages/admin/Admin";
import { UserActivity } from "./pages/admin/user-activity/User-Activity";
import { PostListing } from "./pages/my-lists/PostListing";
import { AdminUserList } from "./pages/admin/user-list/User-List";

const App = () => {
  //const {appbar,cookies} = useSelector((state) => state);
  const [splashPage, setSplashPage] = useState(false);
  setTimeout(() => {
    if (!splashPage) setSplashPage(true);
  }, 500);

  useEffect(() => {
    // check for token in LS when app first runs
    if (localStorage.token) {
      // if there is a token set axios headers for all requests
      setAuthToken(localStorage.token);
      store.dispatch(loadUser());
    }
    // try to fetch a user, if no token or invalid token we
    // will get a 401 response from our API
    //store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener("storage", () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  return (
    <Router>
      <>
        <Navbar />
        <Box sx={{ marginTop: "88px" }}></Box>
        <Alert />
        {!splashPage ? <SplashPage /> : null}
        <Routes>
          {/* <Route path="/" element={<LandingPage />} /> */}
          {/* <Route path="/" element={<Posts />} /> */}
          {/* <Route path="/" element={<Userpost/>} /> */}
          {/* <Route path="/users" element={<Users />} /> */}
          {/* <Route path="/users" element={<UserList/>} /> */}
          {/* <Route path="/list" element={<ListPage />} />  */}
          {/* <Route path="/my-listing" element={<ListPage />} /> */}
          {/* <Route path="/Experience-part" element={<ExperiencePart />} /> */}
          {/* <Route path="/show-reel" element={<ShowReel />}/> */}
          {/* <Route path="/accolades-&-awards" element={<AccoladesAwards />} /> */}
          {/* <Route path="/mediaPosts" element={<MediaPosts/>}/> */}
          {/* <Route path="/mediaPhotos" element={<MediaPhotos/>}/> */}
          {/* <Route path="/mediaVideos" element={<MediaVideos/>}/> */}
          {/* <Route path="/connection" element={<SocialCircle />} /> */}
          <Route path="/contacts-user" element={<ContactsUser />} />
          {/* <Route path="/awardList" element={<AwardList/>}/> */}
          <Route path="/media-about" element={<MediaAbout />} />
          {/* <Route path="/overview" element={<Overview/>} /> */}
          <Route path="/about" element={<AboutUs />} />
          {/* <Route path="/register" element={<Register />} /> */}
          <Route path="/register" element={<RegisterPage />} />
          {/* <Route path="/login2" element={<Login />} /> */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<LoginPage />} />
          {/*
          <Route path="profiles" element={<Profiles />} />
          <Route path="profile/:id" element={<Profile />} />
         
          */}{" "}
          <Route path="users" element={<PrivateRoute component={UserList} />} />
          <Route
            path="/my-listing"
            element={<PrivateRoute component={ListPage} />}
          />
          <Route
            path="/post-listing"
            element={<PrivateRoute component={PostListing} />}
          />
          <Route
            path="/my-experience"
            element={<PrivateRoute component={ExperiencePart} />}
          />
          <Route
            path="/my-reel"
            element={<PrivateRoute component={ShowReel} />}
          />
          <Route
            path="/accolades-and-awards"
            element={<PrivateRoute component={CertificateList} />}
          />
          <Route
            path="/awardList"
            element={<PrivateRoute component={AwardList} />}
          />
          <Route
            path="/media-posts"
            element={<PrivateRoute component={MediaPosts} />}
          />
          <Route
            path="/media-videos"
            element={<PrivateRoute component={MediaVideos} />}
          />
          <Route
            path="/media-photos"
            element={<PrivateRoute component={MediaPhotos} />}
          />
          <Route
            path="/invitation"
            element={<PrivateRoute component={InvitationPage} />}
          />
          <Route
            path="/connection"
            element={<PrivateRoute component={SocialCircle} />}
          />
          <Route
            path="/contacts-user"
            element={<PrivateRoute component={ContactsUser} />}
          />
          <Route
            path="profiles"
            element={<PrivateRoute component={Profiles} />}
          />
          <Route
            path="profile/:id"
            element={<PrivateRoute component={Profile} />}
          />
          {/**    PrivateRoute    */}
          <Route
            path="dashboard"
            element={<PrivateRoute component={Dashboard} />}
          />
          <Route
            path="create-profile"
            element={<PrivateRoute component={ProfileForm} />}
          />
          <Route
            path="edit-profile"
            element={<PrivateRoute component={ProfileForm} />}
          />
          <Route
            path="add-experience"
            element={<PrivateRoute component={AddExperience} />}
          />
          <Route
            path="add-education"
            element={<PrivateRoute component={AddEducation} />}
          />
          <Route path="posts" element={<PrivateRoute component={Posts} />} />
          <Route path="posts/:id" element={<PrivateRoute component={Post} />} />
          <Route path="/admin" element={<PrivateRoute component={Admin} />} />
          <Route
            path="/admin-user-activity"
            element={<PrivateRoute component={UserActivity} />}
          />
          <Route
            path="/admin-user-list"
            element={<PrivateRoute component={AdminUserList} />}
          />
          {/* <Route path="/home" element={<HomePage />} /> */}
          <Route path="/home" element={<PrivateRoute component={HomePage} />} />
        </Routes>
      </>
    </Router>
  );
};

export default App;
