import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import profile from "./profile";
import post from "./post";
import agreements from "./agreements";
import cookies from "./cookies";
import appBarReducer from "./appBarReducer";
import admin from "./admin";
import connections from "./connections";

export default combineReducers({
  alert,
  auth,
  profile,
  post,
  agreements,
  cookies,
  appBarReducer,
  admin,
  connections,
});
