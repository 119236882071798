import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
// @mui
import { styled } from "@mui/material/styles";
import {
  Link,
  Container,
  Typography,
  Modal,
  Button,
  Box,
  Alert,
  Snackbar,
} from "@mui/material";
// hooks
import useResponsive from "../hooks/useResponsive";
// components
//import Logo from "../components/logo";
//import Iconify from "../components/iconify";
// sections
//import { LoginForm } from "../sections/auth/login";
import { Close as CloseIcon } from "@mui/icons-material";
import Login from "./auth/Login";
import Register from "./auth/Register";
import PicMeLogo from "../img/logo.png";

// ----------------------------------------------------------------------

import Cookies from "./Cookies";
import UserAgreement from "./agreements/UserAgreement";
import PrivacyPolicy from "./agreements/PrivacyPolicy";
import CookiePolicy from "./agreements/CookiePolicy";
import Slide from "@mui/material/Slide";

import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledSection = styled("div")(({ theme }) => ({
  //width: "100%",
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  //boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
  //padding: theme.spacing(12, 0),
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  //padding: theme.spacing(12, 0),
}));

const style = (theme) => ({
  position: "absolute",
  mb: 2,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  mt: 2,
  p: 4,
  minHeight: "80vh",
  maxHeight: "80vh",
  overflow: "hidden",
  overflowY: "scroll",
  borderRadius: "16px",
  [theme.breakpoints.down("xs")]: {
    width: 400,
  },
  [theme.breakpoints.down("sm")]: {
    width: 400,
  },
  [theme.breakpoints.up("md")]: {
    width: 400,
  },
  [theme.breakpoints.up("lg")]: {
    width: 800,
  },
  [theme.breakpoints.up("xl")]: {},
});

// ----------------------------------------------------------------------

function LoginPage({ auth: { userDetails } }) {
  const mdUp = useResponsive("up", "md");
  const [cookiesClicked, setCookiesClicked] = useState(false);
  const [userAgreement, setUserAgreement] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [cookiePolicy, setCookiePolicy] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const { agreements, cookies } = useSelector((state) => state);

  useEffect(() => {
    if (agreements[1] === "UserAgreement")
      agreements[0] ? setUserAgreement(true) : setUserAgreement(false);
    if (agreements[1] === "PrivacyPolicy")
      agreements[0] ? setPrivacyPolicy(true) : setPrivacyPolicy(false);
    if (agreements[1] === "CookiePolicy")
      agreements[0] ? setCookiePolicy(true) : setCookiePolicy(false);
  }, [agreements]);

  useEffect(() => {
    cookies[0] ? setCookiesClicked(true) : setCookiesClicked(false);
  }, [cookies]);

  // const handleClick = (newState) => () => {
  //   setState({ ...newState, open: true });
  // };

  return (
    <>
      <Helmet>
        <title> Login </title>
      </Helmet>
      {!cookiesClicked ? <Cookies /> : null}
      {userAgreement ? <UserAgreement /> : null}
      {privacyPolicy ? <PrivacyPolicy /> : null}
      {cookiePolicy ? <CookiePolicy /> : null}

      <StyledRoot>
        <Container maxWidth="sm">
          {mdUp && (
            <StyledSection>
              <Typography
                variant="h4"
                sx={{ ml: 20, mr: 20, mt: 15 }}
                align="center"
              >
                <img src={PicMeLogo} alt="login" loading="lazy" />{" "}
              </Typography>
              <Typography
                variant="h5"
                sx={{ px: 5, mt: 1, mb: 5 }}
                align="center"
              >
                Elevate your sports game! Create your epic sporting journey,
                showcasing your finest moments and achievements. Share your
                awesome videos, photos, and stats with the world. Unlock global
                recognition and open doors to exciting opportunities.
              </Typography>
            </StyledSection>
          )}
        </Container>

        <Container maxWidth="sm">
          <StyledContent>
            {!mdUp && (
              <>
                <Typography variant="h4" sx={{ mt: 15, mb: 2 }} align="center">
                  <img
                    src={`${PicMeLogo}?w=164&h=164&fit=crop&auto=format`}
                    //srcSet={`${PicMeLogo}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    alt="logo"
                    loading="lazy"
                  />
                  Manage your sporting profile, build your highlights and
                  successes. Share videos, images, and stats. Get worldwide
                  exposure and opportunities.
                </Typography>
              </>
            )}
            <Typography variant="h4" gutterBottom>
              Join the fastest growing sports community!
            </Typography>
            {/* {userDetails && (
              <Alert
                severity="error"
                sx={{ width: "100%", marginBottom: "10px" }}
              >
                {userDetails.message}
              </Alert>
            )} */}
            <Typography variant="body2" sx={{ mb: 5 }}>
              <Link variant="subtitle2">{""}</Link>
            </Typography>
            <Login openModal={openModal} />

            <Modal
              open={isModalOpen}
              onClose={closeModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Box display="flex" justifyContent="flex-end">
                  <Button onClick={closeModal}>
                    <CloseIcon />
                  </Button>
                </Box>
                <Register closeModal={closeModal} />
              </Box>
            </Modal>
          </StyledContent>
        </Container>
      </StyledRoot>
      {/*
      <TawkMessengerReact
        propertyId="658c4d9670c9f2407f83c060"
        widgetId="1him0u606" />
              */}
    </>
  );
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(LoginPage);
