/* eslint-disable react/jsx-no-undef */
import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Button, IconButton } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";

function UserPageWithdraw(props) {
  const { onClose, selectedValue, open, userId, deleteRequest } = props;
  const handleClose = () => {
    deleteRequest(userId);
    onClose(selectedValue);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        "& .MuiPaper-root.MuiPaper-elevation": { maxWidth: "336px" },
      }}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          color: "#000000E6",
          fontWeight: 600,
          fontSize: "18px",
        }}
        id="customized-dialog-title"
      >
        Withdraw Invitation
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        dividers
        sx={{
          padding: "16px !important",
        }}
      >
        <Typography
          gutterBottom
          sx={{
            color: "#000000E6",
            fontSize: "16px",
          }}
        >
          If you withdraw now, you won't be able to resend to this person for up
          tp 3 weeks.
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          padding: "12px 16px",
        }}
      >
        <Button
          onClick={handleClose}
          sx={{
            border: "1px solid #0A66C2",
            color: "#FFFFFF",
            background: "#0A66C2",
            fontSize: "16px",
            height: "32px",
            borderRadius: "16px",
            padding: " 0.6rem 1.2rem",
          }}
        >
          Withdraw
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UserPageWithdraw;
