import api from "../utils/api";
import { setAlert } from "./alert";

import {
  PROFILE_ERROR,
  GET_USER_LIST,
  GET_MY_CONNECTION_LIST,
  GET_MY_INVITATIONS_LIST,
  POST_CONNECTION_FAIL,
  POST_CONNECTION_REQUEST,
  PUT_INVITATIONS_REQUEST,
  DELETE_INVITATIONS_REQUEST,
  GET_CONTACT_LIST,
  GET_CONTACT_LIST_ERROR,
  GET_MY_CONNECTION,
  GET_STORIES_LIST,
  GET_USER_LISTING,
  GET_USER_LISTING_ERROR,
  POST_USER_LISTING,
  POST_USER_LISTING_ERROR,
  DELETE_USER_LISTING,
  DELETE_USER_LISTING_ERROR,
  PUT_USER_LISTING,
  PUT_USER_LISTING_ERROR,
  GET_ALL_USER_LIST,
} from "./types";
/*
  NOTE: we don't need a config object for axios as the
 default headers in axios are already Content-Type: application/json
 also axios stringifies and parses JSON for you, so no need for 
 JSON.stringify or JSON.parse
*/
// Get all AllUsers
export const getAllUsers = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`/users/list/${userId}`);
    dispatch({
      type: GET_ALL_USER_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};
// Get all AllUsers
export const getUsers = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`/users/list`);
    dispatch({
      type: GET_USER_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};
// Get all getMyConnections
export const getMyConnections = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`/connection/friend-list/${userId}`);
    dispatch({
      type: GET_MY_CONNECTION_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

// Get all getMyConnections
export const getMyConnectionsList = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`/connection/friend-list/${userId}`);
    dispatch({
      type: GET_MY_CONNECTION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

// getMyInvitations
export const getMyInvitations = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`/connection/received-request-list/${userId}`);
    dispatch({
      type: GET_MY_INVITATIONS_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};
// getStories
export const getStories = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`/connection/received-request-list/${userId}`);
    dispatch({
      type: GET_STORIES_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

//  send connection request
export const postConnectionRequest =
  (requestSender, requestReceiver) => async (dispatch) => {
    const body = { requestSender, requestReceiver };
    try {
      // Construct the URL with the connectionRequestId
      const url = `/connection/request-send`;

      // Send a POST request to the server
      const res = await api.post(url, body);
      dispatch({
        type: POST_CONNECTION_REQUEST,
        payload: res?.data,
      });
    } catch (err) {
      dispatch({
        type: POST_CONNECTION_FAIL,
        payload: {
          msg: err?.response?.statusText,
          status: err?.response?.status,
        },
      });
    }
  };
//  Accept Invitation request

export const putInvitationAccepted = (invitationId) => async (dispatch) => {
  try {
    const res = await api.put(`/connection/status-update/${invitationId}`);
    dispatch({
      type: PUT_INVITATIONS_REQUEST,
      payload: res?.data,
    });
  } catch (err) {
    dispatch({
      type: POST_CONNECTION_FAIL,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

export const deleteInvitationAccepted = (invitationId) => async (dispatch) => {
  try {
    const res = await api.delete(
      `/connection/remove-connection/${invitationId}`
    );
    dispatch({
      type: DELETE_INVITATIONS_REQUEST,
      payload: res?.data,
    });
  } catch (err) {
    dispatch({
      type: POST_CONNECTION_FAIL,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

// getMyContactList
export const getMyContactList = (contactId) => async (dispatch) => {
  try {
    const res = await api.get(`/connection/send-request-list/${contactId}`);
    dispatch({
      type: GET_CONTACT_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_CONTACT_LIST_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};

// Get USER Listing
export const getUserListing = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/listing/${id}`);
    dispatch({
      type: GET_USER_LISTING,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_USER_LISTING_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Post User Listing
export const postUserListing = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/listing", formData);
    console.log("response of post api :", res);
    dispatch({
      type: POST_USER_LISTING,
      payload: res.data,
    });
    dispatch(setAlert("User List Added ", "success"));
  } catch (err) {
    dispatch({
      type: POST_USER_LISTING_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};
// DELETE User Listing

export const deleteUserListing = (deleteUserListingId) => async (dispatch) => {
  try {
    const res = await api.delete(
      `/listing/delete-listing/${deleteUserListingId}`
    );
    dispatch({
      type: DELETE_USER_LISTING,
      payload: res?.data,
    });
    dispatch(setAlert("Delete User List", "success"));
  } catch (err) {
    dispatch({
      type: DELETE_USER_LISTING_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};
// PUT OR UPDATE User Listing
export const putUserListing = (formData) => async (dispatch) => {
  try {
    const res = await api.put(`/listing/update-listing`, formData);
    dispatch({
      type: PUT_USER_LISTING,
      payload: res?.data,
    });
  } catch (err) {
    dispatch({
      type: PUT_USER_LISTING_ERROR,
      payload: {
        msg: err?.response?.statusText,
        status: err?.response?.status,
      },
    });
  }
};
