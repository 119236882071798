import React, { useState, useEffect } from "react";
import Story from "./Story";
import "./StoryReels.css";
import { AddNewPostDialog } from "./Add-New-StoryReel";
import AddStories from "./AddStories";
import { UserStories } from "./User-Stories";

function StoryReel({
  userStoryDetails,
  userFriendStoryDetails,
  postUserStoriesDetails,
  userId,
  userStoriesPost,
  getUserStory,
  getUserFriendsStory,
}) {
  const [showModal, setShowModal] = useState(false);
  const closemodal = () => setShowModal(false);

  useEffect(() => {
    if (userStoriesPost.status === 200) {
      getUserStory(userId);
      getUserFriendsStory(userId);
      closemodal();
    }
  }, [userStoriesPost]);

  function isImage(url) {
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"]; // Add more image extensions if needed
    const extension = url && url.split(".").pop().toLowerCase();
    return imageExtensions.includes(extension);
  }

  function isVideo(url) {
    const videoExtensions = ["mp4", "webm", "ogg", "avi", "mov"]; // Add more video extensions if needed
    const extension = url && url.split(".").pop().toLowerCase();
    return videoExtensions.includes(extension);
  }
  const [open, setOpen] = useState(false);
  const [openReels, setOpenReels] = useState(false);
  const handleReelsOpen = (id) => {
    setOpenReels(id, true);
  };
  const handleUserReelsOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpenReels(false);
    setOpen(false);
  };
  if (!userStoryDetails?.story) {
    return null;
  }

  const image = isImage(
    userStoryDetails.story && userStoryDetails.story[0]?.image
  );
  const video = isVideo(
    userStoryDetails.story && userStoryDetails.story[0]?.image
  );

  return (
    <div className="storyReel">
      <AddStories setShowModal={setShowModal} />
      <div>
        {userStoryDetails.story.length !== 0 && (
          <Story
            handleReelsOpen={handleUserReelsOpen}
            image={
              userStoryDetails.story[0]?.image &&
              userStoryDetails.story[0]?.image !== "empty"
                ? `https://${
                    process.env.NODE_ENV === "development"
                      ? "localhost:8080"
                      : "picme.click:8080"
                  }/api/story/image/${userStoryDetails.story[0].image}`
                : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
            }
            title={userStoryDetails.story[0]?.profile?.firstname}
            profileSrc={
              userStoryDetails.story[0]?.profile?.avatar &&
              userStoryDetails.story[0].profile.avatar !== "empty"
                ? `https://${
                    process.env.NODE_ENV === "development"
                      ? "localhost:8080"
                      : "picme.click:8080"
                  }/api/profile/photo/${
                    userStoryDetails.story[0].profile?.avatar
                  }`
                : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
            }
            video={
              userStoryDetails.story[0]?.image &&
              userStoryDetails.story[0]?.image !== "empty"
                ? `https://${
                    process.env.NODE_ENV === "development"
                      ? "localhost:8080"
                      : "picme.click:8080"
                  }/api/story/video/${userStoryDetails.story[0].image}`
                : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
            }
            isImage={image}
            isVideo={video}
          />
        )}
        {open && (
          <UserStories
            onClose={handleClose}
            open={open}
            reel={userStoryDetails.story}
            isImage={isImage}
            isVideo={isVideo}
          />
        )}
      </div>
      {userFriendStoryDetails.filteredArray &&
        userFriendStoryDetails.filteredArray.map((story, index) => {
          const image = isImage(story[0].image);
          const video = isVideo(story[0].image);
          return (
            <div key={index}>
              <Story
                handleReelsOpen={() => handleReelsOpen(story[0]?._id)}
                image={
                  story[0]?.image && story[0]?.image !== "empty"
                    ? `https://${
                        process.env.NODE_ENV === "development"
                          ? "localhost:8080"
                          : "picme.click:8080"
                      }/api/story/image/${story[0].image}`
                    : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
                }
                title={story[0]?.profile?.firstname}
                profileSrc={
                  story[0]?.profile?.avatar &&
                  story[0].profile.avatar !== "empty"
                    ? `https://${
                        process.env.NODE_ENV === "development"
                          ? "localhost:8080"
                          : "picme.click:8080"
                      }/api/profile/photo/${story[0].profile?.avatar}`
                    : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
                }
                video={
                  story[0]?.image && story[0]?.image !== "empty"
                    ? `https://${
                        process.env.NODE_ENV === "development"
                          ? "localhost:8080"
                          : "picme.click:8080"
                      }/api/story/video/${story[0].image}`
                    : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
                }
                isImage={image}
                isVideo={video}
              />
              {openReels && (
                <UserStories
                  onClose={handleClose}
                  open={openReels}
                  reel={story}
                  isImage={isImage}
                  isVideo={isVideo}
                />
              )}
            </div>
          );
        })}
      {showModal && (
        <AddNewPostDialog
          onClose={closemodal}
          open={showModal}
          postUserStoriesDetails={postUserStoriesDetails}
          userId={userId}
          userStoriesPost={userStoriesPost}
        />
      )}
    </div>
  );
}
export default StoryReel;
