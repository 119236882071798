import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { postAwardDetails } from "../../actions/profile";
import PropTypes from "prop-types";
import { putAwardDetails } from "../../actions/profile";
import { Close } from "@mui/icons-material";
import { connect } from "react-redux";

const AwardsPopUp = ({
  postAwardDetails,
  getAwards,
  editedData,
  profile: { awardPost, awardDetailUpdate },
  userId,
  closeExperienceModal,
  putAwardDetails,
}) => {
  const [objectURL, setObjectURL] = useState("");
  const [uploadError, setUploadError] = useState(null);
  const [isEdited, setIsEdited] = useState(
    editedData.awardName !== undefined ? true : false
  );
  const fileInput = React.createRef();

  const [formData, setFormData] = useState({
    id: editedData ? editedData._id : "",
    awardName: editedData ? editedData.awardName : "",
    // companyName: editedData ? editedData.companyName : "",
    role: editedData ? editedData.role : "",
    date: editedData ? editedData.date : "",
    description: editedData ? editedData.description : "",
    awardImage: editedData ? editedData.certificateImage : null,
    user: userId,
  });
  const handleChange = (e) => {
    if (e.target.name === "awardImage") {
      setFormData({ ...formData, awardImage: e.target.files[0] });
      setObjectURL(URL.createObjectURL(e.target.files[0]));
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataWithImage = new FormData();

    if (editedData.action === "edit") {
      formDataWithImage.append("id", formData.id);
      formDataWithImage.append("awardName", formData.awardName);
      // formDataWithImage.append("companyName", formData.companyName);
      formDataWithImage.append("role", formData.role);
      formDataWithImage.append("date", formData.date);
      formDataWithImage.append("description", formData.description);
      formDataWithImage.append("awardImage", formData.awardImage);
      formDataWithImage.append("user", formData.user);
      putAwardDetails(formDataWithImage);
    } else {
      formDataWithImage.append("awardName", formData.awardName);
      // formDataWithImage.append("companyName", formData.companyName);
      formDataWithImage.append("role", formData.role);
      formDataWithImage.append("date", formData.date);
      formDataWithImage.append("description", formData.description);
      formDataWithImage.append("awardImage", formData.awardImage);
      formDataWithImage.append("user", formData.user);
      postAwardDetails(formDataWithImage);
    }
  };
  return (
    <Grid
      item
      xs={12}
      sx={{
        background: "#FFFFFF",
        borderRadius: "6px",
        paddingX: "20px",
        gap: "16px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: " space-between" }}>
        {" "}
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 600,
          }}
        >
          {editedData.action === "edit" ? "Edit Awards" : "Add Awards"}
        </Typography>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 600,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={closeExperienceModal}
            sx={{
              // position: "absolute",
              right: 0,
              top: 0,
              height: "25px",
              width: "25px",
            }}
          >
            <Close />
          </IconButton>
        </Typography>
      </Box>

      <form>
        <Box>
          <Grid
            item
            xs={12}
            sx={{
              background: "rgb(241 241 241 / 55%)",
              borderRadius: "6px",
              padding: "20px",
              gap: "16px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                paddingRight: "0px !important",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                gap: "20px",
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  paddingRight: "0px !important",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  position: "relative",
                  gap: "10px",
                  width: "100%",
                }}
              >
                {" "}
                <Typography
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  Award Name
                </Typography>
                <TextField
                  type="text"
                  placeholder=""
                  name="awardName"
                  height="30px"
                  fontSize="12px"
                  border="1px solid rgb(0 0 0 / 30%)"
                  value={formData.awardName}
                  onChange={handleChange}
                  sx={{
                    fontSize: "12px",
                    width: "100%",
                  }}
                />
              </Grid>
              {/* <Grid
                item
                xs={6}
                sx={{
                  paddingRight: "0px !important",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  position: "relative",
                  gap: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  Company Name
                </Typography>
                <TextField
                  type="text"
                  placeholder=""
                  name="companyName"
                  fontSize="12px"
                  border="1px solid rgb(0 0 0 / 30%)"
                  value={formData.companyName}
                  onChange={handleChange}
                />
              </Grid> */}
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                paddingRight: "0px !important",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                gap: "20px",
              }}
            >
              <Grid
                item
                xs={6}
                sx={{
                  paddingRight: "0px !important",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  position: "relative",
                  gap: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  Date
                </Typography>
                <TextField
                  type="date"
                  variant="outlined"
                  fullWidth
                  name="date"
                  fontSize={"12px"}
                  value={formData.date}
                  onChange={handleChange}
                />
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  paddingRight: "0px !important",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  position: "relative",
                  gap: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  Role
                </Typography>
                <TextField
                  type="text"
                  placeholder=""
                  fullWidth
                  name="role"
                  fontSize={"12px"}
                  value={formData.role}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                paddingRight: "0px !important",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                position: "relative",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                }}
              >
                Description
              </Typography>
              <TextField
                type="text"
                placeholder=""
                name="description"
                sx={{
                  fontSize: "12px",
                  width: "100%",
                }}
                value={formData.description}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                paddingRight: "0px !important",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                gap: "20px",
              }}
            >
              {" "}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mt: 3,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    padding: "10px",
                  }}
                >
                  Award Image
                </Typography>
                {objectURL !== "" ? (
                  <img
                    src={objectURL}
                    alt="Profile"
                    style={{
                      maxHeight: "200px",
                      maxWidth: "200px",
                      marginBottom: "8px",
                    }}
                  />
                ) : (
                  editedData.certificateImage && (
                    <img
                      alt={editedData.awardName}
                      src={
                        editedData.certificateImage &&
                        editedData.certificateImage !== "empty"
                          ? `https://${
                              process.env.NODE_ENV === "development"
                                ? "localhost:8080"
                                : "picme.click:8080"
                            }/api/awards/photo/${editedData.certificateImage}`
                          : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
                      }
                      style={{
                        maxHeight: "200px",
                        maxWidth: "200px",
                        marginBottom: "8px",
                      }}
                    />
                  )
                )}

                <Button variant="contained" component="label">
                  {!isEdited
                    ? `${objectURL !== "" ? "Image Uploaded" : "Upload Image"}`
                    : `${objectURL !== "" ? "Image Updated" : "Update Image"}`}
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    name="awardImage"
                    onChange={handleChange}
                    hidden
                    ref={fileInput}
                  />
                </Button>
                {uploadError && (
                  <Typography variant="body2" color="error" mt={1}>
                    {uploadError}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            my: 3,
          }}
        >
          <Button
            type="submit"
            variant="contained"
            style={{ marginRight: "10px" }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </form>
    </Grid>
  );
};

AwardsPopUp.propTypes = {
  postAwardDetails: PropTypes.func.isRequired,
  putAwardDetails: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  profile: state.profile,
});
export default connect(mapStateToProps, { postAwardDetails, putAwardDetails })(
  AwardsPopUp
);




