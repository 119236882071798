import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addComment } from "../../actions/post";
import { getPosts } from "../../actions/post";
import { getUserPost } from "../../actions/post";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Avatar,
  List,
  ListItem,
} from "@mui/material";
import moment from "moment";
import { useParams } from "react-router-dom";

const CommentFormMui = ({
  postId,
  addComment,
  post,
  getUserPost,
  getPosts,
}) => {
  const [text, setText] = useState("");
  const { id } = useParams();
  const userId = !id ? localStorage.getItem("userId") : id;
  localStorage.getItem("userId");
  const onSubmit = (e) => {
    e.preventDefault();
    addComment(post._id, { text });
    setText("");
    setTimeout(() => {
      getPosts();
      getUserPost(userId);
    }, 2000);
  };
  const {
    avatar: profileAvatar,
    _id: profileId,
    firstname,
    lastname,
  } = post.profile || {};
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "13px",
          padding: "17px 15px",
        }}
      >
        <Box
          sx={{
            height: 40,
            minWidth: 40,
            borderRadius: "6px",
            overflow: "hidden",
          }}
        >
          {/* <img
              src={image.path ? image.path : Avatar}
              height={40}
              width={40}
              alt="pic"
            /> */}
          <Avatar
            alt={""}
            src={
              profileAvatar && profileAvatar !== "empty"
                ? `https://${
                    process.env.NODE_ENV === "development"
                      ? "localhost:8080"
                      : "picme.click:8080"
                  }/api/profile/photo/${profileAvatar}`
                : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
            }
            title={`${firstname || "John"} ${lastname || "Deo"}`}
          />
        </Box>
        <Grid
          item
          xs={12}
          sx={{
            paddingRight: "0px !important",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          {/* <Editor
              toolbarHidden
              editorState={editorState}
              wrapperClassName="wrapper"
              toolbarClassName="toolbar-class"
              onEditorStateChange={onEditorStateChange}
              onChange={""}
              // mention={mentions}
              // hashtag={hashtags}
            /> */}
          <TextField
            label="Comment Text"
            multiline
            maxRows={4}
            value={text}
            onChange={(e) => {
              setText(e.target.value);
            }}
            required
            fullWidth
          />
        </Grid>
        <Button
          sx={{
            height: 40,
            width: 40,
            borderRadius: "8px",
            backgroundColor: "#0C345D",
            fontFamily: "Roboto-Regular",
            border: "1px solid #0C345D",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#FFFFFF",
              color: "#0C345D",
            },
          }}
          onClick={onSubmit}
          disabled={text === "" ? true : false}
        >
          Send
        </Button>
      </Box>
      {post.comments?.map((val) => {
        return (
          <List
            sx={{
              listStyle: "none",
              padding: "0",
            }}
          >
            <>
              <ListItem
                sx={{
                  fontSize: "12px",
                  color: "#0C345D",
                  paddingBottom: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  padding: "5px 75px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        height: 30,
                        width: 30,
                        borderRadius: "6px",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={
                          val.profile.avatar && val.profile.avatar !== "empty"
                            ? `https://${
                                process.env.NODE_ENV === "development"
                                  ? "localhost:8080"
                                  : "picme.click:8080"
                              }/api/profile/photo/${val.profile.avatar}`
                            : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
                        }
                        height={30}
                        width={30}
                        alt="pic"
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "7px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "#000000",
                        }}
                      >
                        {`${val.profile.firstname} ${val.profile.lastname}`}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "10px",
                          color: "#9E9E9E",
                        }}
                      >
                        (
                        {moment
                          .utc(val.date)
                          .local()
                          .startOf("seconds")
                          .fromNow()}
                        )
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ paddingLeft: "40px" }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#000000",
                    }}
                  >
                    {`${val.text}`}
                  </Typography>
                </Box>
              </ListItem>
            </>
          </List>
        );
      })}
    </Box>
  );
};

CommentFormMui.propTypes = {
  addComment: PropTypes.func.isRequired,
  getUserPost: PropTypes.func.isRequired,
  getPosts: PropTypes.func.isRequired,
};

export default connect(null, { getPosts, addComment, getUserPost })(
  CommentFormMui
);
