import api from "../utils/api";
import {
  APPROVE_POST,
  GET_PROFILE_PICTURE_POSTS,
  GET_REGULAR_POSTS,
  REJECT_POST,
  POST_ERROR,
  APPROVE_REGULAR_POST,
  REJECT_REGULAR_POST,
} from "./types";


export const approveRegularPost = (postId) => (dispatch) => {
  dispatch({
    type: APPROVE_REGULAR_POST,
    payload: postId,
  });
  api
    .put(`/posts/accept-post/${postId}`)
    .then((res) => {
      console.log("Post updated: ", res.data);
    })
    .catch((err) => {
      console.log("Failed to update Profile: ", err);
      dispatch({
        type: POST_ERROR,
        payload: {
          msg: err?.response?.statusText,
          status: err.response.status,
        },
      });
    });
};
export const approvePost = (postId) => (dispatch) => {
  dispatch({
    type: APPROVE_POST,
    payload: postId,
  });
  api
    .put(`/profile/accept-post/${postId}`)
    .then((res) => {
      console.log("Profile updated: ", res.data);
    })
    .catch((err) => {
      console.log("Failed to update Profile: ", err);
      dispatch({
        type: POST_ERROR,
        payload: {
          msg: err.response.statusText,
          status: err.response.status,
        },
      });
    });
};

export const rejectRegularPost = (postId) => (dispatch) => {
  dispatch({
    type: REJECT_REGULAR_POST,
    payload: postId,
  });
  api
    .delete(`/posts/delete-post/${postId}`)
    .then((res) => {
      console.log("Profile updated: ", res.data);
    })
    .catch((err) => {
      console.log("Failed to update Profile: ", err);
      dispatch({
        type: POST_ERROR,
        payload: {
          msg: err.response.statusText,
          status: err.response.status,
        },
      });
    });
};

export const rejectPost = (postId) => (dispatch) => {
  dispatch({
    type: REJECT_POST,
    payload: postId,
  });
  // Make an API call to update the Profile in the database
  api
    .put(`/profile/reject-post/${postId}`)
    .then((res) => {
      console.log("Profile updated: ", res.data);
    })
    .catch((err) => {
      console.log("Failed to update Profile: ", err);
      dispatch({
        type: POST_ERROR,
        payload: {
          msg: err.response.statusText,
          status: err.response.status,
        },
      });
    });
};

export const getProfilePicturePosts = (page, limit) => async (dispatch) => {
  try {
    const res = await api.get("/profile/approved", { params: { page, limit } });
    dispatch({
      type: GET_PROFILE_PICTURE_POSTS,
      payload: res.data,
    });
  } catch (err) {
    console.error(err.message);
  }
};

export const getRegularPosts = (page, limit) => async (dispatch) => {
  try {
    const res = await api.get("/posts/admin", { params: { page, limit } });
    dispatch({
      type: GET_REGULAR_POSTS,
      payload: res.data,
    });
  } catch (err) {
    console.log("it failed");
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
