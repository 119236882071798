import { Grid } from "@mui/material";
import React from "react";
import { SideBar } from "../users/Sidebar";
import Connection  from "./Connections";

export const SocialCircle = () => {
    return (
        <Grid
            container
            spacing={3}
            sx={{
                padding: "35px",
                paddingRight: "10px",
                paddingTop: "34px",
                gap: "20px",
                paddingLeft: "58px",
                justifyContent: "center",
            }}
        >
            <Grid item xs={2} sx={{ padding: "0 !important" }}>
                <SideBar />
            </Grid>
            <Grid
                item
                xs={6.35}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    padding: "0 !important",
                }}
            >
                <Grid item xs={12}>
                    <Connection />
                </Grid>
            </Grid>
        </Grid>
    );
};
