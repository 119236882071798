import { useLocation, useNavigate } from "react-router-dom";
import { List, ListItem } from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { connect } from "react-redux";
import { getCurrentProfile } from "../../actions/profile";
import { getUserListing } from "../../actions/connections";
import PropTypes from "prop-types";
import {
  SettingsNavListItemIcon,
  SettingsNavbarListItemButton,
  SettingsNavbarListItemText,
} from "../../components/list/settings-navbar-list";
import { useEffect } from "react";
const ListNavbar = ({
  getCurrentProfile,
  getUserListing,
  profile: { profile },
  connections: { userListDetail },
}) => {
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    getCurrentProfile();
    getUserListing(userId);
  }, [getCurrentProfile, getUserListing, userId]);
  const experience = profile && profile.experience;
  const userListing = userListDetail && userListDetail.list;
  const menus = [
    {
      title: "My Listings",
      route: "/my-listing",
      icon: <BookmarkIcon />,
      subTitle: `${userListing?.length}`,
    },
    {
      title: "Experience",
      route: "/my-experience",
      icon: <ListIcon />,
      subTitle: `${experience?.length}`,
    },
    {
      title: "Showreel",
      route: "/my-reel",
      icon: <VideoLibraryIcon />,
    },
    {
      title: "Certificates",
      route: "/accolades-and-awards",
      icon: <NewReleasesIcon />,
    },
    {
      title: "Awards",
      route: "/awardList",
      icon: <EmojiEventsIcon />,
    },
  ];

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <List>
      {menus.map((item, index) => (
        <ListItem key={`settings-nav-item-${index}}`} disablePadding>
          <SettingsNavbarListItemButton
            selected={location.pathname === item.route}
            onClick={() => navigate(item.route)}
          >
            <SettingsNavListItemIcon
              sx={{
                color: "inherit",
              }}
            >
              {item.icon}
            </SettingsNavListItemIcon>
            <SettingsNavbarListItemText
              primary={item.title}
              secondary={item.subTitle}
            />
          </SettingsNavbarListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

ListNavbar.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  connections: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  connections: state.connections,
});

export default connect(mapStateToProps, { getCurrentProfile, getUserListing })(
  ListNavbar
);
