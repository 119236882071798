import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCurrentProfile, deleteAccount } from "../../actions/profile";
import { Helmet } from "react-helmet-async";
import WhatsOnYourMind from "../../components/homepage/WhatsOnYourMind";
import NewFeeds from "../../components/homepage/NewFeeds";
import DashboardHeader from "../../components/personalAccount/DashboardHeader";
import UserProfile from "../../components/personalAccount/UserProfile";
import IntroCard from "../../components/personalAccount/IntroCard";
import FriendList from "../../components/personalAccount/FriendList";
import { Grid, Container, Typography, Button, Modal } from "@mui/material";
import AddExperience from "../profile-forms/AddExperience";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { postBioDetails } from "../../actions/profile";

import Box from "@mui/material/Box";
import { CustomTabs, CustomTabsPanel } from "../../components/tabs/custom-tabs";
import { tabMenu } from "../../utils/consts";
import PhotosAndVideos from "./Photos";
import { getUserPost } from "../../actions/post";

const Dashboard = ({
  getCurrentProfile,
  deleteAccount,
  auth: { user }, postBioDetails,
  profile: { profile , bioPost}, getUserPost, post: { userPosts , posts}
}) => {debugger
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    getCurrentProfile();
    getUserPost(userId);
  }, [getCurrentProfile, getUserPost, userId]);
  const [isExperiernceModalOpen, setIsExperienceModalOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // const experience = profile && profile.experience
  const openExperienceModal = () => {
    setIsExperienceModalOpen(true);
  };
  const closeExperienceModal = () => {
    setIsExperienceModalOpen(false);
  };
  useEffect(() => {
    if(bioPost?.status === 200) {
      getCurrentProfile()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bioPost]);

  const [value, setValue] = useState("posts");

  const handleChange = (event) => {
    setValue(event);
  };
  const dashboard = "dashboard";
  return (
    <Container sx={{ marginTop: 10, padding: "0 !important" }}>
      <Helmet>
        {/*
        <script
          async
          src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${"748650393078-b8o43mblb8i7bm9kbip16vggmoorum0s.apps.googleusercontent.com"}`}
          crossorigin="anonymous"
        />
        */}
      </Helmet>
      {profile !== null ? (
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ padding: "0 !important" }}>
            <DashboardHeader profile={profile} />
            <UserProfile profile={profile} deleteAccount={deleteAccount} />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              background: "#FFFFFF",
              boxShadow: "0px 3px 2px 0px rgb(0 0 0 / 10%)",
              borderRadius: "6px",
              padding: "15px !important",
              overflow: "auto",
              borderTopRightRadius: "0px !important",
              borderTopLeftRadius: "0px !important",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                maxHeight: "29px",
                width: "max-content",
              }}
            >
              {tabMenu.map((val, index) => (
                <>
                  <CustomTabs
                    label={val.label}
                    onChange={handleChange}
                    value={val.value}
                    value1={value}
                    key={index}
                  />
                </>
              ))}
            </Box>
          </Grid>
          <Box sx={{ width: "100% !important" }}>
            {tabMenu.map((tabs, index) => (
              <CustomTabsPanel
                value={tabs.value}
                value1={value}
                key={index}
                sx={{
                  "& .MuiTabPanel-root": {
                    paddingRight: "0px !important",
                  },
                }}
              >
                {value === "posts" && (
                  <Grid
                    container
                    spacing={6}
                    sx={{
                      marginLeft: "-24px !important",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={4}
                      sx={{
                        paddingLeft: "0 !important",
                      }}
                    >
                      <IntroCard
                        profile={profile}
                        postBioDetails={postBioDetails}
                        userId={userId}
                        openExperienceModal={openExperienceModal}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={8}
                      sx={{
                        paddingLeft: "24px !important",
                      }}
                    >
                      <WhatsOnYourMind profile={profile}/>
                      <NewFeeds posts={userPosts.post} getUserPost={getUserPost} userPosts={userPosts} userId={userId}/>
                    </Grid>
                  </Grid>
                )}
                {value === "friends" && <FriendList dashboard={dashboard} />}
                {value === "photoVideo" && <PhotosAndVideos showVideo={true} />}
                {value === "socialCircle" && <h4>socialCircle</h4>}
                {/* {value === "myListings" && <ListPage />}
                {value === "experience" && <ExperiencePart experience={experience} userId={userId} getCurrentProfile={getCurrentProfile} />}
                  {value === "showReel" && <ShowReel />}
                  {value === "certificates" && <CertificateList/>}
                  {value === "awards" && <AwardList />} */}
                {/* {value === "team" && <TeamList />} */}
              </CustomTabsPanel>
            ))}
          </Box>

          {/* <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <IntroCard
                  profile={profile}
                  openBioModal={openBioModal}
                  openExperienceModal={openExperienceModal}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <WhatsOnYourMind profile={profile} />
                <NewFeeds />
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      ) : (
        <Grid container spacing={3} marginTop={20}>
          <Grid item xs={12}>
            <Typography variant="h4">
              You have not yet set up a profile, please add some info
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              component={Link}
              to="/create-profile"
              variant="contained"
              color="primary"
            >
              Create Profile
            </Button>
          </Grid>
        </Grid>
      )}
      <Modal
        open={isExperiernceModalOpen}
        onClose={closeExperienceModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            borderRadius: "15px",
            padding: "12px",
            height: "80vh",
            overflowY: "auto",
            width: isSmallScreen ? "90%" : "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <AddExperience closeExperienceModal={closeExperienceModal} />

            {/* <Button sx={{ height: "20px" }} onClick={closeExperienceModal}>
                  <CloseIcon />
                </Button> */}
          </div>
        </div>
      </Modal>


    </Container>
  );
};

Dashboard.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  postBioDetails: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  getUserPost: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  post: state.post,
});

export default connect(mapStateToProps, { getCurrentProfile, deleteAccount, getUserPost, postBioDetails })(
  Dashboard
);
