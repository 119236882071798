import { SHOW_AGREEMENT, HIDE_AGREEMENT } from "./types";

export const showAgreement = (name) => (dispatch) => {
  dispatch({
    type: SHOW_AGREEMENT,
    payload: { name },
  });
};

export const hideAgreement = (name) => (dispatch) => {
  dispatch({
    type: HIDE_AGREEMENT,
    payload: { name },
  });
};
