import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ListSidebar } from "./ListSidebar";
import { ShowReelCard } from "./ShowReelCard";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getUserReels } from "../../actions/profile";
import { postUserReelDetails } from "../../actions/profile";
import { AddNewPostDialog } from "../../components/reels/Add-New-StoryReel";
import { PostSectionSideBar } from "./PostSectionSideBar";
import { deleteUserReelDetail } from "../../actions/profile";
const ShowReel = ({
  getUserReels,
  postUserReelDetails,
  deleteUserReelDetail,
  profile: { userReelDetails, userReelPost, userReelDetailDelete },
}) => {
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    getUserReels(userId);
  }, [getUserReels, userId]);
  const [showModal, setShowModal] = useState(false);
  const closemodal = () => setShowModal(false);

  useEffect(() => {
    if (userReelPost.status === 200) {
      getUserReels(userId);
      closemodal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReelPost]);
  return (
    <Grid
      container
      spacing={3}
      sx={{
        gap: "20px",
        padding: "24px",
        justifyContent: "center",
        paddingRight: "0px !important",
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          padding: "0px !important",
          flexWrap: "wrap",
        }}
      >
        <Grid item xs={2} sx={{ padding: "0 !important" }}>
          <ListSidebar />
          <PostSectionSideBar />
        </Grid>

        <Grid
          item
          xs={6.35}
          sx={{ display: "flex", flexWrap: "wrap", gap: "20px" }}
        >
          <ShowReelCard
            userReelDetail={userReelDetails}
            setShowModal={setShowModal}
            deleteUserReelDetail={deleteUserReelDetail}
            userReelDetailDelete={userReelDetailDelete}
            getUserReels={getUserReels}
            userId={userId}
          />
        </Grid>
      </Grid>
      {showModal && (
        <AddNewPostDialog
          onClose={closemodal}
          open={showModal}
          postUserReelDetails={postUserReelDetails}
          userReelPost={userReelPost}
          userId={userId}
          getUserReels={getUserReels}
        />
      )}
    </Grid>
  );
};
ShowReel.propTypes = {
  getUserReels: PropTypes.func.isRequired,
  postUserReelDetails: PropTypes.func.isRequired,
  deleteUserReelDetail: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  profile: state.profile,
});
export default connect(mapStateToProps, {
  getUserReels,
  postUserReelDetails,
  deleteUserReelDetail,
})(ShowReel);
