import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function SearchSelect(props) {
  const { value, onChange, name, list, placeholder } = props;

  return (
    <Autocomplete
      disablePortal
      id={name}
      options={list}
      value={value}
      /*sx={{ width: 300 }}*/
      //  defaultValue={props.defaultValueProp}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={name} placeholder={placeholder} />
      )}
    />
  );
}
