export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_BY_ID = "GET_PROFILE_BY_ID";
export const GET_PROFILES = "GET_PROFILES";
export const GET_REPOS = "GET_REPOS";
export const NO_REPOS = "NO_REPOS";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_COVER = "UPDATE_PROFILE_COVER";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const ACCOUNT_DELETED = "ACCOUNT_DELETED";
export const GET_POSTS = "GET_POSTS";
export const GET_POST = "GET_POST";
export const POST_ERROR = "POST_ERROR";
export const UPDATE_LIKES = "UPDATE_LIKES";
export const DELETE_POST = "DELETE_POST";
export const ADD_POST = "ADD_POST";
export const ADD_COMMENT = "ADD_COMMENT";
export const REMOVE_COMMENT = "REMOVE_COMMENT";
export const SHOW_AGREEMENT = "SHOW_AGREEMENT";
export const HIDE_AGREEMENT = "HIDE_AGREEMENT";
export const COOKIES_ACCEPTED = "COOKIES_ACCEPTED";
export const COOKIES_REJECTED = "COOKIES_REJECTED";
export const SHOW_APP_BAR = "SHOW_APP_BAR";
export const HIDE_APP_BAR = "HIDE_APP_BAR";
export const APPROVE_POST = "APPROVE_POST";
export const REJECT_POST = "REJECT_POST";
export const GET_PROFILE_PICTURE_POSTS = "GET_PROFILE_PICTURE_POSTS";
export const GET_REGULAR_POSTS = "GET_REGULAR_POSTS";
export const APPROVE_REGULAR_POST = "APPROVE_REGULAR_POST";
export const REJECT_REGULAR_POST = "REJECT_REGULAR_POST";
export const GET_USER_IMAGES = "GET_USER_IMAGES";
export const GET_USER_VIDEO = "GET_USER_VIDEO";

// Connections Type
export const GET_USER_LIST = "GET_USER_LIST";
export const GET_ALL_USER_LIST = "GET_ALL_USER_LIST";
export const POST_REQUEST_SEND = "POST_REQUEST_SEND";
export const GET_MY_INVITATIONS_LIST = "GET_MY_INVITATIONS_LIST";
export const GET_STORIES_LIST = "GET_STORIES_LIST";
export const GET_MY_CONNECTION_LIST = "GET_MY_CONNECTION_LIST";
export const POST_CONNECTION_REQUEST = "POST_CONNECTION_REQUEST";
export const POST_CONNECTION_FAIL = "POST_CONNECTION_FAIL";
export const PUT_INVITATIONS_REQUEST = "PUT_INVITATIONS_REQUEST";
export const PUT_INVITATIONS_ERROR = "PUT_INVITATIONS_ERROR";
export const DELETE_INVITATIONS_REQUEST = "DELETE_INVITATIONS_REQUEST";
export const GET_CONTACT_LIST = "GET_CONTACT_LIST";
export const GET_CONTACT_LIST_ERROR = "GET_CONTACT_LIST_ERROR";
export const GET_USER_POST = "GET_USER_POST";
export const GET_MY_CONNECTION = "GET_MY_CONNECTION";

export const GET_AWARDS_DETAILS = "GET_AWARDS_DETAILS";
export const GET_AWARDS_IMG = "GET_AWARDS_IMG";
export const POST_AWARDS_DETAILS = "POST_AWARDS_DETAILS";
export const PUT_AWARDS_DETAILS = "PUT_AWARDS_DETAILS";
export const DELETE_AWARDS_DETAILS = "DELETE_AWARDS_DETAILS";
export const GET_AWARDS_DETAILS_ERROR = "GET_AWARDS_DETAILS_ERROR";
export const POST_AWARDS_DETAILS_ERROR = "POST_AWARDS_DETAILS_ERROR";
export const PUT_AWARDS_DETAILS_ERROR = "PUT_AWARDS_DETAILS_ERROR";
export const DELETE_AWARDS_DETAILS_ERROR = "DELETE_AWARDS_DETAILS_ERROR";
export const GET_AWARDS_IMG_ERROR = "GET_AWARDS_IMG_ERROR";
export const POST_EXPERIENCE_DETAILS = "POST_EXPERIENCE_DETAILS";
export const POST_EXPERIENCE_DETAILS_ERROR = "POST_EXPERIENCE_DETAILS_ERROR";
export const DELETE_EXPERIENCE_DETAILS = "DELETE_EXPERIENCE_DETAILS";
export const DELETE_EXPERIENCE_DETAILS_ERROR = "DELETE_AWARDS_DETAILS_ERROR";
export const GET_CERTIFICATE_DETAILS = "GET_CERTIFICATE_DETAILS";
export const GET_CERTIFICATE_DETAILS_ERROR = "GET_CERTIFICATE_DETAILS_ERROR";
export const DELETE_CERTIFICATE_DETAILS = "DELETE_CERTIFICATE_DETAILS";
export const DELETE_CERTIFICATE_DETAILS_ERROR =
  "DELETE_CERTIFICATE_DETAILS_ERROR";
export const POST_CERTIFICATE_DETAILS = "POST_CERTIFICATE_DETAILS";
export const POST_CERTIFICATE_DETAILS_ERROR = "POST_CERTIFICATE_DETAILS_ERROR";
export const PUT_CERTIFICATE_DETAILS = "PUT_CERTIFICATE_DETAILS";
export const PUT_CERTIFICATE_DETAILS_ERROR = "PUT_CERTIFICATE_DETAILS_ERROR";
export const GET_USER_REELS_DETAILS = "GET_USER_REELS_DETAILS";
export const GET_USER_FRIENDS_REELS_DETAILS = "GET_USER_FRIENDS_REELS_DETAILS";
export const GET_USER_FRIENDS_REELS_DETAILS_ERROR =
  "GET_USER_FRIENDS_REELS_DETAILS_ERROR";
export const GET_USER_REELS_DETAILS_ERROR = "GET_USER_REELS_DETAILS_ERROR";
export const POST_USER_REELS_DETAILS = "POST_USER_REELS_DETAILS";
export const POST_USER_REELS_DETAILS_ERROR = "POST_USER_REELS_DETAILS_ERROR";
export const POST_USER_STORIES_DETAILS = "POST_USER_STORIES_DETAILS";
export const POST_USER_STORIES_DETAILS_ERROR =
  "POST_USER_STORIES_DETAILS_ERROR";
export const DELETE_USER_REELS_DETAILS = "DELETE_USER_REELS_DETAILS";
export const DELETE_USER_REELS_DETAILS_ERROR =
  "DELETE_USER_REELS_DETAILS_ERROR";
export const GET_USER_STORY_DETAILS = "GET_USER_STORY_DETAILS";
export const GET_USER_STORY_DETAILS_ERROR = "GET_USER_STORY_DETAILS_ERROR";
export const GET_USER_FRIENDS_STORY_DETAILS = "GET_USER_FRIENDS_STORY_DETAILS";
export const GET_USER_FRIENDS_STORY_DETAILS_ERROR = "GET_USER_FRIENDS_STORY_DETAILS_ERROR";
export const POST_BIO_DETAILS = "POST_BIO_DETAILS";
export const POST_ACTIVITY_DETAILS = "POST_ACTIVITY_DETAILS";
export const GET_USER_ACTIVITY_DETAILS = "GET_USER_ACTIVITY_DETAILS";
export const GET_USER_LISTING = "GET_USER_LISTING";
export const GET_USER_LISTING_ERROR = "GET_USER_LISTING_ERROR";
export const POST_USER_LISTING = "POST_USER_LISTING";
export const POST_USER_LISTING_ERROR = "POST_USER_LISTING_ERROR";
export const PUT_USER_LISTING = "PUT_USER_LISTING";
export const PUT_USER_LISTING_ERROR = "PUT_USER_LISTING_ERROR";
export const DELETE_USER_LISTING = "DELETE_USER_LISTING";
export const DELETE_USER_LISTING_ERROR = "DELETE_USER_LISTING_ERROR";
