import {
  GET_USER_LIST,
  GET_MY_CONNECTION_LIST,
  GET_MY_INVITATIONS_LIST,
  POST_CONNECTION_REQUEST,
  PUT_INVITATIONS_REQUEST,
  DELETE_INVITATIONS_REQUEST,
  GET_CONTACT_LIST,
  GET_MY_CONNECTION,
  GET_STORIES_LIST,
  GET_USER_LISTING,
  POST_USER_LISTING,
  DELETE_USER_LISTING,
  PUT_USER_LISTING,
  GET_ALL_USER_LIST,
} from "../actions/types";

const initialState = {
  users: [],
  myConnections: [],
  myConnectionsList: [],
  myInvitation: [],
  requestConnection: [],
  acceptInvitationRequest: {},
  rejectInvitationRequest: {},
  contactList: [],
  loading: true,
  userListDetail: [],
  userListPost: {},
  userListUpdate: {},
  userListDelete: {},
  error: {},
  stories: [],
};

function connectionsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_USER_LIST:
      return {
        ...state,
        users: payload,
        loading: false,
      };
    case GET_ALL_USER_LIST:
      return {
        ...state,
        users: payload,
        loading: false,
      };
    case GET_MY_CONNECTION_LIST:
      return {
        ...state,
        myConnections: payload,
        loading: false,
      };
    case GET_USER_LISTING:
      return {
        ...state,
        userListDetail: payload,
        loading: false,
      };
    case GET_MY_CONNECTION:
      return {
        ...state,
        myConnectionsList: payload,
        loading: false,
      };
    case GET_MY_INVITATIONS_LIST:
      return {
        ...state,
        myInvitation: payload,
        loading: false,
      };
    case GET_STORIES_LIST:
      return {
        ...state,
        stories: payload,
        loading: false,
      };
    case GET_CONTACT_LIST:
      return {
        ...state,
        contactList: payload,
        loading: false,
      };
    case POST_CONNECTION_REQUEST:
      return {
        ...state,
        requestConnection: payload,
      };
    case POST_USER_LISTING:
      return {
        ...state,
        userListPost: payload,
      };
    case PUT_INVITATIONS_REQUEST:
      return {
        ...state,
        acceptInvitationRequest: payload,
      };
    case PUT_USER_LISTING:
      return {
        ...state,
        userListUpdate: payload,
      };
    case DELETE_INVITATIONS_REQUEST:
      return {
        ...state,
        rejectInvitationRequest: payload,
      };
    case DELETE_USER_LISTING:
      return {
        ...state,
        userListDelete: payload,
      };
    default:
      return state;
  }
}

export default connectionsReducer;
