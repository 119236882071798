import React, { useState, useEffect } from "react";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { styled } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import { connect } from "react-redux";
import { getUsers } from "../../actions/connections";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { FilterSearchComponents } from "./searchbar/Filter-Search-Components";
import { Box, List, ListItemButton, ListItemText } from "@mui/material";

const SearchBar = ({ getUsers, connections: { users } }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const navigate = useNavigate();

  const handleOptionSelect = (event) => {
    if (event) {
      navigate(`/profile/${event.user?._id}`);
      setOpen(false);
    }
  };

  const applySearchFilters = (value) => {
    let filteredDataCopy = [...users];
    console.log(filteredDataCopy, "filteredDataCopy");
    if (value === "") {
      return setFilteredData([]);
    } else {
      filteredDataCopy = filteredDataCopy.filter((user) => {
        console.log(user);
        if (!user) {
          return false;
        }
        const firstName = `${user.firstname} ${user.lastname}`;
        const lowerSearchValue = value.toLowerCase();
        return firstName.toLowerCase().includes(lowerSearchValue);
      });
      setFilteredData(filteredDataCopy);
    }
    if (filteredDataCopy?.length !== 0) {
      setOpen(true);
    }
  };
  console.log(filteredData, "filteredData");
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <FilterSearchComponents
        searchTitle={"Search..."}
        isEmpty={true}
        onSearchChange={applySearchFilters}
      />
      {open && filteredData?.length !== 0 && (
        <div
          style={{
            position: "absolute",
            zIndex: 999,
            background: "white",
            boxShadow: "0px 2px 20px 0px #0000003d",
            borderRadius: "8px",
            top: "50px",
            left: "24px",
            width: " 230px",
          }}
        >
          <List>
            {filteredData?.map((user) => (
              <ListItemButton
                key={user._id}
                onClick={() => handleOptionSelect(user)}
              >
                <ListItemText>
                  {user.firstname} {user.lastname}
                </ListItemText>
              </ListItemButton>
            ))}
          </List>
        </div>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  connections: state.connections,
});

export default connect(mapStateToProps, { getUsers })(SearchBar);
