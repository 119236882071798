import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
//import PostItem from './PostItem';
import PostItemMui from "./PostItemMui";
import PostFormMui from "./PostFormMui";
import { getPosts } from "../../actions/post";
import { Box, Typography, Button, Grid } from "@mui/material";
import { getCurrentProfile } from "../../actions/profile";
import { addLike, removeLike, deletePost } from "../../actions/post";

const Posts = ({
  getCurrentProfile,
  profile: { profile },
  getPosts,
  post: { posts },
}) => {
  const [showModal, setShowModal] = useState(false);

  const closemodal = () => setShowModal(false);

  useEffect(() => {
    getCurrentProfile();
    getPosts();
  }, [getPosts, getCurrentProfile]);

  return (
    <Grid
      container
      spacing={3}
      sx={{
        justifyContent: "center",
        padding: "35px",
      }}
    >
      <Box
        sx={{
          "& .MuiTextField-root": { mb: 2 },
          "& .MuiPaper-root": { mb: 2 },
          "& .MuiTypography-root": { mb: 1, mt: 1 },
          "& .MuiButton-root": { mb: 1, mt: 1, mr: 1 },
        }}
        justifyContent="center"
        alignItems="center"
        noValidate
        autoComplete="off"
        textAlign="center"
        justify="center"
      >
        <Grid item xs={12} sx={{}}>
          <Typography variant="h3" align="center">
            Posts
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              Welcome to the community
            </Typography>
            <Typography variant="h6" align="center">
              <Button onClick={() => setShowModal(true)} variant="contained">
                New post
              </Button>
            </Typography>{" "}
          </Box>

          {showModal && <PostFormMui closemodal={closemodal} />}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            width: "100%",
            gap: "16px",
            justifyContent: "center",
          }}
        >
          {posts.map((post) => (
            <PostItemMui
              key={post._id}
              post={post}
              showActions={true}
              postId={post._id}
              addLike={addLike}
              removeLike={removeLike}
              deletePost={deletePost}
              getPosts={getPosts}
            />
          ))}
        </Grid>
      </Box>
    </Grid>
  );
};
Posts.propTypes = {
  getPosts: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  post: state.post,
  profile: state.profile,
});

export default connect(mapStateToProps, { getPosts, getCurrentProfile })(Posts);
