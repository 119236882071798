import { Box, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export const SearchInput = ({
  size = "medium",
  variant = "filled",
  placeholder = "Search",
  height,
  width,
  onChange,
}) => {
  const containerStyle = {
    backgroundColor: variant === "filled" ? "#E0E0E0 " : "transparent",
    borderRadius: size === "medium" ? "6px" : "8px",
    border: variant === "filled" ? "none" : `1px solid '#E9EEF2'}`,
    height: size === "medium" ? 40 : height ? { height } : 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: size === "medium" ? 2 : 1.5,
  };

  const inputStyle = {
    paddingX: size === "medium" ? 1.5 : 1.5,
    fontSize: size === "medium" ? 16 : 10,
  };

  return (
    <Box sx={containerStyle}>
      <SearchIcon />
      <InputBase
        placeholder={placeholder}
        sx={inputStyle}
        onChange={onChange}
      />
    </Box>
  );
};
