import React from "react";
import { Box, Typography } from "@mui/material";
import PostItemMui from "../posts/PostItemMui";
import { addLike, removeLike, deletePost } from "../../actions/post";

const NewFeeds = (props) => {
  const { posts, ProfileProp } = props;

  return (
    <>
      <Box
        sx={{
          background: "#FFFFFF",
          boxShadow: "0px 3px 2px 0px rgb(0 0 0 / 10%)",
          padding: "16px",
          borderRadius: ProfileProp ? "12px" : "6px",
          marginY: "16px",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          marginTop: ProfileProp && "0 !important",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 600,
          }}
        >
          News Feeds
        </Typography>
      </Box>
      {!posts || posts?.length === 0 ? (
        <Typography variant="body1" color="text.secondary">
          No feeds to display.
        </Typography>
      ) : (
        posts &&
        posts.map((post) => (
          <PostItemMui
            key={post._id}
            post={post}
            showActions={true}
            addLike={addLike}
            removeLike={removeLike}
            deletePost={deletePost}
          />
        ))
      )}
    </>
  );
};

export default NewFeeds;
