import {
  Box,
  Card,
  CardContent,
  Typography,
  Divider,
  Button,
  Grid,
} from "@mui/material";
import React, { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

export const ShowReelCard = ({
  userReelDetail,
  setShowModal,
  deleteUserReelDetail,
  userReelDetailDelete,
  getUserReels,
  userId,
}) => {
  const deleteRequest = (receiverId) => {
    deleteUserReelDetail(receiverId);
  };
  useEffect(() => {
    if (userReelDetailDelete.status === 200) {
      getUserReels(userId);
    }
  }, [userReelDetailDelete]);
  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          background: "#FFFFFF",
          boxShadow: "0px 0px 0px 2px rgb(0 0 0 / 10%)",
          borderRadius: "0.8rem",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            padding: "15px 20px",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: "#000000",
            }}
          >
            ShowReel
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="inherit"
              style={{
                marginBottom: "16px",
                background: "#e4e6eb",
                fontSize: "14px",
                fontWeight: 600,
              }}
              onClick={() => setShowModal(true)}
            >
              <AddIcon />
            </Button>
          </Box>
        </Box>
        <Divider width={"100%"} />
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            padding: "15px",
            paddingBottom: "25px",
          }}
        >
          {userReelDetail?.reels &&
            userReelDetail?.reels.map((user, index) => {
              return (
                <Card
                  key={index}
                  style={{
                    width: "200px",
                    marginLeft: "10px",
                    marginTop: "10px",
                    borderRadius: "5px",
                    marginRight: "0px!important",
                    marginBottom: "0px!important",
                  }}
                >
                  <CardContent
                    sx={{
                      padding: " 0 !important",
                      height: "200px",
                      width: "200px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "self-end",
                      }}
                    >
                      <Button
                        sx={{
                          fontSize: "12px",
                          color: "none",
                          border: "none",
                          borderRadius: "20px",
                          minWidth: "0px",
                        }}
                        onClick={() => deleteRequest(user._id)}
                      >
                        <DeleteIcon />
                      </Button>
                    </Box>

                    {(() => {
                      const fileExtension = user?.image
                        ?.split(".")
                        .pop()
                        .toLowerCase();
                      if (
                        fileExtension === "jpg" ||
                        fileExtension === "jpeg" ||
                        fileExtension === "png"
                      ) {
                        return (
                          <img
                            key={index}
                            src={
                              user.image && user.image !== "empty"
                                ? `https://${
                                    process.env.NODE_ENV === "development"
                                      ? "localhost:8080"
                                      : "picme.click:8080"
                                  }/api/reels/image/${user.image}`
                                : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
                            }
                            alt={`${index}`}
                            style={{
                              width: "100%",
                              height: "200px",
                              objectFit: "cover",
                            }}
                          />
                        );
                      } else if (
                        fileExtension === "mp4" ||
                        fileExtension === "webm" ||
                        fileExtension === "ogg"
                      ) {
                        return (
                          <video
                            key={index}
                            controls
                            style={{
                              width: "100%",
                              height: "200px",
                              objectFit: "cover",
                            }}
                          >
                            <source
                              src={
                                user.image && user.image !== "empty"
                                  ? `https://${
                                      process.env.NODE_ENV === "development"
                                        ? "localhost:8080"
                                        : "picme.click:8080"
                                    }/api/reels/video/${user.image}`
                                  : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
                              }
                              type={`video/${fileExtension}`}
                            />
                            Your browser does not support the video tag.
                          </video>
                        );
                      } else {
                        return (
                          <img
                            key={index}
                            src={
                              "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
                            }
                            alt={user.profile.firstname}
                          />
                        );
                      }
                    })()}
                  </CardContent>
                </Card>
              );
            })}
        </Box>
      </Grid>
    </>
  );
};
