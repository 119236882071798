import React, { useState, useEffect } from "react";
import Story from "./Story";
import "./StoryReels.css";
import { AddNewPostDialog } from "./Add-New-StoryReel";
import AddReel from "./AddReel";
import { UserReels } from "./User-Reels";

function ReelHome({
  getUserReels,
  userReelDetails,
  userFriendsReelDetails,
  postUserReelDetails,
  userId,
  userReelPost,
  getUserFriendsReels,
}) {
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);

  useEffect(() => {
    if (userReelPost.status === 200) {
      getUserReels(userId);
      getUserFriendsReels(userId);
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReelPost]);

  function isImage(url) {
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"]; // Add more image extensions if needed
    const extension = url && url.split(".").pop().toLowerCase();
    return imageExtensions.includes(extension);
  }

  function isVideo(url) {
    const videoExtensions = ["mp4", "webm", "ogg", "avi", "mov"]; // Add more video extensions if needed
    const extension = url && url.split(".").pop().toLowerCase();
    return videoExtensions.includes(extension);
  }
  const [open, setOpen] = useState(false);
  const [openReels, setOpenReels] = useState(false);
  const handleReelsOpen = (id) => {
    setOpenReels(id, true);
  };
  const handleUserReelsOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpenReels(false);
    setOpen(false);
  };
  const image = isImage(
    userReelDetails?.reels[0] && userReelDetails?.reels[0].image
  );
  const video = isVideo(
    userReelDetails?.reels[0] && userReelDetails?.reels[0].image
  );
  return (
    <div className="storyReel">
      <AddReel setShowModal={setShowModal} />
      <div>
        {userReelDetails.reels.length !== 0 && (
          <Story
            handleReelsOpen={handleUserReelsOpen}
            image={
              userReelDetails?.reels[0]?.image &&
              userReelDetails?.reels[0]?.image !== "empty"
                ? `https://${
                    process.env.NODE_ENV === "development"
                      ? "localhost:8080"
                      : "picme.click:8080"
                  }/api/reels/image/${userReelDetails.reels[0].image}`
                : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
            }
            title={userReelDetails?.reels[0]?.profile?.firstname}
            profileSrc={
              userReelDetails?.reels[0]?.profile?.avatar &&
              userReelDetails?.reels[0]?.profile?.avatar !== "empty"
                ? `https://${
                    process.env.NODE_ENV === "development"
                      ? "localhost:8080"
                      : "picme.click:8080"
                  }/api/profile/photo/${
                    userReelDetails?.reels[0].profile?.avatar
                  }`
                : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
            }
            video={
              userReelDetails?.reels[0]?.image &&
              userReelDetails?.reels[0]?.image !== "empty"
                ? `https://${
                    process.env.NODE_ENV === "development"
                      ? "localhost:8080"
                      : "picme.click:8080"
                  }/api/reels/video/${userReelDetails?.reels[0]?.image}`
                : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
            }
            isImage={image}
            isVideo={video}
          />
        )}

        {open && (
          <UserReels
            onClose={handleClose}
            open={open}
            reel={userReelDetails.reels}
            isImage={isImage}
            isVideo={isVideo}
          />
        )}
      </div>

      {userFriendsReelDetails.friendsReels &&
        userFriendsReelDetails.friendsReels.map((reel, index) => {
          const image = isImage(reel[0].image);
          const video = isVideo(reel[0].image);
          return (
            <div key={index}>
              <Story
                handleReelsOpen={() => handleReelsOpen(reel[0]._id)}
                image={
                  reel?.image && reel?.image !== "empty"
                    ? `https://${
                        process.env.NODE_ENV === "development"
                          ? "localhost:8080"
                          : "picme.click:8080"
                      }/api/reels/image/${reel[0].image}`
                    : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
                }
                title={reel[0]?.profile?.firstname}
                profileSrc={
                  reel[0]?.profile?.avatar && reel[0].profile.avatar !== "empty"
                    ? `https://${
                        process.env.NODE_ENV === "development"
                          ? "localhost:8080"
                          : "picme.click:8080"
                      }/api/profile/photo/${reel[0].profile?.avatar}`
                    : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
                }
                video={
                  reel[0]?.image && reel[0]?.image !== "empty"
                    ? `https://${
                        process.env.NODE_ENV === "development"
                          ? "localhost:8080"
                          : "picme.click:8080"
                      }/api/reels/video/${reel[0].image}`
                    : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
                }
                isImage={image}
                isVideo={video}
              />
              {openReels && (
                <UserReels
                  onClose={handleClose}
                  open={openReels}
                  reel={reel}
                  isImage={isImage}
                  isVideo={isVideo}
                />
              )}
            </div>
          );
        })}
      {showModal && (
        <AddNewPostDialog
          onClose={closeModal}
          open={showModal}
          postUserReelDetails={postUserReelDetails}
          userReelDetails={userReelDetails}
          userFriendsReelDetails={userFriendsReelDetails}
          userId={userId}
          userReelPost={userReelPost}
        />
      )}
    </div>
  );
}

export default ReelHome;
