import { Grid } from "@mui/material";
import React from "react";
import { AdminSidebar } from "../AdminSidebar";
import UserListTable from "./User-List-Table";

export const AdminUserList = () => {
  return (
    <Grid
      container
      spacing={3}
      sx={{
        gap: "20px",
        padding: "24px",
        justifyContent: "center",
        paddingRight: "0 !important",
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          padding: "0px !important",
        }}
      >
        <Grid item xs={2} sx={{ padding: "0 !important" }}>
          <AdminSidebar />
        </Grid>
        <Grid
          item
          xs={6.35}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          <UserListTable />
        </Grid>
      </Grid>
    </Grid>
  );
};
