import { Grid, Modal } from "@mui/material";
import React, { useState, useEffect } from "react";
import { CertificateCard } from "./CertificateCard";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CertificatesPopUp from "./CertificatesPopup";
import { ListSidebar } from "./ListSidebar";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { PostSectionSideBar } from "./PostSectionSideBar";
import { getCertificate } from "../../actions/profile";
import { deleteCertificateDetail } from "../../actions/profile";

const CertificateList = ({
  getCertificate,
  deleteCertificateDetail,
  profile: { certificatePost, certificateDetails, certificateDetailDelete },
}) => {
  const userId = localStorage.getItem("userId");
  const [isExperiernceModalOpen, setIsExperienceModalOpen] = useState(false);
  const theme = useTheme();
  const [editedData, setEditedData] = useState({});
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    getCertificate(userId);
  }, [getCertificate, userId]);

  const closeExperienceModal = () => {
    setIsExperienceModalOpen(false);
    setEditedData({});
  };
  useEffect(() => {
    if (certificatePost.status === 200) {
      getCertificate(userId);
      closeExperienceModal();
    }
  }, [certificatePost]);

  const openExperienceModal = () => {
    setIsExperienceModalOpen(true);
  };

  return (
    <>
      {" "}
      <Grid
        container
        spacing={3}
        sx={{
          gap: "20px",
          padding: "24px",
          justifyContent: "center",
          paddingRight: "0px !important",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "20px",
            padding: "0px !important",
          }}
        >
          <Grid item xs={2} sx={{ padding: "0 !important" }}>
            <ListSidebar />
            <PostSectionSideBar/>
          </Grid>

          <Grid
            item
            xs={6.35}
            sx={{ display: "flex", flexWrap: "wrap", gap: "20px" }}
          >
            <CertificateCard
              openExperienceModal={openExperienceModal}
              getCertificate={getCertificate}
              certificateDetails={certificateDetails}
              setEditedData={setEditedData}
              deleteCertificateDetail={deleteCertificateDetail}
              certificateDetailDelete={certificateDetailDelete}
              userId={userId}
            />
          </Grid>
        </Grid>
        <Modal
          open={isExperiernceModalOpen}
          onClose={closeExperienceModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              borderRadius: "15px",
              padding: "12px",
              height: "55h",
              overflowY: "auto",
              width: isSmallScreen ? "90%" : "600px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <CertificatesPopUp
                closeExperienceModal={closeExperienceModal}
                getCertificate={getCertificate}
                userId={userId}
                editedData={editedData}
                certificateDetailDelete={certificateDetailDelete}
              />
            </div>
          </div>
        </Modal>
      </Grid>
    </>
  );
};
CertificateList.propTypes = {
  getCertificate: PropTypes.func.isRequired,
  deleteCertificateDetail: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  profile: state.profile,
});
export default connect(mapStateToProps, {
  getCertificate,
  deleteCertificateDetail,
})(CertificateList);
