import { v4 as uuIdv4 } from "uuid";
import { SET_ALERT, REMOVE_ALERT } from "./types";

export const setAlert =
  (msg, alertType, missingFields = [], timeout = 5000) =>
  (dispatch) => {
    const id = uuIdv4();
    dispatch({
      type: SET_ALERT,
      payload: { msg, alertType, missingFields, id },
    });

    console.log("Alert dispatched");
    setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
  };
