export const tabMenu = [
  { label: "Posts", value: "posts" },
  { label: "Friends", value: "friends" },
  { label: "Photo/Video", value: "photoVideo" },
  // { label: "Social Circle", value: "socialCircle" },
  // { label: "My Listings", value: "myListings" },
  // { label: "Experience", value: "experience" },
  // { label: "Reel", value: "showReel" },
  // { label: "Certificates", value: "certificates" },
  // { label: "Awards", value: "awards" },
  // { label: "My Team", value: "team" },

];
