import { Grid } from "@mui/material";
import { ListSidebar } from "./ListSidebar";
import ExperienceCard from "./ExperienceCard";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ExperiencePop from "./ExperiencePop";
import { PostSectionSideBar } from "./PostSectionSideBar";

import { connect } from "react-redux";
import { getCurrentProfile } from "../../actions/profile";

const ExperiencePart = ({
  getCurrentProfile,
  auth: { user },
  profile: { profile, experiencePost },
}) => {
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    getCurrentProfile();
  }, [getCurrentProfile, userId]);

  const [isExperiernceModalOpen, setIsExperienceModalOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [editedData, setEditedData] = useState({});
  const openExperienceModal = () => {
    setIsExperienceModalOpen(true);
  };
  const closeExperienceModal = () => {
    setIsExperienceModalOpen(false);
    setEditedData({});
  };
  useEffect(() => {
    if (experiencePost.status === 200) {
      getCurrentProfile();
      closeExperienceModal();
    }
  }, [experiencePost]);
  const experience = profile && profile.experience;
  return (
    <>
      <Grid
        container
        spacing={3}
        sx={{
          padding: "24px",
          gap: "20px",
          justifyContent: "center",
          paddingRight: "0px !important",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            padding: "0px !important",
          }}
        >
          <Grid item xs={2} sx={{ padding: "0 !important" }}>
            <ListSidebar />
            <PostSectionSideBar />
          </Grid>

          <Grid
            item
            xs={6.35}
            sx={{ display: "flex", flexWrap: "wrap", gap: "20px" }}
          >
            <ExperienceCard
              openExperienceModal={openExperienceModal}
              experience={experience}
              userId={userId}
              setEditedData={setEditedData}
              getCurrentProfile={getCurrentProfile}
            />
          </Grid>
        </Grid>{" "}
        <Modal
          open={isExperiernceModalOpen}
          onClose={closeExperienceModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              borderRadius: "15px",
              padding: "12px",
              width: isSmallScreen ? "90%" : "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                overflow: "auto",
                maxHeight: "85vh",
              }}
            >
              <ExperiencePop
                closeExperienceModal={closeExperienceModal}
                userId={userId}
                getCurrentProfile={getCurrentProfile}
                editedData={editedData}
              />

              {/* <Button sx={{ height: "20px" }} onClick={closeExperienceModal}>
            <CloseIcon />
          </Button> */}
            </div>
          </div>
        </Modal>
      </Grid>
    </>
  );
};

ExperiencePart.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, { getCurrentProfile })(ExperiencePart);
