import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addPost } from "../../actions/post";
import {
  Box,
  Card,
  FormControl,
  Typography,
  TextField,
  Input,
  Button,
  Alert,
  Paper,
  Avatar,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Close from "@mui/icons-material/Close";
import UploadFile from "@mui/icons-material/UploadFile";

const PostFormMui = ({ addPost, profile, closemodal }) => {
  const [text, setText] = useState("");
  const [postImage, setUpPostImage] = useState(null);

  // const [showModal, setShowModal] = useState(true); // State to manage modal visibility
  const [errorMessage, setErrorMessage] = useState("");

  const mediaUrl = useMemo(() => {
    if (!postImage) {
      return null;
    }
    // Create the media URL and return it
    return URL.createObjectURL(postImage);
  }, [postImage]);
  useEffect(() => {
    // document.body.style.overflowY = "hidden";
    // return () => {
    //   document.body.style.overflowY = "scroll";
    // };
  }, []);

  const myStyle = {
    width: "100%",
  };
  const container = {
    maxWidth: "1424px",
    position: "fixed",
    margin: "auto",
    width: "100%",
    overflow: "hidden",
    padding: "0 2rem",
    left: "0",
    bottom: "0",
    right: "0",
    top: "0px",
    backgroundColor: "rgb(189,189,189,0.9)",
    zIndex: 10,
  };
  const borderStyle = {
    border: "1px solid #ddd",
    borderRadius: "4px",
    padding: "10px",
    width: "42%",
    margin: "auto",
    marginBottom: "10px",
    right: "29%",
    position: "fixed",
    top: "16%",
    background: "white",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
    transitionProperty: "none",
    transitionDuration: " 5s",
    height: "82vh",
  };

  const submitForm = (e) => {
    const MAX_IMAGE_SIZE_MB = 1; // Max image size in megabytes
    const MAX_VIDEO_SIZE_MB = 30; // Max video size in megabytes
    e.preventDefault();
    setErrorMessage("");
    // Check the file size based on type (image or video)
    if (postImage) {
      const maxSize =
        postImage.type.startsWith("image") || postImage.type.startsWith("video")
          ? postImage.type.startsWith("image")
            ? MAX_IMAGE_SIZE_MB
            : MAX_VIDEO_SIZE_MB
          : null;

      if (maxSize && postImage.size / 1024 / 1024 > maxSize) {
        setErrorMessage(
          `File size exceeds the maximum allowed size of ${maxSize} MB.`
        ); // Set error message
        return;
      }
    }
    const user = localStorage.getItem("userId");
    const formData = new FormData();
    formData.append("text", text);
    formData.append("user", user);
    postImage && formData.append("postImage", postImage);

    addPost(formData);
    setText("");
    setUpPostImage(null);
    closemodal(); // Call the closemodal function after submitting the form
  };

  // if (!showModal) {
  //   return null; // Render nothing if showModal is false
  // }
  const renderSelectedMedia = () => {
    if (!postImage) {
      return null;
    }
    return (
      <div>
        {postImage.type.startsWith("image") ? (
          <img
            src={mediaUrl}
            alt="Selected_Image"
            style={{
              maxWidth: "100%",
              maxHeight: "300px",
              borderRadius: "15px",
            }}
          />
        ) : postImage.type.startsWith("video") ? (
          <video
            controls
            src={mediaUrl}
            style={{
              maxWidth: "100%",
              maxHeight: "300px",
              borderRadius: "15px",
            }}
          />
        ) : null}
      </div>
    );
  };

  return (
    <Paper elevation={3} sx={{ padding: 2, maxWidth: "600px", margin: "auto" }}>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        noValidate
        autoComplete="off"
        onSubmit={submitForm}
      >
        {errorMessage && (
          <Alert severity="error" sx={{ width: "100%", marginBottom: "10px" }}>
            {errorMessage}
          </Alert>
        )}

        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={2}
          width="100%"
          marginBottom="10px"
        >
          <Avatar
            alt="User Avatar"
            src={
              profile?.avatar && profile.avatar !== "empty"
                ? `https://${process.env.NODE_ENV === "development"
                  ? "localhost:8080"
                  : "picme.click:8080"
                }/api/profile/photo/${profile?.avatar}`
                : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
            }
            sx={{ width: 40, height: 40, }}
          />
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            {profile?.firstname || ""}{" "}{profile?.lastname || ""}
          </Typography>
        </Box>
        <FormControl fullWidth variant="standard">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={2}
            width="100%"
            marginBottom="10px"
          >
            {/* Text Field for Post */}
            <TextField
              sx={{ width: "100%" }}
              id="outlined-multiline-flexible"
              placeholder={`What's on your mind ${profile?.firstname || ""}?`}
              multiline
              variant="outlined"
              rows={4}
              maxRows={4}
              defaultValue={text}
              onChange={(e) => setText(e.target.value)}
            />
          </Box>
          {renderSelectedMedia()}
          {/* Upload Button */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <IconButton
              variant="contained"
              component="label"
              sx={{ marginRight: "10px" }}
            >
              <UploadFile />
              <input
                type="file"
                hidden
                onChange={(e) => setUpPostImage(e.target.files[0])}
              />
            </IconButton>
            <Box>
              <IconButton
                aria-label="close"
                sx={{ position: "absolute", top: 5, right: 5 }}
                onClick={closemodal}
              >
                <Close />
              </IconButton>
              <Button variant="contained" type="submit">
                Post
              </Button>
            </Box>
          </Box>
        </FormControl>
      </Box>
    </Paper>
  );
};

PostFormMui.propTypes = {
  addPost: PropTypes.func.isRequired,
};

export default connect(null, { addPost })(PostFormMui);
