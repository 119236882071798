import * as React from "react";
import Box from "@mui/material/Box";
import BgImage from "../img/bg.jpg";

export default function SplashPage() {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        backgroundImage: `url(${BgImage})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "cover",
      }}
    />
  );
}
