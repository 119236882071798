import { useLocation, useNavigate } from "react-router-dom";
import { List, ListItem } from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import PendingActionsIcon from '@mui/icons-material/PendingActions';

import {
  SettingsNavListItemIcon,
  SettingsNavbarListItemButton,
  SettingsNavbarListItemText,
} from "../list/settings-navbar-list";
export const SettingsNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <List>
      {menus.map((item, index) => (
        <ListItem key={`settings-nav-item-${index}}`} disablePadding>
          <SettingsNavbarListItemButton
            selected={location.pathname === item.route}
            onClick={() => navigate(item.route)}
          >
            <SettingsNavListItemIcon
              sx={{
                color: "inherit",
              }}
            >
              {item.icon}
            </SettingsNavListItemIcon>
            <SettingsNavbarListItemText
              primary={item.title}
              secondary={item.subTitle}
            />
          </SettingsNavbarListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

const menus = [
  {
    title: "Invitations",
    route: "/invitation",
    icon: <InsertInvitationIcon />,
    // subTitle: "530",
  },
  {
    title: "Connections",
    route: "/connection",
    icon: <GroupIcon />,
  },
  {


    title: "Pending Request List",
    route: "/contacts-user",
    icon: <PendingActionsIcon/>,
    // subTitle: "573",
  }
];
