import { COOKIES_ACCEPTED, COOKIES_REJECTED } from "./types";

export const cookiesAccepted = () => (dispatch) => {
  dispatch({
    type: COOKIES_ACCEPTED,
  });
};

export const cookiesRejected = () => (dispatch) => {
  dispatch({
    type: COOKIES_REJECTED,
  });
};
