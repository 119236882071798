import React, { useEffect } from "react";
import store from "../store";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { styled, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
//import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";
import { grey } from "@mui/material/colors";
import CookiesButtons from "./CookiesButtons";
//import { cookiesAccepted, cookiesRejected } from "../actions/cookies";

import { showAgreement } from "../actions/agreements";

const Item = styled("div")(({ theme }) => ({
  ...theme.typography.h3,
  padding: theme.spacing(2),
  color: "#FFFFFF",
}));

const theme = createTheme();

theme.typography.h4 = {
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.6rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "0.6rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "0.8rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "0.8rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "0.8rem",
  },
};

export default function Cookies() {
  const agreements = useSelector((state) => state.agreements);
  function handleClick(e) {
    store.dispatch(showAgreement("CookiePolicy"));
  }
  useEffect(() => {
  }, [agreements]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        position: "fixed",
        bottom: "0",
        width: "100vw",
        height: "auto",
        zIndex: 1101,
        backgroundColor: grey[400],
        opacity: [0.9, 0.9, 0.9],
        "&:hover": {
          backgroundColor: grey[500],
          opacity: [1.0, 1.0, 1.0],
        },
        [theme.breakpoints.down("sm")]: {
          bottom: 0,
        },
      }}
    >
      <Grid
        container
        spacing={{ xs: 1, md: 1 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={0.5} sm={2} md={1} textAlign="right">
          <Item>
            {" "}
            <InfoIcon />
          </Item>
        </Grid>

        <Grid item xs={3.5} sm={6} md={11} textAlign="left">
          <Item>
            {" "}
            <Typography variant="h4" theme={theme}>
              Pic Me and 3rd parties use essential and non-essential cookies to
              provide, secure, analyze, and improve our Services, and to show
              you relevant ads (including professional and job ads) on and off
              Pic Me. Learn more in our{" "}
              <Link onClick={handleClick}>Cookie Policy</Link>. Select Accept to
              consent or Reject to decline non-essential cookies for this use.
              You can update your choices at any time in your{" "}
              <Link>settings</Link>.
            </Typography>
            <CookiesButtons />
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
