import * as React from "react";
import store from "../store";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import { cookiesAccepted, cookiesRejected } from "../actions/cookies";

export default function CookiesButtons() {
  return (
    <Stack spacing={2} direction="row" sx={{ mt: 1 }}>
      <Button
        onClick={() => {
          store.dispatch(cookiesAccepted());
        }}
        variant="contained"
      >
        Accept
      </Button>
      <Button
        onClick={() => {
          store.dispatch(cookiesRejected());
        }}
        variant="outlined"
      >
        Reject
      </Button>
    </Stack>
  );
}
