import { Grid, Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { getUserActivity } from "../../../actions/profile";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { useEffect } from "react";

const columns = [
  {
    field: "jj",
    headerName: "",
    width: 26,
    minWidth: 10,
  },
  {
    field: "name",
    headerName: "User Name",
    flex: 1,
  },
  {
    field: "email",
    headerName: "E-mail",
    flex: 1,
  },
  {
    field: "role",
    headerName: "Role",
    flex: 1,
  },
  {
    field: "dateOfBirth",
    headerName: "Date of Birth",
    flex: 1,
  },
  {
    field: "gender",
    headerName: "Gender",
    flex: 1,
  },
];
const UserListTable = ({
  getUserActivity,
  profile: { userActivityDetails, activityPost },
}) => {
  useEffect(() => {
    getUserActivity();
    if (activityPost?.status === 200) {
      getUserActivity();
    }
  }, [getUserActivity, activityPost]);
  if (!userActivityDetails.activity) {
    return null;
  }
  const rows = userActivityDetails.activity
    .slice()
    .reverse()
    .map((item) => ({
      id: item._id,
      email: item.email,
      description: item.description,
    }));
  const data = [
    {
      id: "1",
      name: "Uddam kumar",
      email: "shiv2gmail.com",
      role: "manager",
      dateOfBirth: "19-09-2001",
      gender: "male",
      image:
        "https://staticimg.amarujala.com/assets/images/2023/06/21/whatsapp-dp_1687343435.jpeg",
    },
    {
      id: "2",
      name: "Ankit sharma",
      email: "Sharmaankit@gmail.com",
      role: "Senior Developer",
      dateOfBirth: "19-09-2001",
      gender: "male",
      image:
        "https://play-lh.googleusercontent.com/C9CAt9tZr8SSi4zKCxhQc9v4I6AOTqRmnLchsu1wVDQL0gsQ3fmbCVgQmOVM1zPru8UH",
    },
    {
      id: "3",
      name: "Ajay Saini",
      email: "ajaysaini@gmail.com",
      role: "Junior Developer",
      dateOfBirth: "19-09-2001",
      gender: "male",
      image:
        "https://cdn.statusqueen.com/dpimages/thumbnail/Stylish_boy_dp-3855.jpg",
    },
  ];
  return (
    <>
      {userActivityDetails.activity !== 0 ? (
        <Grid
          item
          xs={12}
          sx={{
            background: "#FFFFFF",
            boxShadow: "0px 0px 0px 2px rgb(0 0 0 / 10%)",
            borderRadius: "0.8rem",
            display: "flex",
            flexDirection: "column",
            paddingLeft: "0px !important",
            paddingTop: "24px",
            marginTop: "0",
            height: "85vh",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              minWidth: {
                lg: "auto",
                md: "1100px",
                sm: "1100px",
                xs: "1100px",
              },
            }}
          >
            <Box
              sx={{
                paddingX: "24px !important",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#0C345D",
                  fontWeight: 600,
                }}
              >
                Users List
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              maxHeight: "717px !important",
              minHeight: "400px",
              width: "100%",
            }}
          >
            <DataGrid
              rows={data || []}
              columns={columns.map((col) => ({
                ...col,
                renderCell: (params) =>
                  col.field === "name" ? (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#000000",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        cursor: "pointer",
                      }}
                      //   onClick={() => handleClickEditOpen(params.row, "view")}
                    >
                      <Typography
                        sx={{
                          overflow: "hidden",
                          borderRadius: "100px",
                          width: "40px",
                          height: "40px",
                        }}
                      >
                        <img
                          src={params.row.image}
                          height={40}
                          width={40}
                          alt="ProfilePicture"
                        />
                      </Typography>

                      {params.value}
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        fontSize: "12px",
                      }}
                    >
                      {params.value}
                    </Typography>
                  ),

                sortable: false,
              }))}
              disableColumnSelector
              disableDensitySelector
              disableColumnFilter
              disableColumnMenu
              hideFooter
              pageSizeOptions={[]}
              sx={{
                border: 0,
                paddingY: 2,
                "& .MuiDataGrid-main": {
                  minWidth: {
                    lg: "auto",
                    md: "1100px",
                    sm: "1100px",
                    xs: "1100px",
                  },
                },
              }}
            />
          </Box>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};
UserListTable.propTypes = {
  getUserActivity: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  profile: state.profile,
});
export default connect(mapStateToProps, { getUserActivity })(UserListTable);
