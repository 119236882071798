import * as React from "react";
import store from "../../store";
import { hideAgreement } from "../../actions/agreements";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

export default function UserAgreement() {
  return (
    <Box
      sx={{
        position: "fixed",
        display: "flex",
        flexWrap: "wrap",
        "& > :not(style)": {
          m: 5,
        },
        zIndex: 15,
      }}
      onClick={() => store.dispatch(hideAgreement("UserAgreement"))}
    >
      <Paper elevation={3}>
        {" "}
        <Typography variant="h4" gutterBottom>
          User Agreement
        </Typography>
        <Typography variant="h5" gutterBottom>
          Our mission is to connect the world’s professionals to allow them to
          be more productive and successful. Our services are designed to
          promote economic opportunity for our members by enabling you and
          millions of other professionals to meet, exchange ideas, learn, and
          find opportunities or employees, work, and make decisions in a network
          of trusted relationships.
        </Typography>
      </Paper>
    </Box>
  );
}
