import React from "react";
import { Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Register from "./auth/Register";
import PicMeLogo from "../img/logo.png";

const StyledRoot = styled("div")(({ theme }) => ({
  margin: "auto",
  [theme.breakpoints.up("md")]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
}));

const StyledSection = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "column",
  minHeight: "100vh",
  backgroundColor: theme.palette.background.default,
  "@media (max-width: 960px)": {
    display: "none", // Hide on medium screens
  },
  "@media (max-width: 600px)": {
    display: "none", // Hide on small screens
  },
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  marginTop: "40px",
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "column",
  minHeight: "100vh",
  //padding: theme.spacing(12, 0),
}));

const RegisterPage = () => {
  return (
    <Container>
      <StyledRoot>
        {/* Left Section */}
        <StyledSection>
          <img
            src={PicMeLogo}
            alt="login"
            loading="lazy"
            style={{ width: "100px", height: "100px", alignSelf: "center" }}
          />

          <Typography variant="h5" sx={{ px: 5, mt: 1, mb: 5 }} align="center">
            Take your sports performance to new heights! Craft your
            extraordinary athletic adventure. Display your standout moments and
            accomplishments. Share compelling videos, captivating photos, and
            impressive stats with a global audience. Gain widespread recognition
            and open doors to thrilling opportunities. Elevate your sporting
            journey and leave a lasting impact!
          </Typography>
        </StyledSection>

        {/* Right Section */}
        <StyledContent>
          <Register />
        </StyledContent>
      </StyledRoot>
    </Container>
  );
};

export default RegisterPage;
