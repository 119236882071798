// MUI
import { Avatar } from "@mui/material";
import "./Story.css";
import React from "react";
function Story({ image, profileSrc, title, isImage, isVideo, video,handleReelsOpen }) {
  return (
    <div className="story" onClick={handleReelsOpen}>
      {!isVideo && (
        <div className="story__video-container">
          <div
            style={{ backgroundImage: `url(${image})` }}
            className="story__image"
          />
        </div>
      )}
      {isVideo && (
        <div className="story__video-container">
          <video autoPlay loop muted className="story__video">
            <source
              src={video}
              type={`video/${video.split(".").pop().toLowerCase()}`}
            />
            Your browser does not support the video tag.
          </video>
        </div>
      )}{" "}
      <Avatar className="story__avatar" src={profileSrc} />
      <h4>{title}</h4>
    </div>
  );
}

export default Story;
