import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Avatar,
  Button,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { connect } from "react-redux";
import { getMyConnectionsList } from "../../actions/connections";

const FriendAndProfileCard = ({
  getMyConnectionsList,
  connections: { myConnectionsList },
  myConnections,
  dashboard,
}) => {
  const id = localStorage.getItem("userId");
  useEffect(() => {
    const userId = localStorage.getItem("userId");
    getMyConnectionsList(userId);
  }, [getMyConnectionsList]);


  const list = myConnectionsList.list;
  return (
    <>
      {myConnections.list.length !== 0 ? (
        myConnections.list.map((user, index) => {
          const isId = user.friend.user !== id;
          return (
            <Card
              key={index}
              style={{
                minWidth: 185,
                maxWidth: 185,
                margin: "8px",
                borderRadius: "5px",
                boxShadow: "0px 0px 0px 2px rgb(0 0 0 / 10%)",
              }}
            >
              {" "}
              <a href={`/profile/${user.friend.user}`}>
                <CardContent
                  sx={{
                    padding: 0,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    minHeight: "200px",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "62px",
                      background: "#A7A7A7",
                      marginBottom: "50px",
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "10px",
                    }}
                  >
                    <Avatar
                      alt={user.friend.profileId.firstname}
                      src={
                        user.friend.profileId.avatar &&
                        user.friend.profileId.avatar !== "empty"
                          ? `https://${
                              process.env.NODE_ENV === "development"
                                ? "localhost:8080"
                                : "picme.click:8080"
                            }/api/profile/photo/${user.friend.profileId.avatar}`
                          : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
                      }
                      sx={{
                        height: "104px",
                        width: "104px",
                        textTransform: "capitalize",
                      }}
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      fontWeight: "600",
                      color: "#000000e6",
                      padding: "6px",
                      fontSize: "16px",
                      textAlign: "center",
                      paddingBottom: "0 !important",
                      textTransform: "capitalize",
                    }}
                  >
                    {user.friend.profileId.firstname || ""}{" "}
                    {user.friend.profileId.lastname || ""}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      color: "#00000099",
                      padding: "6px",
                      fontSize: "14px",
                      textAlign: "center",
                      paddingTop: "0 !important",
                      paddingX: "12px",
                    }}
                  >
                    {user.friend.profileId.playingPosition} |{" "}
                    {user.friend.profileId.role} |{" "}
                    {user.friend.profileId.skills}
                  </Typography>
                </CardContent>
              </a>
              <CardActions
                style={{
                  justifyContent: "center",
                  gap: "5px",
                  flexDirection: "column",
                  padding: "12px",
                }}
              >
                {/* <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  color: "#00000099",
                  fontSize: "12px",
                }}
              >
              
              </Typography> */}
                {isId ? (
                  list?.some((users) => users._id === user._id) ? (
                    <Button
                      variant="text"
                      sx={{
                        fontSize: "16px",
                        color: "#2065D1",
                        height: "32px",
                        background: "white",
                        padding: "10px 30px",
                        borderRadius: "20px",
                        border: "1px solid #0a66c2",
                        fontWeight: "600",
                      }}
                    >
                      Message
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      sx={{
                        width: "100%",
                        background: "transparent ",
                        color: "#0a66c2",
                        // `${user.buttonText === "Connect" ? "#0a66c2" : "#00000099"
                        // }`,
                        border: "1px solid #0a66c2",
                        //  `1px solid ${user.buttonText === "Connect" ? "#0a66c2" : "#00000099"
                        //   }`,
                        borderRadius: "20px",
                        "& svg": {
                          fontSize: "20px",
                          fontWeight: 600,
                          color: "#0a66c2",
                          // `${user.buttonText === "Connect" ? "#0a66c2" : "#00000099"
                          //   }`,
                        },
                        "& span.MuiButton-startIcon": {
                          marginRight: "4px !important",
                        },
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                      startIcon={
                        <PersonAddAltIcon />
                        // <ScheduleIcon />,
                      }
                      onClick={() => user.user?._id}
                    >
                      Connect
                    </Button>
                  )
                ) : null}
              </CardActions>
            </Card>
          );
        })
      ) : (
        <Box
          sx={{
            padding: "16px",
            width: "100%",
            display: "flex",
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              color: "black",
            }}
          >
            No Contact
          </Typography>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  connections: state.connections,
});

export default connect(mapStateToProps, {
  getMyConnectionsList,
})(FriendAndProfileCard);
