import { COOKIES_ACCEPTED, COOKIES_REJECTED } from "../actions/types";

const initialState = [false, false];

function cookiesReducer(state = initialState, action) {
  const { type } = action;

  switch (type) {
    case COOKIES_ACCEPTED:
      state = [true, true];
      return state;
    case COOKIES_REJECTED:
      state = [true, false];
      return state;
    default:
      return state;
  }
}

export default cookiesReducer;
