export const profileDataInitialState = {
  firstname: "",
  lastname: "",
  nickname: "",
  sport: "",
  email: "",
  country: "",
  website: "",
  bio: "",
  githubusername: "",
  experience: [
    {
      title: "",
      company: "",
      location: "",
      from: null,
      to: null,
      current: false,
      description: "",
    },
  ],
  education: [
    {
      school: "",
      degree: "",
      fieldofstudy: "",
      from: null,
      to: null,
      current: false,
      description: "",
    },
  ],
  social: {
    youtube: "",
    twitter: "",
    facebook: "",
    linkedin: "",
    instagram: "",
  },
  role: "player",
  gender: "",
  avatar: "empty",
  approved: false,
  dateOfBirth: "",
  countryOfBirth: "",
  cityOfBirth: "",
  coverImage: "",
  height: "0",
  weight: "0",
  schooling: "",
  tertiaryEducation: "",
  favouriteMovie: "",
  favouriteArtist: "",
  favouriteFood: "",
  hobbies: [],
  playingPosition: "",
  sportingHistory: [],
  achievements: [],
  coachingHistory: [],
  managerialHistory: [],
  qualificationsList: [],
  achievementsList: [],
  pressCompanyName: "",
  pressConceptionDate: "",
  pressCountryLocated: "",
  pressCityLocated: "",
  fanFavoriteTeam: "",
};
