import React, { useState } from "react";
import {
  Avatar,
  Button,
  Divider,
  Typography,
  Paper,
  Tooltip,
  Box,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect } from "react";
import { getMyConnections } from "../../actions/connections";

const UserProfile = ({
  profile,
  deleteAccount,
  ProfileProp,
  getMyConnections,
  connections: { myConnections },
}) => {
  useEffect(() => {
    getMyConnections(profile.user._id);
  }, [getMyConnections, profile.user._id]);
  const [menuAnchor, setMenuAnchor] = useState(null);

  function randomColor() {
    let hex = Math.floor(Math.random() * 0xffffff);
    let color = "#" + hex.toString(16);
    return color;
  }

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleMenuItemClick = (action) => {
    // Perform the action based on the selected menu item

    if (action === "Delete My Account") {
      deleteAccount && deleteAccount();
    }
    if (action === "Delete My Account") handleMenuClose();
  };
  const [value, setValue] = useState("Active");

  const handleChange = (event) => {
    setValue(event);
  };

  function stringAvatar(name) {
    return {
      children: `${name.split(" ")[0][0]} ${name.split(" ")[1][0]}`,
    };
  }
  return (
    <Paper
      className="user-profile"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Box
        sx={{
          paddingX: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {/* <div> {profile?.avatar ? (
            profile.avatar !== "empty" && (
              <Avatar
                sx={{
                  bgcolor: randomColor(),
                  width: "70%",
                  height: "70%",
                  marginTop: -5,
                }}
                aria-label="recipe"
                // src={`https://${
                //   process.env.NODE_ENV === "development"
                //     ? "localhost:8080"
                //     : "picme.click:8080"
                // }/api/profile/photo/${profile?.avatar}`}
                src={profile?.avatar}
                alt={profile?.lastname}
              >
                <IconButton
                  color="info"
                  style={{ backgroundColor: "white", borderRadius: "50%" }}
                >
                  <PhotoCamera />
                </IconButton>
              </Avatar>
            )
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",

                borderRadius: "50%",
                bgcolor: randomColor(),
                width: "70%",
                height: "70%",
                marginTop: -5,
              }}
            >
              <IconButton
                color="info"
                style={{ backgroundColor: randomColor(), borderRadius: "50%" }}
              >
                <PhotoCamera />
              </IconButton>
            </Box>
          )}
          </div> */}
            <Avatar
              sx={{
                width: "170px",
                height: "170px",
                marginTop: "-55px",
                border: "4px solid white",
                textTransform: "capitalize",
                fontSize: "32px",
              }}
              alt={`${profile?.firstname || ""} ${profile?.lastname || ""}`}
              {...stringAvatar(`${profile?.firstname} ${profile?.lastname}`)}
              src={
                profile?.avatar && profile.avatar !== "empty"
                  ? `https://${
                      process.env.NODE_ENV === "development"
                        ? "localhost:8080"
                        : "picme.click:8080"
                    }/api/profile/photo/${profile?.avatar}`
                  : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"
              }
            />

            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="h4"
                sx={{
                  fontSize: "30px !important",
                  textTransform: "capitalize",
                }}
              >
                {profile?.firstname || "Bruce Wayne"} {profile?.lastname || ""}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {myConnections.list && myConnections.list.length} friends
              </Typography>
            </div>
          </div>
          {!ProfileProp && (
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* <Tooltip title="Add to Story" arrow>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddCircle />}
                style={{ marginRight: "8px" }}
              >
                <span className="show-icons">Add to Story</span>
              </Button>
            </Tooltip> */}
              <Tooltip title="Edit Profile" arrow>
                <Link to="/edit-profile">
                  <Button
                    variant="contained"
                    startIcon={<Edit />}
                    style={{
                      marginRight: "8px",
                      background: "#e4e6eb",
                      color: "black",
                    }}
                  >
                    <span className="show-icons">Edit Profile</span>
                  </Button>
                </Link>
              </Tooltip>
              {/* <Tooltip title="Menu" arrow>
              <IconButton color="inherit" onClick={handleMenuOpen} style={{ marginRight: "8px", background: "#e4e6eb", color: "black" }}>
                <ArrowDropDown />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={menuAnchor}     
              open={Boolean(menuAnchor)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleMenuItemClick("Add Education")}>
                <Link to="/add-education">Add Education</Link>
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick("Delete My Account")}>
                Delete My Account
              </MenuItem>
            </Menu> */}
            </div>
          )}
        </div>
        <Divider style={{ margin: "16px 0" }} />
      </Box>
    </Paper>
  );
};
const mapStateToProps = (state) => ({
  connections: state.connections,
});

export default connect(mapStateToProps, {
  getMyConnections,
})(UserProfile);
