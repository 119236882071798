/* eslint-disable array-callback-return */
import { Box, Grid, Typography, Divider, Button, Avatar } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import React from "react";
import { useEffect } from "react";


export const AwardCard = ({
  openExperienceModal,
  awardDetails,
  getAwards,
  awardDetailDelete,
  deleteAwardDetail,
  userId,
  setEditedData
}) => {
  const deleteRequest = (receiverAwardId) => {
    deleteAwardDetail(receiverAwardId);
  };

  const handleClickEditOpen = (data, action) => {
    openExperienceModal();
    setEditedData({ ...data, action });
  };
  useEffect(() => {
    if (awardDetailDelete.status === 200) {
      getAwards(userId);
    }
  }, [awardDetailDelete]);
  return (
    <Grid
      item
      xs={12}
      sx={{
        background: "#FFFFFF",
        boxShadow: "0px 0px 0px 2px rgb(0 0 0 / 10%)",
        borderRadius: "0.8rem",
        // paddingY: "15px !important",
        overflow: "auto",
        // minHeight: "00px",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          padding: "15px 20px",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            color: "#000000",
          }}
        >
          Awards
        </Typography>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: "#00000099",
              paddingRight: "16px",
            }}
          >
            <Button
              variant="contained"
              color="inherit"
              style={{
                width: "100%",
                marginBottom: "16px",
                background: "#e4e6eb",
                fontSize: "14px",
                fontWeight: 600,
              }}
              onClick={openExperienceModal}
            >
              <AddIcon />
            </Button>
          </Typography>
        </Box>
      </Box>

      <Divider width={"100%"} />

      {awardDetails?.awards?.length !== 0 ?
        awardDetails?.awards?.map((users, index) => {
          const awardDate = new Date(users.date);
          const formatDate = (date) => {
            const monthNames = [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ];
            const day = date.getDate();
            const month = monthNames[date.getMonth()];
            const year = date.getFullYear();
            return `${day} ${month}, ${year}`;
          };

          const formattedFromDate = formatDate(awardDate);
          return (
            <>
              <Box
                key={index}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "15px 20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <Box sx={{}}>
                    <Avatar
                      alt={users.awardName}
                      src={users.certificateImage && users.certificateImage !== "empty"
                        ? `https://${process.env.NODE_ENV === "development"
                          ? "localhost:8080"
                          : "picme.click:8080"
                        }/api/awards/photo/${users.certificateImage}`
                        : "https://gravatar.com/avatar/2d31a19877590239a3bb972a96da9973?d=mm&r=pg&s=200"}

                      sx={{
                        height: "70px",
                        width: "70px",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      marginLeft: "15px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#000000",
                      }}
                    >
                      {users.awardName}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#0000009",
                      }}
                    >
                      {users.description}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "rgba(0, 0, 0, .6)",
                      }}
                    >
                      {users.role}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "rgba(0, 0, 0, .6)",
                      }}
                    >
                      {formattedFromDate}
                    </Typography>
                  </Box>
                </Box>

                <Button
                  sx={{
                    fontSize: "16px",
                    color: "none",
                    border: "none",
                  }}
                  onClick={() => handleClickEditOpen(users, "edit")}
                >
                  <EditIcon />
                </Button>

                <Button
                  sx={{
                    fontSize: "16px",
                    color: "none",
                    border: "none",
                  }}
                  onClick={() => deleteRequest(users._id)}
                >
                  <DeleteIcon />
                </Button>
              </Box>
              <Divider width={"100%"} />

            </>
          );
        }) : <Box
          sx={{
            padding: "16px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              color: "black",
            }}
          >
            No Awards Yet
          </Typography>
        </Box>}

    </Grid>
  );
};
