import { Box, Button } from "@mui/material";
import { SearchInput } from "./Search-Input";

export const FilterSearchComponents = ({
  searchTitle,
  Component,
  isEmpty,
  hideDate = false,
  onSearchChange,
}) => {
  return (
    <Box
      sx={{
        marginLeft: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flex: 1,
      }}
    >
      {!isEmpty && (
        <Button size="small" sx={{ position: "relative", marginRight: "20px" }}>
          Clear Filters
        </Button>
      )}

      <Box
        sx={{
          width: 230,
          marginLeft: "24px",
          height: "40px",
        }}
      >
        <SearchInput
          height="40px"
          variant="filled"
          placeholder={searchTitle}
          onChange={(event) => onSearchChange(event.target.value)}
        />
      </Box>

      {Component && <Component />}
    </Box>
  );
};
