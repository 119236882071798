import {
  APPROVE_POST,
  REJECT_POST,
  GET_PROFILE_PICTURE_POSTS,
  GET_REGULAR_POSTS,
  APPROVE_REGULAR_POST,
  REJECT_REGULAR_POST,
} from "../actions/types";

const initialState = {
  profilePicturePosts: [],
  regularPosts: [],
};
const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPROVE_POST:
      return {
        ...state,
        profilePicturePosts: state.profilePicturePosts.filter(
          (post) => post._id !== action.payload
        ),
      };
    case APPROVE_REGULAR_POST:
      return {
        ...state,
        regularPosts: state.regularPosts.filter(
          (post) => post._id !== action.payload
        ),
      };
    case REJECT_POST:
      return {
        ...state,
        profilePicturePosts: state.profilePicturePosts.filter(
          (post) => post._id !== action.payload
        ),
        regularPosts: state.regularPosts.filter(
          (post) => post._id !== action.payload
        ),
      };
    case REJECT_REGULAR_POST:
      return {
        ...state,
        regularPosts: state.regularPosts.filter(
          (post) => post._id !== action.payload
        ),
      };
    case GET_PROFILE_PICTURE_POSTS:
      return {
        ...state,
        profilePicturePosts: action.payload,
      };
    case GET_REGULAR_POSTS:
      return {
        ...state,
        regularPosts: action.payload,
      };
    default:
      return state;
  }
};

export default adminReducer;
